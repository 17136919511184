(function () {

    'use strict';

    /* Controllers */

    angular.module('kohapac.controllers', [])

    .controller('LandingPageCtrl', ["$scope", "$sce", "$http", function ($scope, $sce, $http) {
        $scope.opacnews = [];
        $http.get('/api/opac-news', {
            cache: true
        }).then(function (rsp) {
            var news = [];
            for (var id in rsp.data) {
                news.push({
                    title: rsp.data[id].title,
                    entry: $sce.trustAsHtml(rsp.data[id]['new']),
                    date: rsp.data[id].timestamp,
                    order: Number(rsp.data[id].number)
                });
            }
            $scope.opacnews = news.sort(function (a, b) {
                return a.order - b.order;
            });
        });

    }])
    .controller('AppErrorCtrl', ["$scope", "$stateParams", function($scope, $stateParams ){

        console.warn($stateParams);
        $scope.errorcode = $stateParams.code;

    }])

    .controller('SearchCtrl', ["$scope", "$state", "$timeout", "SearchQuery", "userService", "kohaSearchSvc", "kwLuceneParser", "configService", function ($scope, $state, $timeout, SearchQuery, userService, kohaSearchSvc, kwLuceneParser, configService) {
        // advanced search page.

        $scope.sortOptions = (configService.opacConfig.mastheadSearchConfig.sortOptions)
            ? configService.opacConfig.mastheadSearchConfig.sortOptions : configService.sortoptions;
        $scope.sortOptions = $scope.sortOptions.map( function(opt){
            if (!opt.group) delete opt.group;
            return opt;
        });

        var lastSearch = kohaSearchSvc.currentSearch();

        $scope.op = '';

        $scope.searchFields = $scope.config.advancedSearch.queryFields.basic;

        $scope.subqueries = [ ];

        var oldlimits = {};
// extract search from last query.
// note this misses a few things.
        // TODO: Now that we have a query parser, we should build out a better adv search interface.

        if(lastSearch){
            var parsed_subqueries = kwLuceneParser.extractSubqueries(lastSearch.q);
            if(parsed_subqueries){
                if(parsed_subqueries.op=='OR') $scope.op = 'OR';
                parsed_subqueries.subqueries.forEach(function(sq){
                    $scope.subqueries.push( sq );
                });
            }
            oldlimits = angular.copy(lastSearch.limitfields);
        }

        $scope.rmQuery = function (i) {
            $scope.subqueries.splice(i, 1);
        };
        $scope.addQuery = function () {
            $scope.subqueries.push({
                q: '',
                field: $scope.searchFields[0].field
            });
        };
        if(!$scope.subqueries.length) $scope.addQuery();

        $scope.options = {};
        angular.forEach({
            language: 'bv-language',
            branch: 'bv-branch',
            itemtype: 'bv-itemtype',
            eformat: 'eformat',
            ccode: 'ccode',
            loc: 'loc'
        }, function (interp, limit) {
            $scope.options[limit] = $scope.config.listCodes(interp).filter(function (code) {
                if (!$scope.config.advancedSearch[limit].exclude) return true;
                return ($scope.config.advancedSearch[limit].exclude.indexOf(code) == -1) ? true : false;
            });
        });
        var defaultSort = ($scope.config.OPACdefaultSortField || 'score') + ' ' + ($scope.config.OPACdefaultSortOrder || 'desc');

        $scope.clearLimits = function() {
            $scope.limits = {
                itemtype: [],
                collection: [],
                ln: [],
                branch: [],
                'location': [],
                eformat: [],
                format: null,
                content: '',
                audience: '',
                pubyear: {
                    from: '',
                    to: ''
                },
                lexile_i: {
                    from: '',
                    to: ''
                },
                geoscale: {
                    from: '',
                    to: ''
                },
                rangeSearch : {},
                available: false,
                sort: ($scope.config.advancedSearch.sort.clearToDefault ? defaultSort : ((lastSearch||{}).sort || defaultSort)),
                suppress: ''
            };
        };
        $scope.clearLimits();

        angular.forEach(['itemtype', 'collection', 'ln', 'location', 'eformat'], function (limit) {
            if (oldlimits[limit] && oldlimits[limit].length == 1) {
                $scope.limits[limit] = oldlimits[limit][0].replace(/"/g, '').split(' OR ');
            }
        });
        if (oldlimits['on-shelf-at'] && oldlimits['on-shelf-at'].length == 1) {
            $scope.limits.available = true;
            $scope.limits.branch = oldlimits['on-shelf-at'][0].replace(/"/g, '').split(' OR ');
        } else if (oldlimits['owned-by'] && oldlimits['owned-by'].length == 1) {
            $scope.limits.branch = oldlimits['owned-by'][0].replace(/"/g, '').split(' OR ');
        }
        if (oldlimits.pubyear && oldlimits.pubyear.length == 1) {
            var drange = oldlimits.pubyear[0].replace(/[\[\]]/g, '').split(' TO ');
            $scope.limits.pubyear.from = (drange[0] == '*') ? '' : drange[0];
            $scope.limits.pubyear.to = (drange[1] == '*') ? '' : drange[1];
        }

        $scope.clearMusicSearch = function() {
            $scope.musicSearch = {
                queries: [{
                    instr: null,
                    num: null
                }],
                op: 'AND',
                add: function () {
                    this.queries.push({
                        instr: null,
                        num: null
                    });
                },
                rm: function (i) {
                    this.queries.splice(i, 1);
                },
                ensemble: null,
                toString: function () {
                    //var str = '';
                    return this.ensemble || this.queries.filter(function (q) {
                        return q.instr && q.num;
                    }).
                    map(function (q) {
                        return q.instr + ((q.num < 10) ? '0' + q.num : q.num);
                    }).
                    join(' ' + this.op + ' ');
                }
            };
        };
        $scope.clearMusicSearch();

        $scope.calendar = {
            opened: { 'create1': false,
                'create2': false,
                'load1': false,
                'load2': false,
            },
            dateFormat: 'MM/dd/yyyy',
            open: function(which) {
                $timeout(function(){
                    $scope.calendar.opened[which] = true;
                });
            }
        };

        $scope.clearAdvancedSearch = function() {
            $scope.subqueries = [{
                q: '',
                field: null
            }];
            $scope.op = '';
            $scope.clearLimits();
            $scope.clearMusicSearch();
            kohaSearchSvc.clearCurrentSearch();
        };
        if (!$scope.config.advancedSearch.retainOnEntry) $scope.clearAdvancedSearch();

        var getRange = function(from, to, opt){
            if(!opt) opt = {};
            if(!opt.format) opt.format = 'number';
            var formatter = {
                'number': function(n){ return n || '*'; },
                'datetime': function(d, from){ if(d){
                        var time = dayjs(d);
                        if(!time.isValid()) throw( "invalid date" );
                        if(opt.snapDay) time = (from) ? time.startOf('day') : time.endOf('day');
                        return time.utc().format();
                    } else {
                        return '*';
                    }
                }
            };
            if(! (opt.format in formatter) ) throw "invalid format";
            var range = "[" + formatter[opt.format](from, true) + " TO " + formatter[opt.format](to) + "]";
            return range;
        };

        $scope.doAdvancedSearch = function () {

            var limits = {};

            if ($scope.limits.branch.length) {
                var field = ($scope.limits.available) ? 'on-shelf-at' : 'owned-by';
                limits[field] = $scope.limits.branch.join(' OR ');
            }
            jQuery.each(['itemtype', 'collection', 'ln', 'location', 'eformat'], function (i, field) {
                if ($scope.limits[field].length) {
                    limits[field] = '"' + $scope.limits[field].join('" OR "') + '"'; // FIXME: quotes in codes will break this.
                }
            });
            if ($scope.musicSearch.toString()) {
                limits.fq = $scope.musicSearch.toString();
            }

            if(userService.is_staff && ($scope.limits.suppress=='0' || $scope.limits.suppress == '')){
                $scope.limits.suppress = '[0 TO 1]';
            }

            // These Must be single-valued.
            jQuery.each(['content', 'audience', 'suppress'], function (i, field) {
                if ($scope.limits[field]) {
                    limits[field] = $scope.limits[field];
                }
            });

            // Format may have multiple terms
            jQuery.each(['format'], function (i, field) {
                if ($scope.limits[field]) {
                    limits[field] = '"'+$scope.limits[field]+'"';
                }
            });

            if ($scope.limits.pubyear.from || $scope.limits.pubyear.to) {
                limits['pubyear'] = getRange($scope.limits.pubyear.from, $scope.limits.pubyear.to);
            }
 
            if ($scope.limits.geoscale.from || $scope.limits.geoscale.to) {
                limits['geo-scale'] = getRange($scope.limits.geoscale.from, $scope.limits.geoscale.to);
            }
            if ($scope.limits.lexile_i.from || $scope.limits.lexile_i.to) {
                limits['lexile_i'] = getRange($scope.limits.lexile_i.from, $scope.limits.lexile_i.to);
            }
            try{
                if ($scope.limits.rangeSearch.loadedDate1 || $scope.limits.rangeSearch.loadedDate2) {
                    limits['load-date'] = getRange($scope.limits.rangeSearch.loadedDate1,
                            $scope.limits.rangeSearch.loadedDate2, {format: 'datetime', snapDay: true} );
                }
                if ($scope.limits.rangeSearch.createdDate1 || $scope.limits.rangeSearch.createdDate2) {
                    limits['create-date'] = getRange($scope.limits.rangeSearch.createdDate1,
                            $scope.limits.rangeSearch.createdDate2, {format: 'datetime', snapDay: true} );
                }
            } catch (e){
                alert(e);
                return;
            }

            var searchParams = {
                q: kwLuceneParser.composeSubqueries($scope.subqueries),
                op: $scope.op,
                sort: $scope.limits.sort,
                limits: limits
            };
            var search = new SearchQuery(searchParams);

            $state.go('search-results.koha', search.stateParams());

        };

    }])

    .controller('SearchResultsCtrl', ["$rootScope", "configService", "userService", "$stateParams", "$scope", "$state", "SearchQuery", "kohaSearchSvc", "kwLuceneParser", "kohaDlg", function($rootScope, configService, userService, $stateParams,
                $scope, $state, SearchQuery, kohaSearchSvc, kwLuceneParser, kohaDlg){
        // controller for abstract state.

        $scope.federatedSearch = {
            ebsco: { id: 'eds', name: configService.altSearch.eds.label, hits: 0 },
            indexData: { id: 'indexdata', name: configService.altSearch.indexdata.label, hits: 0 },
            koha: { id: 'koha', name: 'Library Catalog', hits: 0 }, // FIXME: Make configurable.
            collapsed: false,
            search: null  // This stores current search for child scopes, whether federated search is used or not.
        };

//  this state is retained when changing between child states.

        $scope.viewType = {
            set: function(viewType){
                $scope.viewType.active = configService.viewtype = viewType;
                $scope.viewType.parentClass = viewType;
                $scope.viewType.rowClass = viewType + ((viewType && viewType.indexOf('grid') > -1) ? ' row' : '');
                $scope.viewType.resultClass = viewType + ((viewType && viewType.indexOf('grid') > -1) ? ' col-xs-12 col-sm-6 col-md-4' : ' row');
                $scope.viewType.imgClass = viewType + ((viewType && viewType.indexOf('grid') > -1) ? ' thumbnail' : '');
            }
        };

        userService.whenAnyUserDetails().then(function() {
            $scope.useDefaultFilter = (userService.merged_prefs && userService.merged_prefs.use_default_filter) ? true : false;
            $scope.hasDefaultFilter = (userService.merged_prefs && userService.merged_prefs.default_query_filter) ? true : false;
            var initview = (userService.merged_prefs && userService.merged_prefs.selected_view_type) ? userService.merged_prefs.selected_view_type : (configService.viewtype || 'list-view');

            $scope.viewType.set(initview);
            $scope.$watch('viewType.active', function(viewType) {
                if (! viewType) { return; }
                $scope.viewType.set(viewType);
                if (userService.loggedin) {
                    var currentSelection = userService.merged_prefs.selected_view_type;
                    if (!currentSelection || currentSelection != viewType) {
                        userService.addPrefs({selected_view_type: viewType});
                    }
                }
            });
        });

        $scope.federatedSearch.go = function(childstate){

            resetSource(childstate);
            if(childstate=='koha'){
                var params;
// FIXME: try to translate between limits when possible.

                if($scope.federatedSearch.search){
                    params = $scope.federatedSearch.search.stateParams();
                } else {

                    var newSearch = new SearchQuery({ q: $stateParams.query }); // fixme: not working.
                    params = newSearch.stateParams();
                }
                $state.go( 'search-results.koha', params, { inherit: false });
            } else {
                var parsed_query = kwLuceneParser.parse(this.search.q);
                var rawSearchTerms = kwLuceneParser.extractTerms(parsed_query).filter(
                                    function(term){ return term.length > 2; });
                $state.go( 'search-results.' + childstate, { query: rawSearchTerms }, {inherit: false});
            }
        };

        var namemap = { ebsco: 'eds', indexData: 'indexdata', koha: 'koha' };
        function resetSource (src){
            $scope.federatedSearch.otherSources = [];
            ['koha','ebsco','indexData'].forEach(function(svc){
                var isTarget = (src) ? ( src == namemap[svc] ) : ( $state.current.name=='search-results.'+ namemap[svc]);
                if (svc === 'ebsco' && !configService.EbscoDiscoveryServices) return;
                if (svc === 'indexData' && !configService.IndexDataMKWS) return;

                if(isTarget){
                    $scope.federatedSearch.currentSource = $scope.federatedSearch[svc];
                } else {
                    if(svc=='koha' || configService.altSearch[namemap[svc]].on){
                        $scope.federatedSearch.otherSources.push($scope.federatedSearch[svc]);
                    }
                }
            });
        }
        resetSource();

        $scope.showSearchSummary = true;
        if (configService.opacConfig.mastheadSearchConfig && configService.opacConfig.mastheadSearchConfig.simpleSearch.hideSummary) {
            // FIXME: for anon users, assumes initial masthead mode is simple. 
            if ((userService.merged_prefs && userService.merged_prefs.masthead_search_form == 'simple') || ! userService.loggedin) {
                $scope.showSearchSummary = false;
            }
        }

        $rootScope.$on('setSearchMethod', function(e,args) {
            $scope.showSearchSummary = true;
           if (configService.opacConfig.mastheadSearchConfig.simpleSearch.hideSummary && args == 'simple')
               $scope.showSearchSummary = false;
        });

        $scope.currentSearch = kohaSearchSvc.currentSearch;

        if(configService.isMobile()){
            $scope.federatedSearch.collapsed = true;
            $scope.facetsCollapsed = true;
        }

        $scope.openFacetHelp = function(){
            userService.whenAnyUserDetails().then(function() {

                kohaDlg.dialog({
                    type: 'help',
                    heading: 'Options for refining your search results',
                    ngInclude: '/app/static/partials/help/facetHelpTmpl.html',
                    close: true,
                    scopevars: { advancedFacets: userService.merged_prefs.advanced_facets }
                });
            });
        }

    }])

    .component('searchQuerySummary', {
        templateUrl: '/app/static/partials/search/searchQuerySummary.html',
        bindings: {
            search: '<'
        },

        controller: ["configService", "SearchQuery", "userService", "authoritySvc", "kwLuceneParser", "$q", "$state", "$injector", function( configService, SearchQuery, userService, authoritySvc,
                            kwLuceneParser, $q, $state, $injector ) {

            var ctrl = this;
            this.limitFields = [];


            this.$onInit = function(){
                var search = this.search;
                ctrl.federatedSource = ! ($state.is('search-results.koha'));

                userService.whenAnyUserDetails().then(function() {
                    ctrl.sortOptions = (configService.opacConfig.mastheadSearchConfig.sortOptions)
                        ? configService.opacConfig.mastheadSearchConfig.sortOptions : configService.sortoptions;
                    ctrl.sortOptions = ctrl.sortOptions.map( function(opt){
                        if (!opt.group) {
                            delete opt.group;
                        }
                        return opt
                    });
                    if (userService.can({catalogue:{bib_ratings:'view'}})) {
                        ctrl.sortOptions = ctrl.sortOptions.filter( function(opt){ return (opt.group != 'Patron Rating'); });
                    }
                    ctrl.sortField = search.sort || 'score desc';

                    var picked = ctrl.sortOptions.filter( function(opt){ return opt.value==search.sort; });
                    ctrl.sortLabel = (picked.length) ? picked[0].label : '?';
                    // fixme: this should be uib-dropdown.
                });
                ctrl.subqueries = kwLuceneParser.extractSubqueries(search.q).subqueries;

                angular.forEach(ctrl.subqueries, function(sq){
                    sq.q_display = sq.q;
                    if (sq.field && sq.field.match(/linked_rcn|_rcn_facet$/)) {
                        sq.field_display = 'Heading';
                        var rcn = sq.q.replace(/\\([() ])/g, '$1').replace(/^"|"$|\|\|.*$/g, '');
                        authoritySvc.get(rcn).then(function(r){
                            sq.q_display = r.summary.heading;
                        });
                    } else if (sq.field === '*' && sq.q === '*') {
                        sq.field_display = null;
                        sq.q_display = 'All Records';
                    } else {
                        sq.field_display = sq.field;
                    }
                });

                this.config = configService;

                angular.forEach(search.limitfields, function(vals, key) {

    // TODO: translate facets like 'url: *', '*:*', 
    // and range limits.
    // FIXME: kwLuceneParser fails to parse date math ranges, e.g. catdate:[NOW-800DAYS TO *] excepts.
                    var limfield;
                    if (key == 'fq') {
                        limfield = {field: key};
                        limfield.fieldDisplay = key;
                        limfield.values = [];
                        angular.forEach(vals, function(val) {
                            var limval = {value: val};
                            var vmatch = val.match(/^([^:]+):(.+)$/);
                            if (vmatch && vmatch.length > 2) {
                                var facetName = vmatch[1];
                                var facetValue = vmatch[2];
                                var facetInfo = configService.searchFacetInfo[facetName];

                                facetName = (facetInfo) ? facetInfo.display : facetName;
                                facetValue = (facetInfo && facetInfo.authval) ? configService.display(facetValue, facetInfo.authval) : facetValue;
                                limval.valueDisplay = facetName + ":" + facetValue;
                            }
                            else {
                                limval.valueDisplay = val;
                            }
                            limfield.values.push(limval);
                        });
                        ctrl.limitFields.push(limfield);
                    }
                    else {
                        var facetInfo = configService.searchFacetInfo[key];
                        var otherAuthvals = {
                            ln : 'Language'
                        };
                        limfield = {field: key};
                        limfield.fieldDisplay = (facetInfo) ? facetInfo.display :
                                    otherAuthvals[key] ? otherAuthvals[key] : key;
// FIXME:
// Relying on facetInfo means if the user doesn't have the facet defined, we don't get interpolation.
// authval fields may also come from advanced search.


                        limfield.values = [];
                        angular.forEach(vals, function(val) {
                            var limval = {value: val};
                            var vmatch = val.match(/^\"(.+)\"$/);

                            if (vmatch && vmatch.length > 1 && facetInfo && facetInfo.authval) {
                                var authvaldisplay = configService.display(vmatch[1], facetInfo.authval);
                                limval.valueDisplay = (authvaldisplay==vmatch[1]) ? val : authvaldisplay;
                            } else if ( otherAuthvals[key] && vmatch && vmatch.length > 1 ) {
                                var avdisp = configService.display(vmatch[1], otherAuthvals[key]);
                                limval.valueDisplay = (avdisp==vmatch[1]) ? val : avdisp;
                            } else if (facetInfo && facetInfo.authval === 'rcn-heading') {
                                vmatch = val.match(/^\((.+)\)$/);
                                if (vmatch && vmatch.length > 1) {
                                    var expr = vmatch[1];
                                    var rcns = [], operators = [];
                                    // Just in case something goes amuck
                                    for (var i=0; i<1000; i++) {
                                        var rcnMatch = expr.match(/^(\\\([^)]+\)\d+[^\s]+)\s+(OR|AND|NOT)\s+/);
                                        if (!rcnMatch || rcnMatch.length<3) {
                                            break;
                                        }
                                        rcns.push(rcnMatch[1]);
                                        operators.push(rcnMatch[2]);
                                        expr = expr.replace(/^\\\([^)]+\)\d+[^\s]+\s+(OR|AND|NOT)\s+/,'');
                                    }
                                    rcns.push(expr);
                                    var promises = [];
                                    rcns.forEach(function(token) {
                                        var rcn = token.replace(/\\([() ])/g, '$1').replace(/^"|"$|\|\|.*$/g, '');
                                        promises.push(authoritySvc.get(rcn));
                                    });
                                    $q.all(promises).then(function(rv) {
                                        var newTokens = [];
                                        while (rv.length > 0) {
                                            var r = rv.shift();
                                            newTokens.push(r.summary.heading);
                                            if (operators.length > 0) {
                                                var op = operators.shift();
                                                newTokens.push(' ' + op + ' ');
                                            }
                                        }
                                        limval.valueDisplay = newTokens.join('');
                                    });


                                }
                                else {
                                    var rcn = val.replace(/\\([() ])/g, '$1').replace(/^"|"$|\|\|.*$/g, '');
                                    limval.valueDisplay = rcn;
                                    authoritySvc.get(rcn).then(function(r){
                                        limval.valueDisplay = r.summary.heading;
                                    });
                                }
                            }
                            else {
                                limval.valueDisplay = val.replace(/^\"|\"$/g, '');
                            }
                            limfield.values.push(limval);
                        });
                        ctrl.limitFields.push(limfield);
                    }
                });
                if(search.op && search.op=='OR'){
                    ctrl.limitFields.push(
                        { field: 'q.op',
                          values: [ { value: 'OP', valueDisplay: 'Any term' }]
                      });
                }

            }


            this.rmLimit = function (field, val) {
                // remove a limit and trigger new search.

                var query = new SearchQuery( ctrl.search );
                if(field=='q.op')
                    delete query.op;
                else
                    query.rmLimit(field,val);

                if(configService.geospatialSearch && (field == 'geo-shape') && ARCHVIEW.mapLayers && ARCHVIEW.mapLayers.length > 0){
                    var mapComptrollerSvc = $injector.get('mapComptrollerSvc');
                    mapComptrollerSvc.clearAOIAndDontSearch();
                }

                $state.go('search-results.koha', query.stateParams(), { inherit: false });
                // FIXME: ^^^Will have to distinguish btwn child states
            };

            this.rmQuery = function (i) {

                var subq = angular.copy(this.subqueries);
                subq.splice(i,1);

                var q = kwLuceneParser.composeSubqueries(subq);

                var searchParams = {
                    q: q,
                    op: ctrl.search.op,
                    sort: ctrl.search.sort,
                    limits: ctrl.search.limitfields
                };

                var newSearch = new SearchQuery(searchParams);

                $state.go('search-results.koha', newSearch.stateParams());

            };

            this.reSort = function(sortfield){
                var newSearch = new SearchQuery( this.search );
                newSearch.sort = sortfield;
                newSearch.go();
            }

        }]

    })

    .controller('KohaSearchSummaryCtrl', ["$scope", "$q", "$filter", "configService", "userService", "cartService", "bibService", "acqListSearchAPI", "BibBatchService", "awDailyExportService", "kohaDlg", "alertService", "$location", "$uibModal",
                    function($scope, $q, $filter, configService, userService, cartService, bibService,acqListSearchAPI, BibBatchService, awDailyExportService, kohaDlg, alertService, $location, $uibModal ){

        $scope.changePagesize = function(newSize){
            // this and pager should both be in parent controller,
            // and SearchQuery object should probably know its target (eg koha or ebsco)
            if(newSize != $scope.numPerPage){
                // Note that we mutate configservice's syspref val to make it persistent.
                // numResults should really be configured within wack.
                $scope.search.numPerPage = configService.OPACnumSearchResults = newSize;
                $scope.search.page = 1;
                $scope.search.fetch();
            }
        };

        $scope.$watch('federatedSearch.search.results',
            function(results){
                $scope.search = $scope.federatedSearch.search;
                if(results) $scope.numPerPage = results.bibs.length;
            }
        );


        var selectedBibList = function(){
            return Object.keys($scope.search.results.selectedBibs);
        };

        var targetedActions = [
            {   name: 'cart',
                icon: 'bi bi-bag-check',
                text: 'Add to ' + configService.wording.cart,
                can: function(){ return configService.opacbookbag; },
                do: function(){
                    selectedBibList().forEach(function(bibid){ cartService.add(bibid);});
                }
            },
            {   name: 'list',
                icon: 'bi bi-bookshelf',
                text: 'Add to ' + configService.wording.list,
                can: function(){ return configService.virtualshelves; },
                do: function(){
                    kohaDlg.addToList( selectedBibList() );
                }
            },
            {   name: 'acqlist',
                icon: 'bi bi-bookshelf',
                text: 'Add to ' + configService.AcqList.wording.addToList,
                can: function(){
                    return configService.AcqLists &&
                        userService.can({editcatalogue: {item_list: 'update'}});
                },
                do: function(){
                    var bibids = selectedBibList();
                    var bib_promises = bibids.map(function(bibid){ return bibService.get(bibid); });
                    $q.all(bib_promises).then(function(bib_results) {
                        var types = {};
                        angular.forEach(bib_results, function(bib) {
                            console.dir(bib.summary.order_types);
                            angular.forEach(bib.summary.order_types, function(val, otype) {
                                angular.forEach(val, function(val2, osub) {
                                    types[otype + ' ' + osub] = true;
                                });
                            });
                        });
                        acqListSearchAPI.addBibsToList(bibids, types);
                     });
                }
            },
            {   name: 'hold',
                icon: 'bi bi-chevron-double-down',
                text: $filter('ucfirst')(configService.wording.placehold),
                can: function(){ return configService.RequestOnOpac; },
                do: function(){
                    if($scope.selectedCount()){
                        kohaDlg.placeHold(selectedBibList());
                    }
                }
            },
        ];

        $scope.canAddToCart = configService.opacbookbag;

        $scope.addSelectedToCart = function(){
            if($scope.selectedCount()){
                selectedBibList().forEach(function(bibid){ cartService.add(bibid);});
            }
        }

        $scope.placeSelectedHolds = function(){
            if($scope.selectedCount()){
                kohaDlg.placeHold(selectedBibList());
            }
        }

        $scope.addSelectedToList = function(){
            if($scope.selectedCount()){
                kohaDlg.addToList( selectedBibList() );
            }
        }

        $scope.targetedActions = [];
        var setTargetedActions = function(){ // actions on selected results.
            $scope.targetedActions.length = 0;
            if($scope.selectedCount()){
                targetedActions.forEach(function(action){
                    if(action.can()) $scope.targetedActions.push(action);
                });
            }
        };

// FIXME:
// SCOPE.SEAR4CH MAY BE GONE (ON BACK BUTTON ) ?

        $scope.selectedCount = function(){
            if(!($scope.search||{}).results) return;
            var cnt = Object.keys($scope.search.results.selectedBibs).length;
            return (cnt) ? cnt : undefined;
        };

        $scope.selectAll = function (bool) {
            if (bool) {
                $scope.search.results.setSelected();
            } else {
                $scope.search.results.clearSelected();
            }
        };

        $scope.$watch('search.results.selectedBibs', function(bibs){
            if(!bibs) return;
            setTargetedActions();
        }, true);


        $scope.exportSearch = function() {
            var deferred = $q.defer();
            var vendors = [];
            angular.forEach($scope.search.results.facets, function(facet) {
                if (facet.field == 'homebranch') {
                    angular.forEach(facet.values, function(val) {
                        vendors.push(val.value);
                    });
                }
            });
            if (vendors.length) {
                deferred.resolve(1);
            }
            else if ($scope.search.results.bibs && $scope.search.results.bibs.length) {
                console.dir($scope);
                var bibid = $scope.search.results.bibs[0].id;
                bibService.get(bibid).then(function(bib) {
                    var items = bib.summary.available_at;
                    if (items.length) {
                        vendors.push(items[0].branch);
                        deferred.resolve(1);
                    }
                    else {
                        deferred.reject();
                    }
                });
            }
            else {
                deferred.reject();
            }

            deferred.promise.then(function() {
                acqListSearchAPI.exportSearch({vendors: vendors, query: $location.url()});
            }, function() {
                alertService.add({msg: 'No hits in this search!'});
            });
        };

        $scope.saveSearch = function () {

            userService.whenAuthenticatedUserDetails().then(function(d){
                // if (userService.savedSearches.has($scope.search)) {
                //     alertService.add({
                //         msg: 'This search has already been saved.'
                //     });
                //     return;
                // }

                $uibModal.open({
                    backdrop: 'static',
                    templateUrl: '/app/static/partials/savedSearchConfirm-modal.html',
                    resolve: {
                        defaultName: function() {
                            return userService.savedSearches.defaultName($scope.search);
                        },
                        search: function() {
                            return $scope.search;
                        }
                    },
                    controller: ["$scope", "defaultName", "search", function($scope, defaultName, search) {
                        $scope.search = search;
                        $scope.name = defaultName;
                        $scope.error = false;
                        $scope.doOverwrite = 0;
                        $scope.save = function(rv) {
                            userService.savedSearches.add($scope.search, $scope.name, JSON.stringify(userService.getLastExecutedSearch()), $scope.doOverwrite).then(function (rv) {
                                if (rv == 1) {
                                    $scope.error = false;
                                    $scope.$close(1);
                                }
                                else if (rv == 0) {
                                    $scope.error = false;
                                    $scope.doOverwrite = 1;
                                }
                                else {
                                    $scope.error = true;
                                }
                            });
                        };
                    }],
                }).result.then(function(rv) {
                    if (rv == 1) {
                        alertService.add({
                            msg: 'Search saved!'
                        });
                    }
                });
            });
        };

        $scope.addToAcqListAll = function() {
            acqListSearchAPI.addQueryHitsToList($scope.search);
        };

        $scope.sendResults = function () {
            if (!$scope.search.results.hits) return;

            userService.whenAuthenticatedUserDetails().then(function(details){
                var userdata = details;

                if(userdata.email){
                    kohaDlg.dialog({
                            heading: "Email Search Results",
                            message: "Please enter the recipient email address.",
                            inputs: [{
                                    name: 'recipient',
                                    label: 'Recipient email',
                                    val: userdata.email,
                                    type: 'email'
                                },{
                                    name: 'subject',
                                    label: 'Subject',
                                    val: "Your search results"
                                }],
                            buttons: [{
                                val: true,
                                label: 'Send',
                                btnClass: "btn-primary",
                                submit: true
                            }, {
                                val: false,
                                label: 'Cancel'
                            }]
                        }).result.then(function (modalresult) {
                            if (modalresult) {
                                var options = {
                                    op: 'email',
                                    recipient: modalresult.recipient,
                                    subject: modalresult.subject,
                                };

                                BibBatchService.submit( $scope.search.asUrl().split('/').reverse()[0] , options);
                            }
                        });
                } else {
                    kohaDlg.dialog({
                        type: "notify",
                        alertClass: "warning",
                        heading: "No email address on file",
                        message: "You must have an email address on file with the library to send email."
                    });
                }
            });
        };
        $scope.renderResults = function(dload){
            var options = {
                op: (dload) ? 'download' : 'print'
            };
            BibBatchService.submit( $scope.search.asUrl().split('/').reverse()[0], options);
        };

        $scope.prepareDaily = function(){
            awDailyExportService.startModal($scope.search);
        };

    }])

    .controller('KohaFacetViewCtrl', ["$scope", "$uibModal", "configService", "userService", function($scope, $uibModal, configService, userService){

        $scope.facets = [];

        $scope.advancedFacets = false;
        userService.whenAnyUserDetails().then(function() {
            $scope.advancedFacets = userService.merged_prefs.advanced_facets;
        });

        $scope.$watch('currentSearch()', function(srch){
            if(srch){
                srch.whenResults().then(function(results){

                    var newFacetsArr = angular.copy(results.facets);
                    var newFacetsObj = {};
                    var merging = ( $scope.facets.length );

                    if(merging){
                        // merge facets.
                        newFacetsObj = newFacetsArr.reduce(function(obj,item){
                            obj[item.field] = item;
                            return obj;
                        }, {});

                        for (var i = $scope.facets.length - 1; i >= 0; i--) {
                            var extantFacet = $scope.facets[i];

                            if(newFacetsObj[extantFacet.field]){
                                // todo: can't animate replacement here,
                                // this block allows animation of parent facet field,
                                // not individual facet values.  will have to merge
                                // the values array to animate that.
                                extantFacet.values = newFacetsObj[extantFacet.field].values;
                                delete newFacetsObj[extantFacet.field];
                            } else {
                                // remove.
                                $scope.facets.splice(i,1);
                            }
                        }
                    }

                    newFacetsArr.forEach(function(facetfield){
                        if( !merging || newFacetsObj[facetfield.field] ){
                            if(configService.isMobile()) facetfield.collapsed = true;
                            facetfield.operator = 'OR';
                            $scope.facets.push(facetfield);
                        }
                    });

                });
            }

        });

        $scope.facetDlgOpen = function (field) {
            $uibModal.open({
                backdrop: false,
                templateUrl: '/app/static/partials/facet-modal.html',
                controller: 'FacetDlgCtrl',
                windowClass: "modal facet-viewer",
                resolve: {
                    facetfield: function () {
                        return field;
                    }
                }
            });
            return false;
        };

        $scope.toggleFacetOperator = function(f) {
            f.operator = (f.operator == 'OR') ? 'AND' : 'OR';
        };

    }])

    .controller('KohaSearchResultsCtrl', ["$http", "$scope", "$rootScope", "$state", "$timeout", "kohaSearchSvc", "SearchQuery", "configService", "$anchorScroll", "userService", "ratingService", "bibService", "ebscoService", "indexDataApi", function ($http, $scope,$rootScope, $state, $timeout,
            kohaSearchSvc, SearchQuery, configService, $anchorScroll, userService, 
            ratingService, bibService, ebscoService, indexDataApi) {

        $scope.$watch('useDefaultFilter', function(newVal,oldVal) {
            if ((typeof(oldVal) == 'boolean') && (oldVal !== newVal)) {
                userService.addPrefs({use_default_filter: newVal}).then(function() {
                    triggerSearch();
                });
            }
        });

        $scope.resultsSelection = {};
        // FIXME: federatedSearch.search from parent controller
        $scope.updateSelection = function(){
            $scope.federatedSearch.search.results.clearSelected();
            for(var bibid in $scope.resultsSelection){
                if($scope.resultsSelection[bibid]) $scope.federatedSearch.search.results.setSelected(bibid);
            }
        };
        $scope.$watch('federatedSearch.search.results.selectedBibs',function(bibs){
            if(bibs){
                $scope.resultsSelection = angular.copy(bibs);
            }
        }, true);

        var newSearch = new SearchQuery({fromState: true});
        var lastSearch = kohaSearchSvc.currentSearch();

        var doFetch = false;
        if(newSearch.equals(lastSearch)){
            $scope.search = $scope.federatedSearch.search = lastSearch;
        } else {
            $scope.search = newSearch;
            doFetch = true;
        }

        var triggerSearch = function(search) {
             console.log('RELOADING SEARCH');

            if(!search) search = $scope.search;
            $rootScope.$broadcast('searchStart');
// ... we shouldn't have to set federatedSearch here

            kohaSearchSvc.currentSearch(search);

            $scope.federatedSearch.search = search;

            search.fetch().then(function searchSuccess( results ) {
                $rootScope.$broadcast('searchComplete', {results: results.bibs });  // This appears still necessary to unblock map.
                $scope.federatedSearch.koha.hits = results.hits;
                if (configService.search.singleResultShortcut && (results.bibs.length === 1 && results.hits == 1)) {
                    var bib = results.bibs[0];
                    userService.whenAnyUserDetails().then(function() {
                        if (userService.is_staff) {
                            if(/^\d+$/.test(search.q)){
                                // FIXME: Don't load holdings twice.
                                bibService.holdings(bib.id).then(function(holdings) {
                                    var re = new RegExp(search.q)
                                    var item = holdings.items.find(function(item){ return item.barcode && item.barcode.match(re); });
                                    if (item)
                                        $state.go('staff.bib.items_status', {itemid: item.itemnumber, biblionumber: bib.id}, {reload: true});
                                    else
                                        $state.go('staff.bib.details', {biblionumber: bib.id}, {reload: true});
                                });
                            } else {
                                $state.go('staff.bib.details', {biblionumber: bib.id}, {reload: true});
                            }
                        } else {
                            $state.go('work', {bibid: bib.id}, {reload: true});
                        }
                    });
                }
                $timeout(function(){
                    $anchorScroll(); // This doesn't seem to work; see PT 63563124
                },10);
            }, function searchFail(){
                $rootScope.$broadcast('searchFailed');
            });

            // federated search results.
            // we do this just in the BV results since query terms can't be changed in
            // either of the federated search results interfaces.
            if($scope.config.altSearch.eds && $scope.config.altSearch.eds.on){
                // note there's no attempt to map local search fields to third-party fields.

                var rawSearchTerms = search.rawTerms();
                var searchparams = {
                    resultsperpage: $scope.config.altSearch.eds.injectNum,
                    includefacets: 'n',
                    "query-1": rawSearchTerms
                };
                // EDS apparently can't handle request for 0 results.
                if(searchparams.resultsperpage < 1 ) searchparams.resultsperpage = 1;
                if(rawSearchTerms.length){

                    $http.get('/api/eds/search', { params: searchparams, cache: true }).then(function(rsp){
                        $scope.federatedSearch.ebsco.hits = rsp.data.SearchResult.Statistics.TotalHits;
                        $scope.federatedSearch.ebsco.injectResults = ($scope.config.altSearch.eds.injectNum > 0 ) ?
                            rsp.data.SearchResult.Data.Records : [];
                        $scope.federatedSearch.ebsco.injectResults.forEach(function(bib){
                            search.whenResults().then(function(bvResults){
                                var offset = Math.min( bvResults.hits, configService.altSearch.eds.injectOffset );
                                bib.ResultId += offset;
                            })
                            //  title is always first 'Items' element.
                            bib.Items.forEach(function(meta){
                                meta.Data = ebscoService.xformItem(meta.Data);
                            });
                            var title = bib.Items.shift();
                            bib._title = title ? title.Data : ( bib.Header.PubType + ' | ' + bib.Header.DbLabel );
                        });
                        ebscoService.query = ebscoService.queryStringToHash(rsp.data.SearchRequestGet.QueryString);
                    });
                }
            }
            if($scope.config.altSearch.indexdata && $scope.config.altSearch.indexdata.on){
                if(indexDataApi.runIndexDataSearch) {
                    indexDataApi.runIndexDataSearch(search.q);
                }
                $rootScope.$on('indexDataRecordsReady', function (n, args) {
                    $scope.federatedSearch.indexData.hits =
                        $scope.federatedSearch.indexData.indexdata_totalhits = args.merged;
                });
            }

        };

        if(doFetch) triggerSearch();

        $scope.patronRatings = [];
        $scope.reloadRatings = function() {
            return ratingService.getPatronRatings().then(function(ratings) {
                $scope.patronRatings = ratings;
            });
        };

    }])
    .controller('BibSummaryCtrl', ["$scope", "bibService", "configService", function ($scope, bibService, configService) {

        // Hack.  Ensures that a bib is in scope, given a bibid.
        // Assumes in ng-repeat, so no watch.
        $scope.config = configService;
        if($scope.bibid){
            bibService.get($scope.bibid).then(function(bib){
                $scope.bib = bib;
            });
        }
        // TODO: REMOVE ME.

    }])

    .controller('BibDetailCtrl', ["$scope", "$window", "$rootScope", "$uibModal", "$http", "$sce", "$timeout", "$injector", "kohaDlg", "bib", "holdings", "kohaTagsSvc", "kohaXISBNSvc", "kwNoveListSvc", "configService", "userService", "ratingService", "cartService", "$state", "kohaSearchSvc", "acqListSearchAPI", "$stateParams", "kwApi", "bvStrTmplParse", "$filter", function ($scope, $window, $rootScope, $uibModal, $http, $sce, $timeout,
                    $injector, kohaDlg, bib, holdings, kohaTagsSvc, kohaXISBNSvc, kwNoveListSvc,
                    configService, userService, ratingService,cartService, $state, kohaSearchSvc, acqListSearchAPI,
                    $stateParams, kwApi, bvStrTmplParse, $filter) {
        $scope.user = userService;

        $scope.search = kohaSearchSvc.currentSearch();

        $scope.showIncrement =5;
        $scope.showLimit = 5;
        $scope.showMore = false;

        $scope.setShowAll = function(){
            $scope.showLimit = bib.records.length;
        }
        $scope.invalidHash = {}
        $scope.setShowReset = function(what) {
            for ( var recIndex = 0; recIndex < bib.records.length; recIndex++ ) {
                var record = bib.records[recIndex];
                record.failed = null;
            }
        }

        $scope.updateLimit = function (much) {
            $scope.showLimit += much;
        }

        $scope.setLimit = function () {
            $scope.showLimit = $scope.showIncrement;
        }
        $scope.addToListDlgOpen = kohaDlg.addToList;

        $scope.processUUID = function(bib){
            $scope.processingUUIDs = true;
            $scope.showInvalid = [];
            $scope.validRecords = 0;
            if( !bib.records || bib.records.length == 0)
                return;
            var flatUUID = [];
            for(var bIndex = 0; bIndex < bib.records.length;bIndex++){
                var currGUIDE = bib.records[bIndex].GUIDE;
                var currUUID = bib.records[bIndex].uuid;
                if(currGUIDE)
                    flatUUID.push(currGUIDE)
                else if(currUUID)
                    flatUUID.push(currUUID)
            }

            /*ARCHVIEW.validateOtherDomain(flatUUID).done(function(value){
                $scope.showInvalid = value;
            }); */

            var UUIDS = [];
            var DLSOViewerService = $injector.get('DLSOViewerService');
            for ( var recIndex = 0; recIndex < bib.records.length; recIndex++ ) {
                var record = bib.records[recIndex];
                var GUIDE = record.GUIDE;
                var newRec = DLSOViewerService.extractMarc(record,bib);
                record.GUIDE = GUIDE;
                UUIDS.push(record.uuid);
            }
            $scope.remaining = UUIDS.length;
            function successCB(index, resolve) {
                return function (d) {
                    var currRecord = bib.records[index];
                    currRecord.failed = false;

                    var metadata = ARCHVIEW.metadataConversion(d.data);
                    currRecord.permitted = (metadata._acl_permitted === 1 || metadata._acl_permitted === "1") ? true : false;
                    delete metadata._acl_permitted;
                    currRecord.metadata = d.data;
                    currRecord.thumbnail =  '/api/dlso/' + currRecord.uuid + '/thumbnail';
                    currRecord.checked = false;

                    if (currRecord.linktext) {
                        currRecord._title = currRecord.linktext;
                        if(currRecord.size || metadata.product_size)
                            currRecord._title += (" - " + $filter('filesize')(currRecord.size || metadata.product_size));
                    } else {
                        var tmpl = new bvStrTmplParse(configService.dls.fileTmpl);
                        currRecord._title = tmpl.render(metadata) || currRecord.product_type;
                    }
                    if(!currRecord.summary){
                        kwApi.Dlso.summary( { id: currRecord.uuid } , (rsp) => {
                            console.log(rsp);
                            if(rsp.summary){
                                currRecord.summary = rsp.summary;
                            }
                        })
                    }

                    currRecord.tooltip = currRecord.filename || metadata.title;
                    $scope.validRecords++;
                    resolve(true);
                };
            }
            $scope.invalidHash = {};

            var tempUUIDS = UUIDS.slice();

            $scope.maxResults = $scope.showLimit;

            var queryMetadata = function(index){
              return new Promise( 
                function(resolve, reject)
                { 
                  return $http.get('/api/dlso/' + tempUUIDS[index] + '/metadata', {cache: true}).then(successCB(index,resolve), 
                  
                   function () {
                bib.records[index].failed = true;
                
                var currGUIDE = bib.records[index].GUIDE && bib.records[index].GUIDE.length == 49 ? bib.records[index].GUIDE.substr(8,4) : null;
                
                if(currGUIDE){
                    $scope.invalidHash[currGUIDE] = $scope.invalidHash[currGUIDE] == null ? 1 : $scope.invalidHash[currGUIDE] += 1;
                }
                
                resolve(true);
            });
                }
              );
            };   
                
            var loopDLSO = function(index){
              return queryMetadata(index).then(function(result){
                {
                  $scope.remaining--;
                 if(tempUUIDS.length > index && $scope.maxResults > $scope.validRecords && $scope.remaining > 0){
                  console.log(tempUUIDS[index])
                  return loopDLSO(index + 1)
                 }
                 else {
                  $scope.processingUUIDs = false;
                 }
                }
         
              })
            }
                
            return loopDLSO(0).then(function(){
            
                if(ARCHVIEW && ARCHVIEW.domainFallback && ARCHVIEW.domainFallback.allDomains ){
                    for(var i in $scope.invalidHash){
                        var count = $scope.invalidHash[i];
                        var domain = ARCHVIEW.domainFallback.allDomains[i*1];
                        if(domain == null)
                            $scope.showInvalid.push(count + " Digital Object(s) from Unknown Domain");
                        else
                            $scope.showInvalid.push(domain);
                    }
                }
                //force apply
                $timeout(function(){
                    $scope.$apply();
                },50);
            })

            /*var promises = tempUUIDS.map( function(uuid, index) {
                return $http.get('/api/dlso/' + uuid + '/metadata', {cache: true}).then(successCB(index), errorCB(index));
            });

            return $q.all(promises);*/
        };

        $scope.bib = bib; // this is actual bib object, not promise due to resolve on route.

        $scope.configService = configService;
        $scope.useCatSources = !!configService.catsources;
        if (configService.geospatialSearch && ARCHVIEW.mapLayers && ARCHVIEW.mapLayers.length > 0) {
            var mapComptrollerSvc = $injector.get('mapComptrollerSvc');
            mapComptrollerSvc.pullClassification(bib);
        }
            if((configService.geospatialSearch || configService.dls.enabled) && bib.marcXML == null){
                    $.ajax({
                    type: "GET",
                    url: ARCHVIEW.MarcXMLExport.format(bib.id) ,
                    cache: false,
                    processData : false,
                    contentType: "text/html",
                    dataType : "text",
                    headers: configService.getXSRFHeader(),
                    success: function (xml) {
                       
                        bib.marcXML = xml;
                        var groupy = [bib];
                        if(configService.dls.enabled)
                            $scope.processUUID(bib);
                        if (configService.geospatialSearch && ARCHVIEW.mapLayers && ARCHVIEW.mapLayers.length > 0) {
                            mapComptrollerSvc.mapCast('fillMap', {
                                results: groupy
                            })
                        }
                    },
                    error: function (err) {
console.warn(err);
                    }
                });
            }else{
                var groupy = [bib];
                if(configService.dls.enabled)
                    $scope.processUUID(bib);
                if (configService.geospatialSearch && ARCHVIEW.mapLayers && ARCHVIEW.mapLayers.length > 0) {
                    mapComptrollerSvc.mapCast('fillMap', {
                        results: groupy
                    })
                }
            }

        var completeRecordProcessing = function(bib,data,xml){
                            bib.marcXml = xml;
                            var bibby = data;
                            data.records = $.map(data.uuids,function(uuid){ return { uuid : uuid}; }),
                            $scope.processUUID(bibby);
                            $scope.bib.records = bibby.records;
                             //send message to map system to update list

                            if (configService.geospatialSearch && ARCHVIEW.mapLayers && ARCHVIEW.mapLayers.length > 0) {
                                var mapComptrollerSvc = $injector.get('mapComptrollerSvc');
                                mapComptrollerSvc.mapCast('updateBibData', bibby.records);
                            }
        };
        $scope.addDLSOToCart = function(){
            var triggerFailed = false;
            for(var recIndex = 0; recIndex < bib.records.length;recIndex++){
                var record = bib.records[recIndex];
                if(record.checked){
                    if(record.failed){
                        triggerFailed = true;
                    } else {
                        var text = record._title;
                        if(!text){
                            var metadata = ARCHVIEW.metadataConversion(record.metadata);
                            var tmpl = new bvStrTmplParse(configService.dls.fileTmpl);
                            text = tmpl.render(record.metadata) || record.tooltip;
                        }
                        cartService.addDLSO({
                            text : text,
                            bibTitle: bib.title,
                            uuid : record.uuid,
                            thumbnail : record.thumbnail,
                            marcXML : bib.marcXML,
                            marcID : bib.id
                        });
                    }
                }
            }
            if(triggerFailed)
                alert("Digital Documents that are not in the repository will not be added to the cart");

        };

        $scope.selectAllDLSOs = function(bool){
            for(var recIndex = 0; recIndex < bib.records.length; recIndex++){
                var record = bib.records[recIndex];
                if(bool && record.permitted){
                    record.checked = true;
                } else {
                    record.checked = false;
                }
            }
        };

        userService.whenAnyUserDetails().then(function(){
           $scope.updateButtons();
        });
        $scope.$on('loggedin', function(e, args) { $scope.updateButtons(); });
        $scope.$on('loggedout', function(e, args) { $scope.updateButtons(); });
        $scope.updateButtons = function(){
            $scope.canAttachDlso = $scope.canDeleteDlso = false;

            if(!configService.dls.enabled) return;
            $scope.canAttachDlso = (configService.catsources) ?
                    userService.can({editcatalogue:{ dlso:'attach' } }, "catsource="+bib.marc.subfield("942x"))
                    : userService.can({editcatalogue: { dlso : 'attach' } });
            $scope.canDeleteDlso = (configService.catsources) ?
                    userService.can({editcatalogue:{ dlso:'delete' } }, "catsource="+bib.marc.subfield("942x"))
                    : userService.can({editcatalogue: { dlso : 'delete' } });

                };
        $scope.showUpload = function(){
            //check BIB
            if(bib){
                var bibCatSource = bib.marc.has("942") ? bib.marc.subfield("942x") : null;
                if($scope.useCatSources && bibCatSource == null){
                    alert("The current catalogue does not have an associated Catalog Source.");
                    return;
                }
                else{
                    if(!userService.can({editcatalogue: { dlso : 'upload' } } , "catsource="+bibCatSource)) {
                        alert("You do not have permission to add documents to Catalogs with the "+ bibCatSource + " source");
                        return;
                    }
                }
            }

            //OPEN if user has access
            var bb = $uibModal.open({
                    backdrop: 'static',
                    templateUrl: '/app/static/partials/productUpload-modal.html',
                    controller: 'AddProductModalCtrl',
                    windowClass: "modal",
                    resolve: {
                        bib: function () {
                            return bib;
                        }
                    }
                });

            bb.result.then(function(){
                 //Update digital Documents
                 $http.get("/api/work/"+bib.id).then(function(rsp){ 
                //always refresh marcXML
                        $.ajax({
                        type: "GET",
                        url: ARCHVIEW.MarcXMLExport.format(bib.id) ,
                        cache: false,
                        processData : false,
                        contentType: "text/html",
                        dataType : "text",
                        headers: configService.getXSRFHeader(),
                        success: function (xml) {
                            completeRecordProcessing(bib,rsp.data,xml);
                        },
                        error: function (err) {
                        }
                    });
                 })
            });
            return false;
        };

        $scope.showDelete = function(){
            //check BIB
            if(bib){
                var bibCatSource = bib.marc.has("942") ? bib.marc.subfield("942x") : null;
                if( $scope.useCatSources && bib != null && !userService.can({editcatalogue: { dlso : 'delete' } } , "catsource="+bibCatSource)) {
                        alert("You do not have permission to delete DLSOs associated with the "+ bibCatSource + " source");
                        return;
                }else if( bib == null && !userService.can({editcatalogue: { dlso : 'delete' } } )) {
                        alert("You do not have permission to delete DLSOs");
                        return;
                }
                    
            }
            var deletionRecords = [];
            for(var recIndex = 0; recIndex < bib.records.length;recIndex++){
                var record = bib.records[recIndex];    
                if(record.checked){
                    deletionRecords.push(record);
                }
            }
            
            if(deletionRecords.length == 0){
                alert("Please select a DLSO to delete");
                return;
            }
     
            //OPEN if user has access
            var bb = $uibModal.open({
                    backdrop: 'static',
                    templateUrl: '/app/static/partials/productDelete-modal.html',
                    controller: 'DeleteProductModalCtrl',
                    windowClass: "modal",
                    resolve: {
                        bib: function () {
                            return bib;
                        },
                        deletions: function(){
                            return deletionRecords;
                        }
                    }
                });

            bb.result.then(function(){
                 //Update digital Documents
                 $http.get("/api/work/"+bib.id).then(function(rsp){ 
                //always refresh marcXML

                        $.ajax({
                        type: "GET",
                        url: ARCHVIEW.MarcXMLExport.format(bib.id) ,
                        cache: false,
                        processData : false,
                        contentType: "text/html",
                        dataType : "text",
                        headers: configService.getXSRFHeader(),
                        success: function (xml) {
                            completeRecordProcessing(bib,rsp.data,xml);
                        },
                        error: function (err) {
                        }
                    });

                 })

            });
            return false;

        };

        $scope.holdings = holdings;
        $scope.holdings.selectedItems = {};

        $scope.sameSelectionProgram = function () { // Determine whether items have the same selection program.
            var firstProgram = undefined,
                selectionProgram = undefined,
                theSame = true; // The same until proven otherwise.

            angular.forEach($scope.holdings.item, function (val, key) {
                if (val && val.fields) {
                    selectionProgram = val.fields.order_type + ' ' + val.fields.program;
                }

                if (!firstProgram) {
                    firstProgram = selectionProgram;
                }

                if (firstProgram !== selectionProgram) {
                    theSame = false;
                }
            });

            return theSame; // Returns true or false.
        }

        $scope.addItemsToAcqList = function() {
        // Only a single type now
        var foundType;
            var itemids = [];
            var types = {};
            angular.forEach($scope.holdings.selectedItems, function(val, itemid) {
                if (val) {
                    itemids.push(itemid);
                    var order_type = $scope.holdings.item[itemid].fields.order_type;
                    var order_subtype = $scope.holdings.item[itemid].fields.program;
            foundType = order_type;
           
                }
            });

            acqListSearchAPI.addItemsToList(itemids, foundType, $scope);
            $scope.holdings.selectedItems = {};
        };

        $scope.holdings = holdings;

        var check_callslip_perms = function(){
            var callslip_check = { op: 'check_place', work_id: bib.id, type: 'callslip' };
            if(configService.OPACCallslipRequests){
                $http.post('/api/callslip', $.param(callslip_check), {headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}} )
                    .then(function(rsp){
                        rsp.data.forEach(function(itemid){
                            itemid = parseInt(itemid,10);
                            $scope.holdings.item[itemid].callslip_allowed = true;
                            $scope.bib.callslip_allowed = true;
                    });
                });
            }
            if(configService.OPACDocumentDeliveryRequests){
                callslip_check.type = 'doc_del';
                $http.post('/api/callslip', $.param(callslip_check), {headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}} )
                    .then(function(rsp){
                        rsp.data.forEach(function(itemid){
                            itemid = parseInt(itemid,10);
                            $scope.holdings.item[itemid].doc_del_allowed = true;
                            $scope.bib.doc_del_allowed = true;
                    });
                });
            }
        };
        userService.whenGetUserId().then(function(){
            if(userService.loggedin){
                check_callslip_perms();
            } else {
                $scope.$on('loggedin', function(e) { check_callslip_perms(); });
            }
        });

        $scope.getFullDocumentURL = function(uuid){
            return "/api/dlso/"+uuid;
        }

        $scope.showDocument = function (uuid, idx) {
            var passedRecord = null;
            for(var currRec = 0; currRec < bib.records.length;currRec++){
                if(bib.records[currRec].uuid == uuid){
                    passedRecord = bib.records[currRec];
                }
            }
            var DLSOViewerService = $injector.get('DLSOViewerService');
            DLSOViewerService.show(passedRecord, bib, false, idx);

            return false;
        };

        let subtitle = 'Details for ' + bib.title;
        document.title = $rootScope.pageTitle + ' | ' + subtitle;

        $scope.hidden = {}; // for hiding item records in a summary.

        var isbn = (bib.isbn && typeof bib.isbn === 'object') ? bib.isbn[0] : bib.isbn;

        $scope.novelistContent = {
            on: kwNoveListSvc.on,
            authorSearch: function(author){  return "/app/search/author:(" + encodeURIComponent(author) + ")"; }
        };

        if(isbn && kwNoveListSvc.on){

            var youMayAlsoLike = { similarAuthors: true, similarSeries: true, similarTitles: true };
            // not actually using this -- but expect to group these three into one element.
            angular.forEach(
                kwNoveListSvc.getContent( isbn ),
                function(promise, feature){
                    promise.then(function(content){
                        $scope.novelistContent[feature] = content;
                        if(youMayAlsoLike[feature]) $scope.novelistContent.youMayAlsoLike = true;
                    });
                });
        }

        $scope.searchForTitleInHTML = $sce.trustAsHtml($scope.config.OPACSearchForTitleIn.replace(/TITLE/g, bib.title).replace(/AUTHOR/g, bib.marc.subfield('100a') || '').replace(/ISBN/g, isbn || ''));
        if ($scope.config.TagsShowOnDetail) {
            if (!bib.tags) {
                kohaTagsSvc.get($scope.bib.id).then(function (tags) {
                    bib.tags = tags;
                });
            }
        }

        $http.post('/api/work/'+ $scope.bib.id + '/log', $.param({ action: 'view' }),
                {headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}} );

        $scope.currentTab = 'holdings';
        $scope.tabSelect = function (tabname) {
            // hack to delay rendering of tab content until user clicks on tab.
            // This is here in case anything needs to be done on tab-switch.
            // We use Separate controllers inside tabs, which watch the currentTab value.
            $scope.currentTab = tabname;
        };

        // Syndetics data: load immediately.
        if ($scope.config.SyndeticsEnabled) {
            $http.get('/api/work/' + $scope.bib.id + '/syndetics').then(function (rsp) {
                $scope.syndetics = rsp.data;
            });
        }

        // XISBNs:  load immediately.
        if ($scope.config.OPACFRBRizeEditions) {
            $scope.xisbns = [];
            kohaXISBNSvc.get($scope.bib.id).then(function (editions) {
                editions.forEach(function (bib_q) {
                    bib_q.then(function (bib) {
                        $timeout(function () {
                            $scope.xisbns.push(bib);
                        }, 0);

                    });
                });
            });
        }

        if(bib.isSerial && configService.OPACSerialIssueDisplayCount){
            kwApi.Periodical.getForBib({bibid: bib.id}, function(pubs){
                var hasSubs = pubs.reduce(function(acc,pub){ return acc + pub.total_subscriptions; }, 0);
                if(hasSubs) $scope.periodicals = pubs;
            });
        }

        $scope.staffView = function() {
            $state.go('staff.bib.details', {biblionumber: $scope.bib.id});
        };

        $scope.recordView = function (param) {
            // open modal viewer for marc record.
            kohaDlg.recordView($scope.bib.id, param);
            return false;
        };

        $scope.callslipDlgOpen = function (type) {
            if (!type) type = 'callslip';

            $uibModal.open({
                backdrop: false,
                templateUrl: '/app/static/partials/callslip-modal.html',
                controller: 'CallslipDlgCtrl',
                windowClass: "modal callslip-add",
                resolve: {
                    type: function () {
                        return type;
                    },
                    bib: function () {
                        return {
                            id: $scope.bib.id,
                            title: $scope.bib.title
                        };
                    }
                }
            });
            return false;
        };

        $scope.reviewDlgOpen = function () {
          userService.whenAuthenticatedUserDetails().then(function(){
            $uibModal.open({
                backdrop: false,
                templateUrl: '/app/static/partials/review-modal.html',
                controller: 'ReviewDlgCtrl',
                windowClass: "modal review-add",
                resolve: {
                    bib: function () {
                        return {
                            id: $scope.bib.id,
                            title: $scope.bib.title
                        };
                    },
                    // myreview: function(kohaReviewSvc){ return kohaReviewSvc.mine; }
                    // The button to open this dlg shouldn't appear until reviewSvc has resolved.
                }
            });
          })

          return false;
        };

        $scope.download = function (format) {
            //FIXME: Do refworks 'import to option.'
            if (format == 'refworks') {
                var msgbox = kohaDlg.dialog({
                    heading: 'Export to RefWorks',
                    message: 'If you are logged in to RefWorks, click "Direct export" to send the record directly to your RefWorks database.  Choose "Download" to import manually.',
                    buttons: [{
                        label: 'Download',
                        val: 'download'
                    }, {
                        label: 'Direct export',
                        val: 'send'
                    }]
                });

                msgbox.result.then(function (result) {
                    if (result === 'send') {
                        $http.get('/api/work/' + $scope.bib.id + '/exports/refworks').
                        then(function (rsp) {
                            var href = 'https://www.refworks.com/express/ExpressImport.asp?vendor=Koha&filter=Marc%20Format&encoding=65001';
                            var form = $('<form method="post"></form>').attr('action', href).attr('target', 'RefWorksMain');
                            var textarea = $('<textarea name="ImportData">');
                            textarea.text(rsp.data);
                            form.append(textarea);
                            $(document.body).append(form);
                            form.submit();
                            form.remove();
                        });
                    } else {
                        $window.open("/api/work/" + $scope.bib.id + "/exports/" + format + "?download=true");
                    }
                });
            } else {
                $window.open("/api/work/" + $scope.bib.id + "/exports/" + format + "?download=true");
            }

        };


        $scope.patronRatings = [];

        var dereg;
        $scope.watchRatings = function() {
            dereg = $scope.$watch('patronRatings[bib.id]', function(newVal, oldVal) {
                if (newVal && (newVal !== oldVal)) {
                    ratingService.submit(newVal, $scope.bib.id).then(function(data) {
                        $scope.bib.summary.average_rating = data.average_rating;
                    }, function() {
                        // Prevent infinite loop, and no reason to continue anyway
                        dereg();
                        $scope.ratings[bib.id] = oldVal;
                    });
                }
            });
        };

        $scope.reloadRatings = function() {
            return ratingService.getPatronRatings().then(function(ratings) {
                $scope.patronRatings = ratings;
                $scope.watchRatings();
            });
        };

        $scope.reloadRatings();
        $scope.$on('loggedin', function(e, args) {
            if (dereg) {
                dereg();
                dereg = null;
            }

            $scope.reloadRatings();
        });
        $scope.$on('loggedout', function(e, args) {
            if (dereg) {
                dereg();
                dereg = null;
            }

            $scope.reloadRatings();
        });

        $scope.hasOrderTags = false;  
        $scope.tagresults = 0;
        $scope.getOrderTags = function(){ 
            $http.get('/api/work/' + $scope.bib.id + '/order_tags').then(function (response) {
                $scope.orderTagsData = response.data;
                if ( response.data.length > 0 ) {
                    $scope.hasOrderTags = true;
                    $scope.tagresults = response.data.length;
                }
            });
        };
        $scope.getOrderTags();
        $scope.showOrderTags = function(){
            $uibModal.open({
                backdrop: false,
                templateUrl: '/app/static/partials/order-tags-modal.html',
                controller: 'OrderTagsDlgCtrl',
                windowClass: "modal order-tags",
                size: 'lg',
                resolve: {
                    tags: function () {
                        return $scope.orderTagsData;
                    },
                    items: function() {
                        return $scope.holdings.item;
                    }
                }
            });
            return false;
        };

        if ( $stateParams.place_hold ) {
            // allows third-party links to place hold.
            $scope.placeHold( bib.id );
        }
    }])  // end bibdetailctrl.

    .controller('OrderTagsDlgCtrl', ["$scope", "$uibModalInstance", "tags", "items", "userService", function ($scope, $uibModalInstance, tags, items, userService) {
        $scope.ordertags = tags || [];
        angular.forEach($scope.ordertags, function(t) {
            var item_id = t.itemid;
            if (t.status == '' || t.status == ' ') {
                t.status = (t.is_active ? 'Active' : 'Inactive');
            }
            if (!t.extract_date) t.extract_date = t.created_date;
            if (items[item_id]) {
                t.product_name = items[item_id].itemnotes;
                t.product_number = items[item_id].itemcallnumber;
                t.procurement_type = items[item_id].fields.procurement_type;
            }
            else {
                if (!t.product_name) t.product_name = '(not found)';
                if (!t.product_number) t.product_number = '(not found)';
                if (!t.procurement_type) t.procurement_type = '(not found)';
            }
        });
        $scope.orderstatus = 'Active';
        $scope.user = userService;
     
        $scope.close = function () {
            $uibModalInstance.close();
        };
    }])    


    .controller('CallslipDlgCtrl', ["$scope", "$uibModalInstance", "$state", "type", "bib", "kohaCallslipSvc", "bibService", "configService", "userService", "alertService", function ($scope, $uibModalInstance, $state, type, bib, kohaCallslipSvc, bibService, configService, userService, alertService) {
        $scope.request = {
            biblionumber: bib.id,
            request_type: type,
            bib: bib,
            rfi: (bib.id) ? false : true
        };

        $scope.request_type_label = configService.display(type,'callslip_types');
        $scope.branches = configService.interpolator('branch').dict();

        $scope.config = configService;
        $scope.items = [];

        if(!$scope.request.rfi){
            bibService.holdings(bib.id).then(function (holdings) {
                holdings.items.forEach(function(item) {
                    if (type=='callslip' && item.callslip_allowed
                        || type=='doc_del' && item.doc_del_allowed) {
                            $scope.items.push(item); 
                        }
                });
            });
            userService.whenAnyUserDetails().then(function (details) {
                $scope.request.pickup_branch = details.branchcode;
            });
        }

        // <!-- TODO: Check for existing request and disallow if exists -->

        $scope.datePickOpen = false;
        $scope.openDatePicker = function($event) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.datePickOpen = true;
        };

        $scope.dlgSubmit = function () {
            kohaCallslipSvc.submit($scope.request).then(function () {
                alertService.add({
                    msg: 'Request successfully submitted!',
                    type: 'success'
                });
                $uibModalInstance.close();
                if($state.current.controller == 'UserCallslipsCtrl'){
                    $state.reload();
                }
            }).catch(function (data) {
                alertService.add({
                    msg: 'Request submission failed.  ' + data,
                    type: 'error'
                });
            });

        };
    }])

    .controller('ReviewDlgCtrl', ["$scope", "$uibModalInstance", "bib", "kohaReviewSvc", "alertService", function ($scope, $uibModalInstance, bib, kohaReviewSvc, alertService) {
        if ( !($scope.review = kohaReviewSvc.mine) ) {
          $scope.review = {};
        }

        $scope.bib = bib;
        $scope.submitReview = function () {
            kohaReviewSvc.submit($scope.review).then(function () {
                alertService.add({
                    msg: 'Comment submission succeeded!  The library will publish your comments after review.',
                    type: 'success'
                });
                $uibModalInstance.close();
            }).catch(function (data) {
                alertService.add({
                    msg: 'Comment submission failed.  ' + data,
                    type: 'error'
                });
            });
        };
    }])

    // Controllers for Bib Details tabs.
    // These are all child scopes of the BibDetailCtrl.
    // They watch $scope.currentTab to render.

    .controller('ReviewsTabCtrl', ["$scope", "kohaReviewSvc", function ($scope, kohaReviewSvc) {

        $scope.rendered = false;
        $scope.reviewSvc = kohaReviewSvc;

        $scope.$watch('currentTab', function (val) {
            if (!$scope.rendered && val === 'reviews') {
                $scope.rendered = true;
                kohaReviewSvc.get($scope.bib.id);
            }
        });

    }])

    .controller('publicMastheadCtrl', ["$scope", "$state", "$location", "$injector", "$timeout", "configService", "kohaSearchSvc", "SearchQuery", "kwLuceneParser", function($scope, $state, $location, $injector, $timeout,
                    configService, kohaSearchSvc, SearchQuery, kwLuceneParser){

        // MastheadSearchConfig describes all config options.
        // mastheadPrefilters includes actual facet values.

        $scope.srchCfg = angular.copy(configService.mastheadSearchConfig);

        function focusNextInput($input){
            var me;
            var inputs = $input.closest('form').find('input');
            for (var i = 0; i < inputs.length; i++) {
                if(me){
                    $(inputs[i]).focus();
                    break;
                }
                else if(inputs[i] == $input[0]){
                    me = inputs[i];
                }
            }
        }

        $scope.fieldSelect = {
            options: $scope.srchCfg.queryFieldOptions,
            selectizeConfig: {
                valueField: 'field',
                labelField: 'label',
                maxItems: 1,
                searchField: ['label'],
                closeAfterSelect: true,
                openOnFocus: false,
                plugins: [ 'selectize-plugin-a11y' ],
                onDropDownOpen: function(j){
                    console.log(j);
                }
                // onChange: function(val){    // single-select, so focusnext.
                //     var selectize = this;
                //     $timeout(function(){
                //         focusNextInput(selectize.$control_input);
                //     });
                // }
            }
        };
        $scope.simpleFilterOptions = (configService.mastheadPrefilters._simple_||{}).options || [];

        $scope.prefilters = angular.copy(configService.mastheadPrefilters);

        $scope.orderedSelects = [];

        var fq = $location.search().fq;
        var hasFq = {};
        if (typeof(fq) === 'string') {
            hasFq[fq] = 1;
        }
        else if (typeof(fq) === 'object') {
            fq.forEach(function(fqv) {
                hasFq[fqv] = 1;
            });
        }


        $scope.srchCfg.prefilter.filters.forEach(function(filtercfg){
            if (!$scope.prefilters[filtercfg.filterName]) return;
            if (!filtercfg.filterDef) return;

            var pf = $scope.prefilters[filtercfg.filterName];

            pf.default = filtercfg.default;
            pf.filterDef = angular.copy(filtercfg.filterDef);
            var unlim;
            if(filtercfg.filterDef.unlimited){
                unlim = filtercfg.filterDef.unlimited;
                pf.options.unshift(
                    { label: unlim, fq: '' }    // #174059600
                );
            }

            pf.selectizeConfig = {
                maxItems: 1,
                valueField: 'fq',
                labelField: 'label',
                searchField: ['label','fq'],
                // placeholder: pf.full_label || ("Filter by " + pf.label),
                closeAfterSelect: true,
                openOnFocus: false,
                plugins: [ 'selectize-plugin-a11y' ],
                //allowEmptyOption: (unlim ? true : false), #174059600
                allowEmptyOption: false,
                placeholder: unlim,
                // onChange: function(val){
                //     var selectize = this;
                //     $timeout(function(){
                //         focusNextInput(selectize.$control_input);
                //     });
                // }
            };

            $scope.orderedSelects.push(pf);
        });

        $scope.clearPrefilters = function(reinit){
            // TODO: allow default to come from user prefs.
            angular.forEach($scope.prefilters, function(filt, name){
                var val;
                if(filt.default) val = filt.options.find(function(el){ return el.fq==filt.default; });
// console.log("Setting value to " + filt.value);
                filt.value = val || filt.options[0];
        if (typeof(filt.value) == 'object')
            filt.value = filt.value.fq;

                if (fq && reinit) {
                    filt.options.forEach(function(opt) {
                        if (hasFq[opt.fq]) {
                            filt.value = opt.fq;
                        }
                    });
                }


            });
        };
        $scope.clearPrefilters($scope.srchCfg.prefilter.trackUrlState);

        $scope.query = {
            field: '',
            q: '',
            clear: function(){
                this.field = this.q = '';
                $scope.clearPrefilters();
            },
            clearMinimal: function() {
                this.field = this.q = '';
                if ($scope.srchCfg.prefilter.clearOnStateChange)
                    $scope.clearPrefilters();
            },
        };

        $scope.simpleSearch = function(fq){
            // single-fq initiated from query input dropdown, or just from unadorned query input.
            var query = {
                q: kwLuceneParser.composeSubqueries([{ field: $scope.query.field, q: $scope.query.q }])
                };
            if(fq) query.limits = { fq: fq };
            var search = new SearchQuery(query);
            kohaSearchSvc.doSimpleSearch(search);
            return false;
        };

        $scope.prefilterSearch = function(){
            var query = {
                q: kwLuceneParser.composeSubqueries([{ field: $scope.query.field, q: $scope.query.q }])
                };

            var fq_arr = [];
            angular.forEach($scope.orderedSelects,
                function(filter,i){
                    if(filter.value){
                        if(angular.isArray(filter.value.fq)){
                            fq_arr = fq_arr.concat(filter.value.fq);
            } else if (typeof(filter.value) === 'string') {
                fq_arr.push(filter.value);
                        } else {
                            if(filter.value.fq) fq_arr.push( filter.value.fq );
                        }
                    }
                });
            if(fq_arr.length) query.limits = { fq: fq_arr };
            var search = new SearchQuery(query);
            kohaSearchSvc.doSimpleSearch(search);
            return false;
        };
        $scope.clearSearch = function(){
            $state.params.query = "";
            if (configService.geospatialSearch && ARCHVIEW.mapLayers && ARCHVIEW.mapLayers.length > 0) {
                var mapComptrollerSvc = $injector.get('mapComptrollerSvc');
                if(mapComptrollerSvc) mapComptrollerSvc.clearAOIAndDontSearch();
            }
            $scope.query.clear();
        };

        $scope.$on('loggedout', function(){
            $scope.query.clear();
        });
        $scope.$on("triggerClearAll",function(event,message){
            $scope.query.clear();
        });

        $scope.$on('$stateChangeSuccess', function (e, toState) {

            if($state.includes('search-results')){
                // TODO: possibly also show search if we're on bib-details.
                // FIXME: Also set prefilters.
                // timeout since the controller sets the current search.
                $timeout(function(){
                    var curSrch = kohaSearchSvc.currentSearch();
    // this may be a race condition.

                    if(curSrch){
                        var parsed = kwLuceneParser.extractSubqueries(curSrch.q);
                        if(parsed.subqueries.length==1){
                            var field = parsed.subqueries[0].field;
                            var q = parsed.subqueries[0].q;
                            if(($scope.fieldSelect||{}).options && $scope.fieldSelect.options.filter(function(s){ return s.field==field;}).length){
                                $scope.query.field = field;
                            }
                            if(/^\([^\(\)]+\)$/.test(q)){
                                q = q.replace(/^\(|\)$/g, '');
                            }
                            $scope.query.q = q;
                        }
                    }
                });
            } else {
              $scope.query.clearMinimal();
              if(configService.geospatialSearch){
                var mapComptrollerSvc = $injector.get('mapComptrollerSvc');
                if(mapComptrollerSvc) mapComptrollerSvc.clearAOIAndDontSearch();
              }
            }
        });

    }])

    .controller('KohaCtrl', ["$scope", "$location", "$state", "$rootScope", "$injector", "$uibModal", "$timeout", "kohaDlg", "kohaSearchSvc", "userService", "configService", "cartService", "kohaListsSvc", "messageService", "kwSocialMediaSvc", "$filter", "$http", function ($scope, $location, $state, $rootScope, $injector, $uibModal, $timeout, kohaDlg,
            kohaSearchSvc, userService, configService, cartService, kohaListsSvc, messageService, kwSocialMediaSvc, $filter, $http) {

        // Note $location may cause problems in IE.  See comments sectionat http://docs.angularjs.org/guide/ie
        //

        $scope.root = $rootScope;
        $rootScope.loading = false;

        $scope.masthead = {
            public_view : false,
            docked: false
        };

        if (configService.defaultPublicMasthead) {
            $scope.masthead.public_view = true;
        }
        $scope.skipToContent = function(){
            $('#main-content').attr('tabindex', -1).on('blur focusout', function () {
                $(this).removeAttr('tabindex');
            }).focus();
        }
        $scope.globalfooter = {
            visible: true
        };

        $scope.togglePublicView = function(){ $scope.masthead.public_view = !$scope.masthead.public_view; };
        $scope.toggleMasthead = function(){
            $scope.masthead.docked = ($scope.masthead.docked) ? null : 'user';
        };

        $scope.user = userService;
        userService.whenAnyUserDetails().then(function (details) {
            $scope.userdetails = details;
        });

        messageService.start();

        $rootScope.$on('loggedout', function(){
            $scope.userdetails = {};
            userService.whenAnyUserDetails().then(function (details) {
                $scope.userdetails = details;

            });
            // kohaSearchSvc.query = new SearchQuery({
            //     q: "",
            //     limits:  {}
            // });
            $location.$$search = {};
            //We're explicitly redirecting in userService now.
            //$scope.goHome();
        });

        $rootScope.$on('loggedin', function() {
            userService.whenAnyUserDetails().then(function(details) {
                $scope.userdetails = details;
                if (userService.is_staff) {
                    if ($location.path() == '/') {
                        $location.path('/app/staff').replace();
                    }
                }
            });
        });

        $scope.config = configService;
        $scope.cart = cartService;
        $scope.lists = kohaListsSvc;
        $scope.lists.sync();

        // handles search-to-issue and search-to-hold


        $scope.searchToPick = $rootScope.searchToPick;

        $scope.repeatLastSearch = function(){
            var currentSearch = kohaSearchSvc.currentSearch();
            if(currentSearch){
                $state.go('search-results.koha', currentSearch.stateParams(), {inherit: false} );
            }
        };

        $scope.rfiDlgOpen = function (type) {

            throw('FIXME: must reimplement in public-nav component');
            // $uibModal.open({
            //     backdrop: false,
            //     templateUrl: '/app/static/partials/callslip-modal.html',
            //     controller: 'CallslipDlgCtrl',
            //     windowClass: "modal callslip-add",
            //     resolve: {
            //         type: function () { return type; },
            //         bib: function () { return {}; }
            //     }
            // });
            // return false;
        };

        $scope.placeHold = function (bibid, patronid) {
            userService.whenAuthenticatedUserDetails().then(function(d){
                if(!$scope.user.can_place_holds) return;
                kohaDlg.placeHold([bibid], patronid);
            });
            return false;
        };

        $scope.cartDlgOpen = function () {
            console.warn(' cartDlgOpen !!!');
            $state.go('cart');
            return false;
        };

        // $scope.listDlgOpen = "kohaDlg.lists"; // now lists

        // $scope.addToListDlgOpen = "kohaDlg.addToList"; 

        $scope.viewCitationsDlgOpen = function (bibids) {
            $uibModal.open({
                backdrop: false,
                templateUrl: '/app/static/partials/view-citations-modal.html',
                controller: 'ViewCitationsDlgCtrl',
                windowClass: "modal",
                resolve: {
                    bibids: function () {
                        return bibids;
                    }
                }
            });
            return false;
        };

        $scope.$on('openViewCitationsDlg', function (e, data) {
            $scope.viewCitationsDlgOpen(data);
        });
        $scope.$on('loginRequired', function (e, data) {
            userService.whenAuthenticatedUserDetails({redirectOnFail: '/'});
        });

        $scope.$on('loadingAdd', function () {
            $timeout(function(){
                $rootScope.loading = true;
            });
        });
        $scope.$on('loadingResolve', function () {
            //spinner might not even show b/c of caching
            $timeout(function(){
                $rootScope.loading = false;
            });
        });

        // if (configService.external_auth.saml && !configService.external_auth.saml.iframe) {
        //     $rootScope.samlRedirectLogin = function() {
        //         window.location.assign('/api/saml/login');
        //     };
        //     $rootScope.samlAllowMenuLocalLogin = configService.external_auth.saml.local_login;
        // }

        // if (configService.external_auth.saml && configService.external_auth.saml.sso_logout) {
        //     $rootScope.samlRedirectLogout = function() {
        //         window.location.assign('/api/saml/logout');
        //     };
        //     $rootScope.samlAllowMenuLocalLogout = configService.external_auth.saml.local_logout;
        // }
        $rootScope.loginDlgOpened = false;  // (debounce)
        $rootScope.loginDlgOpen = function (options) {

            console.error('just use whenAuthenticatedUser ...  but that may still need the debounce.');

            // if (!$rootScope.loginDlgOpened) {
            //     $uibModal.open({
            //         backdrop: (configService.authRequired) ? 'static' : false,
            //         keyboard: !configService.authRequired,
            //         templateUrl: '/app/static/partials/login-modal.html',
            //         controller: 'LoginDlgCtrl',
            //         size: ((configService.external_auth.saml||{}).iframe ? 'lg' : 'md'),
            //         resolve: {
            //             loginOptions: function () {
            //                 return options || {};
            //             },
            //         }
            //     });
            // }
            // else {
            //     console.log("Login dlg already open");
            // }
            // return false;
        };

        $rootScope.lostPassDlgOpened = false;
        $rootScope.lostPassDlgOpen =  "kohaDlg.lostPass";

        $scope.loginSocial = kwSocialMediaSvc.authInit;

        $scope.socialLogin = configService.SocialLogin;
        $scope.socialMediaMethods = kwSocialMediaSvc.methods;

        $scope.savedSearchDlgOpen = kohaDlg.savedSearch;

        $scope.suggestionDlgOpen = function () {
            $uibModal.open({
                backdrop: true,
                templateUrl: '/app/static/partials/suggestion-modal.html',
                controller: ["$scope", "$rootScope", "kohaSuggestSvc", "userService", "$uibModalInstance", function ($scope, $rootScope, kohaSuggestSvc, userService, $uibModalInstance) {
                    $scope.suggestion = {
                        notifications: {
                            ACCEPTED: true,
                            ORDERED: true,
                            RECEIVED: true,
                            AVAILABLE: true,
                            REJECTED: true
                        }
                    };
                    $scope.user = userService;
                    $scope.submit = function (data) {
                        var codes = [];
                        angular.forEach(data.notifications, function(val, key) {
                            if (val) {
                                codes.push(key);
                            }
                        });
                        data.notifications = codes.join(',');
                        kohaSuggestSvc.submit(data).then(function () {
                            $uibModalInstance.close();
                            $rootScope.$broadcast('suggestionSubmitted');
                        });
                    };
                }],
                windowClass: "modal purchase-suggestion"
            });
            return false;
        };


        $rootScope.$on("triggerClearMap",function(event,message){
            if (configService.geospatialSearch && ARCHVIEW.mapLayers && ARCHVIEW.mapLayers.length > 0) {
                var mapComptrollerSvc = $injector.get('mapComptrollerSvc');

                mapComptrollerSvc.resultSet = [];
                mapComptrollerSvc.shape = "rectangle";
                mapComptrollerSvc.mapCast("clearRecords");
                mapComptrollerSvc.aoi = null;
                mapComptrollerSvc.aoiMethod = null;
                //mapComptrollerSvc.mapExtent = null;
                mapComptrollerSvc.mapCast("transferAOI", {
                    aoi: null,
                    aoiMethod: null, 
                    ignoreExtent : true
                });
            }
console.log('triggerClearMap');
            var curSearch = kohaSearchSvc.currentSearch();
            if(curSearch){
                curSearch.rmLimit('geo-shape', null);
                $state.go('search-results.koha', curSearch.stateParams(), {inherit: false});
            }
            $scope.$apply();
        });

        // $scope.exitProxy = function() {
        //     userService.exitProxy();
        // };

        // $scope.setLoginBranch = function(){
        //     var branchMap = configService.interpolator('branch').dict()
        //     var branches = [];
        //     angular.forEach(branchMap, function(val, key) {
        //         if (userService.canInBranch({parameters: 'set_branch'}, key)) {
        //             branches.push({branchcode: key, branchname: val});
        //         }
        //     });

        //     branches = $filter('orderByDisplay')(branches,'branchcode');
        //     console.dir(branches);

        //         kohaDlg.dialog({
        //             heading: "Change Login Location",
        //             message: "Please select your library.",
        //             inputs: [{
        //                 name: 'branch',
        //                 label: 'Library',
        //                 val: userService.login_branch,
        //                 options: 'b.branchcode as b.branchname for b in branches'
        //             }],
        //             scopevars: { branches : branches },
        //             buttons: [{
        //                 val: true,
        //                 label: 'Save',
        //                 btnClass: "btn-primary",
        //                 submit: true
        //             }, {
        //                 val: false,
        //                 label: 'Cancel'
        //             }]
        //         })
        //             .result.then(function (val) {
        //                 if (val) {
        //                     userService.setLoginBranch(val.branch).catch(function(e){
        //                         console.warn(e);
        //                     });
        //                 }
        //             });
        // };

        // $scope.updateUserDetails = function(mustRequire) {
        //     var len = mustRequire.length;
        //     var fields = ' ';
        //     for (var i = 0; i<len; i++) {
        //         fields = fields + mustRequire[i] + ' ';
        //     }
        //     kohaDlg.dialog({
        //         heading: 'Update Your Details',
        //         message: 'Some of your personal details are missing, please take a moment to update them. Thank you. Required:' + fields,
        //         buttons: [{val: true, label: 'Update', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}]
        //     }).result.then(function(rv) {
        //         if (rv) {
        //          $location.path('/app/me/details');
        //         }
        //     });
        // }; 

        // $rootScope.checkRequiredUserDetails = function(requiredDetails, userDetailsData) {
        //     var regex = /\W+/;
        //     var mustRequire = requiredDetails.split(regex);
        //     var len = mustRequire.length;
        //     for (var i = 0; i<len; i++) {
        //         if (i in mustRequire) {
        //             var s = mustRequire[i];
        //             var val = userDetailsData[s];
        //             if (val && val.trim()) {
        //                 mustRequire.splice(i, 1);
        //             }
        //         }
        //     }
        //     if (mustRequire.length > 0) {
        //         $scope.updateUserDetails(mustRequire);
        //     }           
        // };        
        
        // check the required user details for ldap-authenticated users
        // who have bypassed the regular login control functions.

// move this into userService.
// //
        // userService.whenAnyUserDetails().then(function (uid) {
        //     if ($scope.user.loggedin && $scope.config.UserRequiredDetails) {
        //         var requiredDetails = $scope.config.UserRequiredDetails;
        //         var userDetailsData = $scope.user.details_data;
        //         $rootScope.checkRequiredUserDetails(requiredDetails, userDetailsData);
        //     }
        // });        


        $rootScope.tracingOptions = false;
        $rootScope.setApiTracing = function(opts, duration) {
            $rootScope.tracingOptions = opts;
            if (!opts || opts.length == 0) {
                delete $http.defaults.headers.common['X-KohaOX-Trace'];
                $rootScope.tracing = false;
                KOHA.logIntercept(false);
            }
            else {
                $http.defaults.headers.common['X-KohaOX-Trace'] = opts.join(',');
                $rootScope.tracing = true;
                var frontendOpts = [];
                opts.forEach(function(opt) {
                    if (opt.substr(0,8) == "Frontend") {
                        frontendOpts.push(opt);
                    }
                });
                if (frontendOpts.length > 0) {
                    KOHA.logIntercept(true, frontendOpts);
                }
                $timeout(function() {
                    $rootScope.setApiTracing(false);
                }, duration*1000);
            }
            $rootScope.$emit('setApiTracing', opts);
        };

        $rootScope.$on('sys.signal.trace', function(evnt, msg) {
            console.log("User tracing toggle");
            console.dir(msg.options);
            $rootScope.setApiTracing(msg.options, msg.duration);
        });
    }])

    .controller('AuthoritiesCtrl', ["$scope", "authoritiesSvc", "$uibModal", function ($scope, authoritiesSvc, $uibModal) {
        $scope.helptoggle = true;
        $scope.search = authoritiesSvc;
        $scope.currentPage = 1;

        $scope.viewMarc = function (marc) {
            // open modal viewer for marc record.
            $uibModal.open({
                backdrop: true,
                templateUrl: '/app/static/partials/marcview-modal.html',
                controller: ["$scope", function ($scope) {
                    $scope.marc = marc;
                    $scope.kind = "Authority heading";
                }],
                windowClass: "modal marc-viewer",
            });

            return false;

        };

        $scope.pageChanged = function () {
            $scope.search.pager.setPage($scope.currentPage);
            $scope.search.fetch();
        }

        $scope.authfqmap = function (fq) {
            var fq2txt = {
                "": "all authors/subjects/titles",
                "kauthtype_s:(PERSO_NAME OR CORPO_NAME OR MEETI_NAME)": "all authors",
                "kauthtype_s:(CORPO_NAME)": "corporate names",
                "kauthtype_s:(MEETI_NAME)": "meeting names",
                "kauthtype_s:(PERSO_NAME)": "personal names",
                "kauthtype_s:(CHRON_TERM OR GENRE/FORM OR TOPIC_TERM OR GEOGR_NAME)": "all subjects",
                "kauthtype_s:(CHRON_TERM)": "chronological terms",
                "kauthtype_s:(GENRE/FORM)": "genre/form terms",
                "kauthtype_s:(TOPIC_TERM)": "topical terms",
                "kauthtype_s:(GEOGR_NAME)": "geographical terms",
                "kauthtype_s:(UNIF_TITLE)": "all titles"
            };
            return fq2txt[fq] || fq;
        };

    }])

    .controller('CartCtrl', ["$scope", "$rootScope", "$window", "configService", "cartService", "alertService", "userService", "kohaDlg", "BibBatchService", "bibService", "$http",
                    function ($scope, $rootScope, $window, configService, cartService, alertService, userService, kohaDlg, BibBatchService, bibService, $http) {
        $scope.config = configService;
        $scope.cart = cartService;
        $scope.user = userService;
        $scope.bibService = bibService;
        // $scope.modal = $uibModalInstance;

        $scope.dlso = {};

        var userdata = {};
        userService.whenAnyUserDetails().then(
            function(details){
                userdata = details;
                $scope.dlso.email = userdata.email;
        });

        $scope.placeHoldsFromCart = function () {
            userService.whenAuthenticatedUserDetails().then(function(d){
                if(!userService.can_place_holds) return;
                var bibids = [];
                $.each($scope.cart.selected, function (bibid, bool) {
                    if (bool) bibids.unshift(bibid);
                });
                kohaDlg.placeHold(bibids);
            });
        };
        $scope.addToListFromCart = function () {
            var bibs = Object.keys($scope.cart.selected).filter(function (bibid) {
                return $scope.cart.selected[bibid];
            });
            if (bibs.length) {
                kohaDlg.addToList( bibs );
            }
        };
        $scope.emptyClose = function(){
            $scope.cart.removeAll();
            $window.history.back();
        };
        $scope.close = function(){ $window.history.back(); }
        $scope.emptyBibsClose = function(){
            $scope.cart.removeAllBibs();
            $window.history.back();
        };

        $scope.sendCart = function () {
            if (!cartService.bibs.length) return;

            if(userdata.email){
                kohaDlg.dialog({
                        heading: "Email " + configService.wording.cart,
                        message: "Please enter the recipient email address.",
                        inputs: [{
                                name: 'recipient',
                                label: 'Recipient email',
                                val: userdata.email,
                                type: 'email'
                            },{
                                name: 'subject',
                                label: 'Subject',
                                val: "Your " + configService.pageTitle + " " + configService.wording.cart
                            }],
                        buttons: [{
                            val: true,
                            label: 'Send',
                            btnClass: "btn-primary"
                        }, {
                            val: false,
                            label: 'Cancel'
                        }]
                    }).result.then(function (modalresult) {
                        if (modalresult) {
                            var options = {
                                op: 'email',
                                recipient: modalresult.recipient,
                                subject: modalresult.subject
                            };
                            BibBatchService.submit(cartService.bibs, options);
                        }
                    });
            } else {
                kohaDlg.dialog({
                    type: "notify",
                    alertClass: "warning",
                    heading: "No email address on file",
                    message: "You must have an email address on file with the library to send email."
                });
            }
        };
        $scope.renderCart = function(dload){
            var options = {
                op: (dload) ? 'download' : 'print',
                subject: "Your " + configService.pageTitle + " " + configService.wording.cart
            };
            BibBatchService.submit(cartService.bibs, options);
        };

        $scope.user.canCreateImportBatch = userService.can({tools:'stage_marc_import'});
        $scope.batchEditCart = function() {
            var params = {
                op: 'create-bib-catalog-batch',
                bib_ids: cartService.bibs.join(',')
            };
            return $http.post('/api/import-batch', $.param(params),{headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
                .then(function(resp) {
                    alertService.add({msg: "New batch created.", type: "success"});
                }, function(rsp){
                    var ermsg = "There was a problem with your request.";
                    alertService.add({msg: ermsg, type: "error", persist: true});
                }
            );
        };

        $scope.removeDLSO = function(guid){
            $scope.cart.removeDLSO(guid);
        }

        $scope.metadata_options = [];
        $scope.metadataSchema_options = [];

        $scope.processDLSOCartOptions = function(){
            $scope.ftp = {compressed : false};
            $scope.dlso.deliveryOption = "download";

            $scope.dlso.downloadType = "F";
            $scope.dlso.packageType = "Zip";
            $scope.metadata_options =[];
            $scope.metadataSchema_options = [];

            if($scope.cart.dlsos.length == 0)
                return;

            var coverageString = $scope.getCoverageString();

            var packageInfo = ARCHVIEW.getData(ARCHVIEW.describeCoverage.fileFormatURL, coverageString); //$scope.processFormat(["wcs:CoverageDescription"]["wcs:CoverageOffering"]["wcs:supportedFormats"])
            $scope.dlso.fileOutput_options = $scope.processCoverages(packageInfo);
            $scope.dlso.fileOutputChoice = "AS_IS;";
        }

        $scope.getCoverageString = function(){
            var dlsoCount = 0;
            var coverageString = "";
            for(var dlsoIndex = 0; dlsoIndex < $scope.cart.dlsos.length;dlsoIndex++){
                var currDlso = $scope.cart.dlsos[dlsoIndex];
                if(dlsoIndex > 0){
                    coverageString += "&COVERAGE=";
                }
                coverageString += currDlso.uuid;
                dlsoCount++;
            }
            return coverageString;
        }

        $scope.getUUIDString = function(){
            var dlsoCount = 0;
            var coverageString = "";
            for(var dlsoIndex = 0; dlsoIndex < $scope.cart.dlsos.length;dlsoIndex++){
                var currDlso = $scope.cart.dlsos[dlsoIndex];
                if(dlsoIndex > 0){
                    coverageString += ",";
                }
                coverageString += currDlso.uuid;
                dlsoCount++;
            }
            return coverageString;
        }

        $scope.getMARCRecordsObject = function(){
            var marcRecords = {};
            for(var dlsoIndex = 0; dlsoIndex < $scope.cart.dlsos.length;dlsoIndex++){
                var currDLSO = $scope.cart.dlsos[dlsoIndex];
                marcRecords[currDLSO.uuid] = currDLSO.marcXML;
            }
            return marcRecords;
        }

         $scope.exportAlerts = [
          ];


          $scope.closeAlert = function(index) {
            $scope.exportAlerts.splice(index, 1);
          };

        $scope['export'] = function(){
             $scope.exportAlerts = [];
             //http://localhost:8080/aw-server/rest/data/export/uuids
             if(cartService.dlsos.length == 0){
                alert("No Documents to export");
                return;
            }
             if($scope.dlso.exports.exportTag == null || $scope.dlso.exports.folderName == ""){
                $scope.exportAlerts.push({ type: 'danger', msg: 'Folder Name Cannot Be Empty' } );
                return;
             }
             
             var exportData = {};
             
             exportData["export_tag"] = $scope.dlso.exports.exportTag;
             exportData.uuids = cartService.dlsos.map(function(x){ return x.uuid;}).join(",");
             exportData["export_type"] = $scope.dlso.exports.exportType;
             exportData.compress = $scope.dlso.exports.compress == "compressed" ? true : false;
             exportData.enable_file_flags = false;
             exportData.generate_MD5_manifest = false;
             
             $("#cart-modal").block({ message: '<h1><i class="icon-spinner icon-spin icon-2x"></i>Exporting...</h1>'  });
             
             $.ajax({
                    type: "POST",
                    url:  '/api/dlso/?op=export',
                    data : exportData,
                    headers: configService.getXSRFHeader(),
                    success: function (data) {
                        
                        if(data.numSuccessExports == 0 && data.numFailedExports == 0){
                            //Nothing passed or failed ? UUIDs not found
                            $scope.exportAlerts.push({ type: 'danger', msg:   'The Digital Documents passed in were not found in the digital library.' } );
                        } 
                        else if(  data.numFailedExports == 0 ){
                            //Everything Passed
                            alertService.add({
                                msg: data.numSuccessExports + ' document(s) were exported to the <b>'+ $scope.dlso.exports.exportTag + '</b> folder in the designated server-side export folder.',
                                type: 'success'
                            });
                            $scope.cart.removeAllDLSOs();
                            $scope.$close()
                        
                        }
                        else if(  data.numFailedExports > 0 ){
                            // Something failed
                            $scope.exportAlerts.push({ type: 'danger', msg: data.numFailedExports + 'export(s) failed and ' + data.numSuccessExports + ' export(s) Succeeded' } );
                        }
                        $("#cart-modal").unblock();
            
                    },
                    error: function (err) {
                        $("#cart-modal").unblock();
                        $scope.exportAlerts.push({ type: 'danger', msg: 'Export Failed' } );
                    }
            });
        }
        
        $scope.saveAsDownload = function(){        
            if(cartService.dlsos.length == 0){
                alert("No Digital Documents Available");
                return;
            }            
            var schema = "AW"; //$scope.dlso.metadataSchemaChoice;
            var metadata = "XML"; //$scope.dlso.metadataChoice;
            var format = $scope.dlso.fileOutputChoice == "" || $scope.dlso.fileOutputChoice == null ? "AS_IS;" : $scope.dlso.fileOutputChoice;
            var packaged = $scope.dlso.packageType;
            var finalPath = null;
            var marcPoster = null;
            var option = $scope.dlso.downloadType;
            switch(option){
                case "FM":
                    if(packaged == "Zip"){
                        //finalPath = ARCHVIEW.getCoverage.bundleOutputURL.format() +"&format="+ + "&metadataformat="++"&metadataschema="+schema;
                        finalPath = {"REQUEST": "getCoverage",
                                  "SERVICE": "WCS",
                                  "VERSION": "1.0.0",
                                  "CRS": "EPSG:4326",
                                  "COVERAGE": $scope.getUUIDString(),
                                  "BBOX": "0,0,0,0",
                                  "OUTPUTFORMAT": "application/json",
                                  "PACKAGETYPE": "BUNDLE",
                                  "FORMAT": format,
                                  "METADATAFORMAT": metadata,
                                  "METADATASCHEMA": schema
                                };
                    }else{                         
                        finalPath = {"REQUEST": "getCoverage",
                                  "SERVICE": "WCS",
                                  "VERSION": "1.0.0",
                                  "CRS": "EPSG:4326",
                                  "COVERAGE": $scope.getUUIDString(),
                                  "BBOX": "0,0,0,0",
                                  "OUTPUTFORMAT": "application/json",
                                  "FORMAT": "AS_IS",
                                  "PACKAGETYPE": packaged,
                                  "METADATASCHEMA": schema
                                };
                    }
                    marcPoster = true;
                
                break;
                case "F":
                    finalPath = ARCHVIEW.getCoverage.fileOutputURL.format($scope.getCoverageString()) + "&FORMAT="+format;
                break;
            }
            
            var ftp = $scope.ftp;
            if($scope.dlso.deliveryOption == "email"){
                if($scope.dlso.email)
                    finalPath +="&emailNotification=" + $scope.dlso.email;
                else{
                    alert("Email address required");
                    return;
                }
            }
            if($scope.dlso.deliveryOption == "ftp"){
                if(ftp.username && ftp.password && ftp.server && ftp.directory){
                    finalPath +="&receiverProfile=" + encodeURIComponent("ftp://" +ftp.username + ":" + ftp.password + "@" + ftp.server + "/" + ftp.directory);    
                    finalPath +="&compressFTP=" + ftp.compressed;
                }else{
                    alert("All FTP Fields are required");
                    return;
                }
            } 

            if(!marcPoster && $scope.dlso.deliveryOption != "ftp" && $scope.dlso.deliveryOption != "email"){
                var win=window.open(finalPath , '_blank');
                win.focus();
            }else if(!marcPoster){
                $.ajax({
                    type: "GET",
                    url: finalPath,
                    cache: false,
                    contentType: "application/json",
                    headers: configService.getXSRFHeader(),
                    success: function (data) {
                        if($scope.dlso.deliveryOption == "email")
                            alert("In progress, an email will be sent when completed.")
                        else
                            alert("In progress, provided FTP will be used to upload when completed.")
                    },
                    error: function () {
                        alert("Delivery process not completed, please contact you System Administrator if the problem persists.")
                    }
                });
            }
            else{
                finalPath.marcRecords = $scope.getMARCRecordsObject();
                $.ajax({
                    type: "POST",
                    url: "/api/wcs",
                    data: finalPath,
                    cache: false,
                    contentType: "application/x-www-form-urlencoded",
                    headers: configService.getXSRFHeader(),
                    success: function (data) {
                        if($scope.dlso.deliveryOption == "email")
                            alert("In progress, an email will be sent when completed.")
                        else if($scope.dlso.deliveryOption == "ftp")
                            alert("In progress, provided FTP will be used to upload when completed.")
                        else{
                            var win=window.open( "/api/dlso/"+data["wcs:Acknowledgement"].RequestId+"/order?as_attachment=false", '_blank');
                            win.focus();
                        }
                    },
                    error: function () {
                        alert("Delivery process not completed, please contact you System Administrator if the problem persists.")
                    }
                });
            }
        };
        
        $scope.processCoverages = function(coverage){        
            var allCoverages = coverage["wcs:CoverageDescription"]["wcs:CoverageOffering"];
            var isArray = Array.isArray(allCoverages);
            if(!isArray)
                allCoverages = [allCoverages];
            var key = 0;
            var allFormats = {};
            for(var covIndex = 0; covIndex < allCoverages.length;covIndex++){
                var cov = allCoverages[covIndex];
                var formatHash = $scope.processFormat(cov["wcs:supportedFormats"]);
                for(key = 0; key < formatHash.length;key++){
                    allFormats[key] = allFormats[key] == null ? 1 : allFormats[key] + 1;
                }
            }
            var coverages = [];
            for(key = 0; key < allFormats.length;key++){
                if(allFormats[key] == allCoverages.length)
                    coverages.push(key);
            }
        
            return coverages;
        
        }
        
        $scope.processFormat = function (format) {            
            var hash = {};
            if(format == null)
                return {};
            if (format.nativeFormat == format["wcs:formats"] || typeof format["wcs:formats"] === 'string' || format["wcs:formats"]  == "NONE"){
                hash[format.nativeFormat] = format.nativeFormat;
                return hash;
                }
            hash[format.nativeFormat] = format.nativeFormat;

            for(var formIndex = 0; formIndex < format["wcs:formats"].length;formIndex++)
                hash[format["wcs:formats"][formIndex]] = format["wcs:formats"][formIndex];
            return hash;
        }
        $scope.processDLSOExportOptions = function(){
            $scope.dlso = {};
            $scope.dlso.downloadType = "E";
            $scope.dlso.exports = {};
            $scope.dlso.exports.compress = "compressed";
            $scope.dlso.exports.exportType = "ALL";
        }
        $scope.exportFlag = cartService.exportFlag;

        if(!cartService.exportFlag){
            $scope.processDLSOCartOptions();
        }else{
            $scope.processDLSOExportOptions();
        }

        $scope.deleteBibs = function () {
            var bibs = Object.keys($scope.cart.selected).filter(function (bibid) {
                return $scope.cart.selected[bibid];
            });
            if (bibs.length) {
                $scope.cart.removeSelected();
                kohaDlg.delBibs( bibs );
            }
        };

        $scope.viewCitationsFromCart = function () {
            var bibs = Object.keys($scope.cart.selected).filter(function (bibid) {
                return $scope.cart.selected[bibid];
            });
            if (bibs.length) {
                $rootScope.$broadcast('openViewCitationsDlg', bibs);
            }
        };
    }])


    .controller('AddToListDlgCtrl', ["$scope", "userService", "kohaListsSvc", "$uibModalInstance", "bibids", "bibService", "configService", "alertService", function ($scope, userService, kohaListsSvc, $uibModalInstance, bibids, bibService, configService, alertService) {
        $scope.lists = kohaListsSvc;
        // assume we always have the bib in bibcache.
        $scope.bibsToAdd = [];
        if (bibids) {
            bibids.forEach(function (bibid) {
                bibService.get(bibid).then(function (bib) {
                    $scope.bibsToAdd.push(bib);
                });
            });
        }
        $scope.order = {
            field: 'title_ext',
            reverse: false,
            map: {
                title_ext: function(input) {
                    if (typeof(input) !== 'string') return input;
                    return input.toLowerCase().replace(/^(a|an|the) /i,'');
                },
                "marc.subfield('260c')": function(input) {
                    if (typeof(input) !== 'string') return input;
                    return input.replace(/^c/i,'');
                },
            },
        };
        $scope.targetList = {
            id: ''
        };
        $scope.newlist = {
            sortfield: 'title'
        };
        $scope.acqLists = configService.AcqLists;
        $scope.user = userService;

        $scope.dlgSubmit = function () {
            if ($scope.targetList.id) {
                $scope.addToList();
            } else {
                $scope.createList();
            }
        };

        $scope.addToList = function () {

            var promise = $scope.lists.addToList($scope.targetList.id, $scope.bibsToAdd);
            if (promise) {
                promise.then(function () {
                    alertService.add({
                        type: 'success',
                        msg: 'Your ' + configService.wording.list + ' has been updated!'
                    });
                    $uibModalInstance.close();
                }, function (data) {
                    $scope.targetList.error = data;
                    //TODO: remove the bibs now since we're out of sync.  or maybe just resync all.
                });
            } else {
                $scope.targetList.error = "Title(s) already exist in " + configService.wording.list;
            }
        };

        $scope.createList = function () {
            var promise = $scope.lists.createList($scope.newlist, $scope.bibsToAdd);
            promise.then(function () {
                alertService.add({
                    type: 'success',
                    msg: 'Your ' + configService.wording.list + ' was created!'
                });
                $uibModalInstance.close();
            }, function () {
                $uibModalInstance.close();
            });
        };

        $scope.close = function () {
            $scope.targetList.error = '';
            $uibModalInstance.close();
        };

    }])

    .controller('ListDlgCtrl', ["$scope", "userService", "configService", "kohaListsSvc", "$uibModalInstance", "shelfnumber", "kohaDlg", "alertService", "BibBatchService", function ($scope, userService, configService, kohaListsSvc, $uibModalInstance, shelfnumber, kohaDlg, alertService, BibBatchService) {

        // Display a list of Public Lists if no list shelfnumber (list id) is given.
        // else display the titles in the list.

        $scope.showingMine = userService.loggedin ? true : false;
        $scope.order = {
            field: 'shelfname',
            reverse: false,
            map: {
                shelfname: function(input) {
                    if (typeof(input) !== 'string') return input;
                    return input.replace(/^(a|an|the) /i,'');
                },
            },
        };
        $scope.order2 = {
            field: 'bib.title_ext',
            reverse: false,
            map: {
                "bib.title_ext": function(input) {
                    if (typeof(input) !== 'string') return input;
                    return input.toLowerCase().replace(/^(a|an|the) /i,'');
                },
                "bib.marc.subfield('260c')": function(input) {
                    if (typeof(input) !== 'string') return input;
                    return input.replace(/^c/i,'');
                },
            },
        };
        $scope.lists = {
            public: [],
            mine: [],
            shown: null
        };
        kohaListsSvc.sync().then(function(){
            $scope.lists.public = kohaListsSvc.get_public();
            $scope.lists.mine = kohaListsSvc.get_mine();
            $scope.lists.shown = userService.loggedin ? $scope.lists.mine : $scope.lists.public;
        });
        $scope.listLists = function(viewname){
            $scope.list = null;
            if(viewname != 'mine') viewname = "public";
            $scope.lists.shown = $scope.lists[viewname];
            $scope.showingMine = viewname == 'mine';
        }
        $scope.canViewMine = function(){
            if (viewingOwnList || ! $scope.lists.mine.length ) return;
            if ($scope.list){
                console.log($scope.lists);
                return $scope.lists.mine == $scope.lists.shown;
            }
            else
                return $scope.lists.public == $scope.lists.shown;
        }
        $scope.canViewPublic = function(){
            if (viewingOwnList || !$scope.lists.public.length) return false;
            if ($scope.list)
                return $scope.lists.public == $scope.lists.shown;
            else
                return $scope.lists.mine == $scope.lists.shown;
        }
        $scope.closeList = function() {
            $scope.list = null;
        };

        $scope.commentCreate = function(c) {
            $scope.list._commentsLoading = true;
            kohaListsSvc.commentCreate($scope.list, c).then(function(comments) {
                console.dir(comments);
                $scope.list.newComment = "";
                $scope.list._commentsLoading = false;
            }, function(resp) {
                $scope.list._newComment = "";
                $scope.list._commentsLoading = false;
                alertService.add({
                    type: 'error',
                    msg: 'Could not create new comment: ' + resp,
                });
            });
        };

        $scope.commentEdit = function(c) {
            $scope.list._editingAny = true;
            angular.forEach($scope.list.comments, function(cc) {
                cc._editing = false;
            });
            c._editing = true;
            c.original = c.comment;
        };

        $scope.commentEditCancel = function(c) {
            $scope.list._editingAny = false;
            c._editing = false;
            c.comment = c.original;
        };

        $scope.commentUpdate = function(c) {
            $scope.list._commentsLoading = true;
            kohaListsSvc.commentUpdate($scope.list, c).then(function(comments) {
                $scope.list.comments = comments;
                $scope.list._commentsLoading = false;
                c._editing = false;
            }, function(resp) {
                c.comment = c.original;
                c._editing = false;
                $scope.list._commentsLoading = false;
                alertService.add({
                    type: 'error',
                    msg: 'Could not update comment: ' + resp,
                });
            });
        };

        $scope.commentDelete = function(c) {
            kohaDlg.dialog({
                heading: 'Delete comment?',
                message: 'Are you sure you wish to delete your comment?  This action cannot be undone.',
                buttons: [{val: false, label: 'Cancel'}, {val: true, label: 'OK', cssClass: 'btn-primary'}]
            }).result.then(function(rv) {
                if (rv) {
                    $scope.list._commentsLoading = true;
                    kohaListsSvc.commentDelete($scope.list, c).then(function(comments) {
                        $scope.list.comments = comments;
                        $scope.list._commentsLoading = false;
                    }, function(resp) {
                        $scope.list._commentsLoading = false;
                        alertService.add({
                            type: 'error',
                            msg: 'Could not delete comment: ' + resp,
                        });
                    });
                }
            });
        };


        $scope.openList = function (id) {
            $scope.list = kohaListsSvc.get(id);

            var resetValues = {
                shelfname: $scope.list.shelfname,
                sortfield: $scope.list.sortfield,
                is_public: $scope.list.is_public
            };
            $scope.resetForm = function () {
                jQuery.each(resetValues, function (key, val) {
                    $scope.list[key] = val;
                });
            };
        };
        var viewingOwnList;
        if (shelfnumber) {
            $scope.openList(shelfnumber);
            viewingOwnList = true; // i.e. state is 'me.lists'
        }

        $scope.user = userService;
        $scope.config = configService;
        $scope.mailto = {
            email: null,
            showEmail: false
        };

        $scope.placeHoldsFromList = function () {
            if(!userService.can_place_holds) return;
            var bibids = [];
            $.each($scope.list.selectedbibs, function (bibid, bool) {
                if (bool) bibids.push(bibid);
            });
            if(!bibids.length) return;
            $uibModalInstance.close();
            kohaDlg.placeHold(bibids);
        };

        $scope.rmTitles = function () {
            var bibsToRemove = Object.keys($scope.list.selectedbibs).filter(function (id) {
                return $scope.list.selectedbibs[id];
            });
            if(!bibsToRemove.length) return;
            var promise = kohaListsSvc.rmFromList($scope.list.shelfnumber, bibsToRemove);
            promise.then(function () {
                $scope.openList($scope.list.shelfnumber);
            });
        };

        $scope.submitListChanges =  function(id){
            kohaListsSvc.updateList(id).then(function(){
                kohaListsSvc.sync().then(function(){$scope.openList(id)});
            }, function(){
                kohaDlg.dialog({
                    type: "notify",
                    alertClass: "warning",
                    heading: "Failed to submit changes.",
                    message: "Your current changes could not be saved."
                });
            })
        }

        $scope.deleteList = function (id) {
            var title = 'Deleting ' + configService.wording.list;
            var msg = 'This action cannot be undone.  Are you sure you want to continue?';
            var btns = [{
                val: false,
                label: 'Cancel'
            }, {
                val: true,
                label: 'OK',
                cssClass: 'btn-primary'
            }];

            kohaDlg.dialog({
                heading: title,
                message: msg,
                buttons: btns
            }).
            result.
            then(function (result) {
                if (result) {
                    var promise = kohaListsSvc.deleteList(id);
                    $uibModalInstance.close();
                    promise.catch(function () {
                        alertService.add({
                            type: 'error',
                            msg: 'Could not delete ' + configService.wording.list
                        });
                    });
                }
            });
        };

        $scope.sendList = function (shelfnumber) {
            var list = (shelfnumber) ? kohaListsSvc.lists[shelfnumber] : $scope.list;
            if (!list) return;

            var bibs = list.works.map(function (l) {
                return l.id;
            });
            var userdata = {};
            userService.whenAnyUserDetails().then(function(details){
                userdata = details;
                if(userdata.email){
                    kohaDlg.dialog({
                            heading: "Email " + configService.wording.list,
                            message: "Please enter the recipient email address.",
                            inputs: [{
                                    name: 'recipient',
                                    label: 'Recipient email',
                                    val: userdata.email,
                                    type: 'email'
                                },{
                                    name: 'subject',
                                    label: 'Subject',
                                    val: configService.wording.list + ": " + list.shelfname + " [from " + configService.pageTitle + "]"
                                }],
                            buttons: [{
                                val: true,
                                label: 'Send',
                                btnClass: "btn-primary"
                            }, {
                                val: false,
                                label: 'Cancel'
                            }]
                        }).result.then(function (modalresult) {
                            if (modalresult) {
                                var options = {
                                    op: 'email',
                                    recipient: modalresult.recipient,
                                    subject: modalresult.subject
                                };
                                BibBatchService.submit(bibs, options);
                            }
                        });
                } else {
                    kohaDlg.dialog({
                        type: "notify",
                        alertClass: "warning",
                        heading: "No email address on file",
                        message: "You must have an email address on file with the library to send email."
                    });
                }
            });
        };
        $scope.renderList = function(dload){
            if (!$scope.list) return;
            var bibs = $scope.list.works.map(function (l) {
                return l.id;
            });
            var options = {
                op: (dload) ? 'download' : 'print',
                subject: configService.wording.list + ": " + $scope.list.shelfname + " [from " + configService.pageTitle + "]"
            };
            BibBatchService.submit(bibs, options);
        };

    }])

    .controller('PlaceHoldsDlgCtrl', ["$scope", "$q", "$uibModalInstance", "kwApi", "userService", "configService", "bibService", "alertService", "bibids", "patronid", function ($scope, $q, $uibModalInstance, kwApi, userService, configService,
                    bibService, alertService, bibids, patronid) {

        // Note we already have resolved userdetails.
        // If patronid isn't passed, then the user is placing the hold for itself.

        $scope.canOverridePolicy = userService.can({circulate:'OR_hold_policy_block'});
        $scope.canHoldCrossGroup = userService.can({circulate:'OR_item_not_in_branch_group'});
        var waitOnData = $q.defer();
        $scope.branches = configService.listCodes('branch').map(function(c){
                                return {code: c, name: configService.display(c,'branch')};
                        });
        if(patronid){  // patronid indicates staff placing hold for patron.

            var details = kwApi.Patron.get( { id: patronid }, {} );
            var holds = kwApi.Hold.getForPatron( { id: patronid }, {});
            $q.all([details.$promise, holds.$promise]).then(function(results){
                $scope.patron = {
                    id: patronid,
                    details: results[0],
                    holds: results[1]
                };
                waitOnData.resolve();

            });

        } else {
            // placing hold for self.
            var myHoldsByBib = {}; // note we only track one per bib.
            // todo: store this in userService, make cacheable.
            kwApi.Hold.getForPatron( { id: userService.id }, {}, function(patronholds){
                // TODO: test against item-level holds.
                // system currently allows a bib-level hold when an item-level already exists.
                // should alert user that they already have item-level hold.
                patronholds.forEach(function(hold){
                    myHoldsByBib[hold.biblionumber] = hold;
                });
                waitOnData.resolve();
            });
        }

        $scope.pending = {};

        $scope.sharedData = {
            branch: userService.details_data.branchcode,
            start: new Date()
        };
        $scope.itemOrder = {
            field: '[homebranch,itemcallnumber]',
            reverse: false
        };

        var maxItemsToCheck = 9;

        waitOnData.promise.then(function(){
            // we now know our target patron.

            if(patronid) $scope.sharedData.branch = $scope.patron.details.branchcode;
            angular.forEach($scope.pending, function(holdData, bibid){
                testPlace(bibid);  // test title-level hold.
            });

            $scope.$watch('sharedData.branch', function(nv,ov){
                if(nv === ov) return;
                angular.forEach($scope.pending, function(holdData, bibid){
                    // here we only test title-level, even though the user may have selected item-level.
                    testPlace(bibid);
                });
            });
        });

        $scope.numTitles = bibids.length;

        bibids.forEach(function(bibid){
            var holdData = $scope.pending[bibid] = {
                status: undefined,     // pending, submitted, blocked, failed, warn, success
                                        // status of pending indicates has been tested, no blocks.
                item_level: '',
                items: {
                    failed: {}, // failures with error msg, by itemid.
                    test_ok: {},
                    holds: {},
                    tested: false
                },
                hold: null, // successfully placed holds, as $resource objects.
                msg: null,
                // bib: holdings:  added later (holdings only if not too many)
            };
            bibService.get(bibid).then(function(bib){
                holdData.bib = bib;
                if(bib.opac_hold_policy=='item') holdData.item_level = "1";
                if(bibids.length == 1) $scope.singleTitle = bib.title;
            });
            $scope.$watch(function (){ return $scope.pending[bibid].item_level; },
                function(nv,ov){ // testPlace items only for sane number of them.
                    bibService.holdings(bibid).then(function(holdings){
                        holdData.holdings = holdings;
                        // console.log(holdings);
                        if(nv && !holdData.items.tested && holdData.bib.summary.item_count <= maxItemsToCheck){
                            holdData.items.tested = true;
                            angular.forEach(holdings.item, function(itemid, item){
                                // console.log(item);
                                testPlace(bibid, itemid);
                            });
                        }

                    });
                });
        });


        $scope.config = configService;
        $scope.canPickDate = (userService.can({reserveforothers: '*'})) ?
                !!configService.AllowHoldDateInFuture : !!configService.OPACAllowHoldDateInFuture;

        $scope.selectItem = function (item, items, opt) {
            items.itemSelected = true;
            if (!opt) opt = {};
            placeHold(item.biblionumber, item, opt).then( function(){
                items.itemSelected = false;
            }, function(){
                items.itemSelected = false;
            });
        };
        $scope.overrideSelectItem = function (item, items) {
            var opt = { override: true };
            $scope.selectItem(item, items, opt);
        };

        var anyItemHeld = function (bibid) {
            // this calls it held while waiting for response...
            return  !! Object.keys($scope.pending[bibid].items.holds).length ;
        };
        $scope.canSelectItem = function(item){
            if($scope.pending[item.biblionumber].items.failed[item.id]  ||
                $scope.pending[item.biblionumber].items.holds[item.id]) return false;
            // we just assume all items are same itemtype.
            if(anyItemHeld(item.biblionumber))
                return truthy((configService.itemtypes[item.itemtype]||{}).manyholdsperbib);
            return true;
        };
        function truthy (val){
            return !!val && val != "0" && val != "false";
        }
        $scope.itemLevelOk = function(hold){
            if( !hold.bib || hold.bib.opac_hold_policy == 'title' || hold.bib.opac_hold_policy == 'none' ) return false;
            return true;
        };
        $scope.titleLevelOk = function(hold){
            if( !hold.bib || hold.bib.opac_hold_policy == 'item' || hold.bib.opac_hold_policy == 'none' ) return false;
            return hold.status=='pending' || (hold.status=='warning' && userService.is_staff);
        };
        $scope.canOverrideTitleBlock = function(hold){
            return hold.status!='success' && hold.status != 'submitted' &&
                    !$scope.titleLevelOk(hold) && $scope.canOverridePolicy && ($scope.canHoldCrossGroup || !$scope.crossGroupHold);
        };
        $scope.extantHold = function(bibid){
            if(patronid){
                return $scope.patron.holds.find(function(hold){ return hold.biblionumber==bibid; });
            } else {
                return myHoldsByBib[bibid];
            }
        };

/// manyholdsperbib applies only to item holds, not title holds.

        $scope.canRecallItem = {};

        function testPlace (bibid, item){

            var params = {
                item: (item) ? item.id : undefined,
                pickup_branch: $scope.sharedData.branch,
                for_patron: patronid // null if non-staff.
            };

            $scope.crossGroupHold = false;
            return kwApi.Hold.testPlace( { bibid: bibid }, params,
                function(ok){
                    if(item){
                        $scope.pending[bibid].available_item_level = true;
                    } else {
                        $scope.pending[bibid].status = 'pending';
                        if(patronid){
                            // Test for multiple title-level holds if staff-placed.
                            //(will fail on backend if non-staff).
                            if($scope.patron.holds.some(function(hold){
                            return hold.biblionumber == bibid; })){
                                    $scope.pending[bibid].status = 'warning';
                                    $scope.pending[bibid].msg = "Patron already has title-level hold.";
                            }
                        }
                    }
                }, function(fail){
                    var errCode = errorCode(fail);
                    if (errCode == 'hold.none_in_group') {
                        $scope.crossGroupHold = true;
                    }

                    if(item){
                        $scope.pending[bibid].items.failed[item.id] = errorStr(fail);
                    } else {
                        $scope.pending[bibid].status = 'blocked';
                        $scope.pending[bibid].msg = errorStr(fail);
                        $scope.pending[bibid].code = errorCode(fail);
                    }
                });
        }

        function placeHold (bibid, item, opt){
            var options = angular.extend({ syncUser: true, override: false }, opt);
            if(!item) $scope.pending[bibid].status = 'submitted';
            if(options.override && item)
                delete $scope.pending[bibid].items.failed[item.id];
            var params = {
                override: (options.override) ? true : false,
                item: (item) ? item.id : undefined,
                pickup_branch: $scope.sharedData.branch,
                notes: $scope.pending[bibid].notes || undefined,
                for_patron: patronid // null if non-staff.
            };
            if($scope.sharedData.start && $scope.sharedData.start > new Date())
                    params.date = $scope.sharedData.start.toISOString();

            var hold = kwApi.Hold.create( { bibid: bibid }, params );
            hold.$promise.then(function(ok){
                if(item){
                    $scope.pending[bibid].items.holds[item.id] = hold;
                    if(item.onloan){
                        kwApi.Item.testRecall( { id: item.id, patron_id: patronid }, {}, function(ok){
                            $scope.canRecallItem[item.id] = true;
                        }, function(failed){

                        } );
                    }
                } else {
                    $scope.pending[bibid].status = 'success'; // unnecessary.
                    $scope.pending[bibid].hold = hold;
                }
                if(options.syncUser) userService.updateCircData();
            }, function(fail){
                if(item){
                    $scope.pending[bibid].items.failed[item.id] = errorStr(fail);

                } else {
                        $scope.pending[bibid].status = 'failed'; // just blocked maybe?
                        $scope.pending[bibid].msg = errorStr(fail);
                        $scope.pending[bibid].code = errorCode(fail);
                }
            });
            return hold.$promise;
        }

        function errorStr (rsp){
            // extract error from response.
            if(angular.isObject(rsp) && rsp.data){
                try {
                    var errjson = rsp.data.replace(/\d\d\d\s*/,'');
                    var err = JSON.parse(errjson);
                    return userService.is_staff ? err.staff : err.public;
                } catch (e) {
                    return 'Error placing hold.';
                }
            } else {
                return 'Error placing hold.';
            }
        }

        function errorCode (rsp){
            if(angular.isObject(rsp) && rsp.data){
                try {
                    var errjson = rsp.data.replace(/\d\d\d\s*/,'');
                    var err = JSON.parse(errjson);
                    return err.code;
                } catch (e) {
                    return 'hold.unknown';
                }
            } else {
                return 'hold.unknown';
            }
        }

        $scope.placeTitleHold = function (bibid) {
            placeHold(bibid);
        };
        $scope.overridePlaceTitleHold = function (bibid) {
            placeHold(bibid, undefined, { override: true });
        };

        $scope.rmTitle = function (pendingHold) {
            pendingHold.hidden=true;
            var numShown = Object.keys($scope.pending).reduce(
                    function(acc,curr){ return acc + (($scope.pending[curr].hidden)?0:1); }, 0);

            if(numShown) $scope.numTitles = numShown;
                else $uibModalInstance.close();
        };
        $scope.canSubmitAll = function(){
            if($scope.numTitles < 2 ) return false;
            if(bibids.some(function(bibid){ return $scope.pending[bibid].item_level; })) return false;
            return true;
        };
        $scope.submitAll = function () {  // title-level submit-all.
            var done = [];
            $.each($scope.pending, function(bibid, holdData){
                if(holdData.status == 'pending' && !holdData.item_level && !holdData.hidden){
                    done.push( placeHold(bibid, null, { syncUser: false }) );
                }
            });
            $q.all(done).then(function(){
                userService.updateCircData();
            })
        };

        $scope.recallItem = function(item){
            kwApi.Item.recall( { id: item.id, patron_id: patronid }, {}, function(ok){
                alertService.add({msg: 'Item successfully recalled.', type: 'success'});
            }, function(failed){
                alertService.add({ msg: 'Item recall failed.  Please try again later or contact your library.',
                    type: 'error'});
                delete $scope.canRecallItem[item.id];
            } );
        };

    }])

    .controller('FacetDlgCtrl', ["$scope", "kohaSearchSvc", "facetfield", "kwLuceneParser", "userService", function ($scope, kohaSearchSvc, facetfield, kwLuceneParser, userService) {

        $scope.facetField = facetfield;
        $scope.search = kohaSearchSvc.currentSearch();

        $scope.advancedFacets = false;
        userService.whenAnyUserDetails().then(function() {
            $scope.advancedFacets = userService.merged_prefs.advanced_facets;
        });

        $scope.toggleFacetOperator = function(f) {
            f.operator = (f.operator == 'OR') ? 'AND' : 'OR';
        };

        $scope.applySearch = function(f) {
            f.applySearch();
            $scope.$close();
        };

        $scope.subqueries = kwLuceneParser.extractSubqueries($scope.search.q).subqueries;

        var isortfields = { pubyear: true, 'on-shelf-at': true };
        var maxFacets = 200;
        $scope.state = {
            sort: (isortfields[$scope.facetField.field]) ? 'index' : 'count',
        };
        var originalSort = { sort: $scope.state.sort };
        $scope.search.moreFacets(facetfield, 0, maxFacets, $scope.state.sort).then(function(facetData) {
            $scope.morefacets = facetData;
            $scope.morefacets.operator = 'OR';
            originalSort.values = angular.copy(facetData.values);
        });

        $scope.reSort = function(v) {

            if(originalSort.sort == $scope.state.sort){
                $scope.morefacets.values = angular.copy(originalSort.values);
            } else {
                if($scope.state.sort=='count'){
                    $scope.morefacets.sort(function(a,b){  return a.count - b.count; });
                } else {
                    $scope.morefacets.values = $scope.morefacets.values.map(
                        function(el,i){ return { index: i, val: el.display_value.toLowerCase()};}).sort(
                            function(a,b){ return (a.val<b.val ? -1 : a.val>b.val ? 1 : 0); }).map(
                                function(el,i){ return $scope.morefacets.values[el.index]});
                }
            }

        };

    }])

    .controller('LostPassDlgCtrl', ["$scope", "kwApi", "alertService", function($scope, kwApi, alertService) {
        $scope.submit = function() {
            kwApi.Login.lostPass({},{username: $scope.username}).$promise.then(function(rv) {
                alertService.add({
                    type: "success",
                    msg: "Reset email sent"
                });
                $scope.$close();
            }, function(err) {
                alertService.addApiError(err,'Unable to send reset link');
            });
        };
    }])

    .controller('LostPassCtrl', ["$scope", "$state", "$stateParams", "kwApi", "alertService", function($scope, $state, $stateParams, kwApi, alertService) {
        $scope.pw = {};
        $scope.submit = function() {
            kwApi.Login.lostPass({},{
                username: $scope.pw.username,
                token: $stateParams.token,
                password: $scope.pw.newpass1,
            }).$promise.then(function(rv) {
                alertService.add({
                    type: "success",
                    msg: "Password reset"
                });
                $state.go('home');
            }, function(err) {
                alertService.addApiError(err,'Unable to reset password');
            });
        };
    }])

    .controller('SelfRegisterCtrl', ["$scope", "kwApi", "alertService", "$state", function($scope, kwApi, alertService, $state) {
        $scope.submit = function() {
            kwApi.PatronRegistration.submitApp({patronData: $scope.patronData}).$promise.then( function() {
                alertService.add({ type: "success", msg: "Account registered, further instructions will be sent by email." });
                $state.go('home');
            }, function(e) {
                alertService.addApiError(e, "Unable to register account, please see a librarian.");
                $state.go('home');
            });
        };
    }])

    .controller('SavedSearchCtrl', ["$scope", "userService", "$http", "alertService", function ($scope, userService, $http, alertService) {
        $scope.searches = userService.savedSearches;

        $scope.updateFollow = function (srch, index) {
            console.log("updateFollow");
            if (srch.follow == 1) {
                srch.follow = 0;
                $scope.searches.all[index].follow = srch.follow;
            }
            else {
                srch.follow = 1;
                $scope.searches.all[index].follow = srch.follow;
            }
            $http.put('/api/saved-search/' + srch.id, JSON.stringify(srch), {authRequired: true})
                .then( function () {
                    alertService.add({
                        type: "success",
                        msg: "Changes saved"
                    });
                }, function () {
                    alertService.add({
                        type: 'error',
                        msg: "Error: Failed to save, please try again."
                    });
                });

        }
    }])

    .controller('AlertCtrl', ["$scope", "alertService", function ($scope, alertService) {
        $scope.manageCallBack = function(callback){
            callback()
        }
        $scope.alertSvc = alertService;
        $scope.alerts = $scope.alertSvc.get();

    }])

    .controller('CourseReservesCtrl', ["$scope", "$uibModal", "kohaCourseSvc", "configService", "$state", "$timeout", function ($scope, $uibModal, kohaCourseSvc, configService, $state, $timeout) {

        kohaCourseSvc.sync();

        $scope.courseSvc = kohaCourseSvc;

        $scope.order = {
            field: '[department,course_number,term,section]',
            reverse: false,
            map: {
                department: function(input) { return configService.display(input,'department') },
                term: function(input) { return configService.display(input,'term') },
                course_name: function(input) {
                    if (typeof(input) !== 'string') return input;
                    return input.replace(/^(a|an|the) /i,'');
                },
            },
        };

        $scope.courseDlgOpen = function (id) {
            var modalInstance = $uibModal.open({
                backdrop: false,
                templateUrl: '/app/static/partials/course-modal.html',
                controller: 'CourseDlgCtrl',
                size: "lg",
                windowClass: "modal course",
                resolve: {
                    course: function () {
                        return $scope.courseSvc.getCourse(id);
                    }
                }
            });
            modalInstance.result.finally(function(){
                  $scope.courseSvc.sync();
            });
            return false;
        };

        $scope.courseFilter = function (course) {
            if (!$scope.filterquery) { return true; }
            var re = new RegExp($scope.filterquery, 'i');
            var instructorTest = false;
            for(var i = 0; i < course.instructors.length; i++){
               var instructor = course.instructors[i];
               instructorTest = re.test(instructor.firstname + " " + instructor.surname);
               if (instructorTest) { break; }
            }
            return instructorTest ||
                re.test(course.department) ||
                re.test(course.section.toString()) ||
                re.test(course.term) ||
                re.test(course.course_number.toString()) ||
                re.test(course.course_name);
        };

        if($state.params.course_id){
            $timeout(function () {
                $scope.courseDlgOpen(parseInt($state.params.course_id, 10));
            }, 1000);
        }
    }])

    .controller('CourseDlgCtrl', ["$scope", "course", function ($scope, course) {

        $scope.KOHA = KOHA;
        $scope.course = course;
        $scope.order = {
            field: 'title',
            reverse: false
        };

    }])

    .controller('ViewCitationsDlgCtrl', ["$scope", "$http", "$uibModalInstance", "bibids", function ($scope, $http, $uibModalInstance, bibids) {
        $scope.citations = '';
        if (bibids) {
            bibids.forEach(function (bibid) {
                $http.get('/api/work/' + bibid + '/cite?style=APA&format=html').
                then(function (response) {
                    if (response.data.cite === null) {
                        $scope.citations += '<em>Citation unavailable (' + bibid + ')</em>';
                        $scope.citations += '<br/><br/>';
                    } else {
                        $scope.citations += response.data.cite;
                        $scope.citations += '<br/><br/>';
                    }
                });
            });
        }
        $scope.close = function () {
            $uibModalInstance.close();
        };
    }])

    .controller('DelBibsDlgCtrl', ["$scope", "$http", "$q", "$uibModalInstance", "bibids", "bibService", "alertService", "bvDownloadSvc", function ($scope, $http, $q, $uibModalInstance, bibids, bibService, alertService, bvDownloadSvc) {
        $scope.limits = {
            items_attached:    true,
            bib_is_on_hold:    true,
            aqorders_link:     true,
            suggestions_link:  true,
            subscription_link: true,
            patron_tags:       true,
            patron_reviews:    true,
        };
        $scope.bibsToDel = [];
        $scope.bibsProcessed = [];
        $scope.processing = 0;

        if ( angular.isArray(bibids) ) {
            bibids.forEach(function (bibid) {
                bibService.get(bibid).then(function (bib) {
                    $scope.bibsToDel.push(bib);
                });
            });
        } else {
            // single bib delete, no options, but still allow cancel.
            $scope.limits = {};
            $scope.noOptions = true;
            bibService.get(bibids).then(function(bib){
                $scope.bibsToDel.push(bib);
            })
        }

        var getTheData = function () {
            var output = '';
            for (var i = 0; i < $scope.bibsProcessed.length; i++) {
                output += $scope.bibsProcessed[i]['title'];
                output += '\t';
                output += $scope.bibsProcessed[i]['author']();
                output += '\t';
                output += $scope.bibsProcessed[i]['delStatus'];
                output += '\n';
            }
            return output;
        };

        $scope.getTheFile = function () {
            bvDownloadSvc.fetch({fetchData: getTheData, fileName: 'download_results.csv'});
        };

        $scope.deleteBibs = function () {
            var limits = angular.copy($scope.limits);

            //$scope.bibsProcessed = jQuery.extend(true, $scope.bibsProcessed, $scope.bibsToDel);
            angular.extend($scope.bibsProcessed, $scope.bibsToDel);
            $scope.bibsToDel = []; // Hide the list div.
            $scope.processing = 1; // Show the spinner.

            var getResult = function(idx) {
                return $http({method: 'DELETE', url: '/api/work/' + $scope.bibsProcessed[idx]['id'], params: limits, authRequired: true}).
                then(function () {
                    $scope.bibsProcessed[idx]['delStatus'] = 'Deleted';
                }, function (response) {
                    if ( angular.isObject(response) ) {
                        $scope.bibsProcessed[idx]['delStatus'] = 'FAIL: ' + Object.keys(response);
                    }
                    else {
                        $scope.bibsProcessed[idx]['delStatus'] = 'FAIL: ' + (response || 'system error') ;
                    }
                });
            };

            var promises = [];
            for (var i = 0; i < $scope.bibsProcessed.length; i++) {
                var request = getResult(i);
                promises.push(request);
            }

            $q.all(promises)['finally'](function () { // See https://docs.angularjs.org/api/ng/service/$q for strange syntax.
                $scope.processing = 0;
                if($scope.noOptions){
                    // single title delete.
                    if($scope.bibsProcessed[0].delStatus != 'Deleted')
                        alertService.add({
                            msg: "Could not delete title.  " + $scope.bibsProcessed[0].delStatus,
                            type: 'error'
                        });
                    $uibModalInstance.close();
                }
            });
            return $q.all(promises);
        };

        $scope.close = function () {
            $uibModalInstance.close();
            $("#resident-search input:visible").first().focus();
        };

    }])

    .controller('UserBaseCtrl', ["$scope", "$uibModal", "$state", "configService", "kwApi", "userService", function ($scope, $uibModal, $state, configService, kwApi, userService) {

        // controllerAs 'dashboard'.

        var self = this;
        this.$onInit = function(){
            self.state = $state.current.name.replace('me.','');
            updateCounts();
        }

        $scope.$on('$stateChangeSuccess', function(e, toState, toParams, fromState) {
            self.state = toState.name.replace('me.','');
            updateCounts();
        });

        this.user = userService;

        this.passwdDlgOpen = function () {
            $uibModal.open({
                backdrop: true,
                templateUrl: '/app/static/partials/passwd-modal.html',
                controller: 'PasswdDlgCtrl'
            });
            return false;
        };

        $scope.config = configService;
        $scope.canChangePassword = ($scope.config.OpacPasswordChange && $scope.config.OpacPasswordChange !== null);

        var holdHistoryLimit = (configService.ShowHoldsRange||'').trim().split(/\s*,\s*/)[1];
        $scope.showHoldHistory = holdHistoryLimit!==0;

        function updateCounts (){ // note this route has a user resolve.
            self.userDetails = userService.details_data;
            self.userCirc = {
                fines: self.userDetails.circdata.fines.total,
                accruing: self.userDetails.circdata.fines.total_accruing,
                issues: {   count: self.userDetails.circdata.issues.total,
                            overdue: { count: self.userDetails.circdata.issues.overdue },
                            dueSoon: 0
                        },
                holds: { count: self.userDetails.circdata.holds.total }
            };
            if(userService.overdrive){
                userService.overdrive.patron().then(function(patronData){
// not getting patronData here.
                    if (! isNaN(patronData.checkouts.totalCheckouts)) {
                        self.userCirc.issues.count += patronData.checkouts.totalCheckouts;
                        self.userCirc.holds.count += patronData.holds.totalItems;
                    }
                });
            } // fixme: what about cloudlibrary?

            kwApi.Hold.getForPatron({id: userService.id}, function(holds){
                var waitingHolds = holds.filter(function(h){ return h.found=='W' ;});
                self.userCirc.holds.waiting = {
                    count: waitingHolds.length,
                };
                self.userCirc.holds.suspended = holds.filter(function(h){ return h.found=='S'}).length;
                waitingHolds.forEach(function(h){
                    if( !self.userCirc.holds.waiting.until || h.waitingdate < self.userCirc.holds.waiting.until ){
                        self.userCirc.holds.waiting.until = h.waitinguntil;
                        self.userCirc.holds.waiting.pickupBy = h.pick_up_by;
                        self.userCirc.holds.waiting.branch = h.branchcode;
                    }
                });
            });
            self.recentActivity = {
                issues: {
                    month: 0,
                    year: 0
                }
            };

            kwApi.Issue.getForPatron({id: userService.id}, function(issues){
                var soon = dayjs().add(7,'day');
                // must get history separately;
                // avoid for now since it may be expensive.
                // var monthAgo = dayjs().subtract(30, 'day');
                // var yearAgo = dayjs().subtract(365, 'day');
                var now = new Date();
                issues.forEach(function(issue){

                    var issued = new Date(issue.issuedate);
                    // if (issued > yearAgo) self.recentActivity.issues.year++;
                    // if (issued > monthAgo) self.recentActivity.issues.month++;
                    if(!issue.returndate){
                        if(issue.isOverdue()){
                            if(!self.userCirc.issues.overdue.since ||
                                  self.userCirc.issues.overdue.since > issue.duedate ){
                                self.userCirc.issues.overdue.since = issue.duedate;
                            }
                        } else {
                            if( dayjs(issue.duedate).isBefore( soon ) ){
                                self.userCirc.issues.dueSoon++;
                            }
                        }
                    }
                });
            });
        }

        userService.getProxyRelations().then(function(proxyrelations){

                // rely on shared scope here.
                if(proxyrelations.relations || proxyrelations.reverse_relations)
                    $scope.proxy = self.proxy = proxyrelations;
            },function(e){
                console.warn(e);
            });

        this.navList = [
            'details', 'lists', 'callslips', 'illrequests','suggestions', 'tags',
            'messages', 'jobs', 'message-prefs', 'prefs', 'social', 'proxy-rel',
            'acqsubs', 'acqlists_switch', 'patron-group'
        ];

        this.userView = {  // by $state name.
            // FIXME: label duplicates pageSubTitle in $state config.
            'dashboard': {
                label: "My Library Dashboard",
                icon: "coin bi bi-person-circle"
            },
            'details': {
                // hide: !(configService.OPACPatronDetails),
                label: "My Personal Details",
                icon: "bi bi-person-circle"
            },
            'callslips': {
                hide: !(configService.OPACCallslipRequests||configService.OPACDocumentDeliveryRequests),
                label: "My Requests",
                icon: "bi bi-tag"
            },
            'lists': {
                hide: !(configService.virtualshelves && userService.can({catalogue: {bib_list: 'create'}})),
                label: "My " + configService.wording.lists ,
                icon: "bi bi-card-list"
            },
            'acqlists_switch': {
                hide: !(configService.AcqLists),
                label: "My " + (configService.AcqList.wording.title || 'acquisition lists') ,
                icon: "bi bi-list"
            },
            'acqsubs': {
                hide: !(configService.AcqLists),
                label: "My " + (configService.AcqList.wording.activeSubs || 'current subscriptions') ,
                icon: "bi bi-list"
            },
            'tags': {
                hide: !(configService.TagsEnabled),
                label: "My Tags",
                icon: "bi bi-tags"
            },
            'suggestions': {
                hide: !(configService.suggestion),
                label: "My Purchase Suggestions",
                icon: "bi bi-megaphone"
            },
            'illrequests': {
                hide: !(configService.ILLRequests),
                label: "My Interlibrary Loan Requests",
                icon: "bi bi-hdd-stack"
            },
            'messages': {
                label: "My Messages",
                icon: "bi bi-inbox"
            },
            'jobs': {
                hide: !(configService.AsyncJobs && userService.can({tools: {async_jobs: 'view'}})),
                label: "My Batch Jobs",
                icon: "bi bi-speedometer"
            },
            'message-prefs': {
                label: "My Messaging Preferences",
                icon: "bi bi-cloud-download"
            },
            'prefs': {
                label: "My General Preferences",
                icon: "bi bi-gear"
            },
            'social': {
                hide: !(configService.SocialLogin),
                label: "Social Login",
                icon: "bi bi-app-indicator"
            },
            'proxy-rel': {
                hide: $scope.proxy,  // ng-hide in an ng-repeat .
                label: "Proxy Borrowing",
                icon: "bi bi-shuffle"
            },
            'patron-group': {
                label: "Patron Group",
                icon: "bi bi-share"
            },
            'fines': {
                label: "My Fines",
                icon: "bi bi-credit-card"
            },
            'issues': {
                label: "My Checkouts",
                icon: "bi bi-stack"
            },
            'holds': {
                label: "My Holds",
                icon: "bi bi-calendar-range"
            },
            'issue-history': {
                label: "My Checkout History",
                icon: "bi bi-stack"
            },
            'hold-history': {
                label: "My Holds History",
                icon: "bi bi-calendar-range"
            },
        };

    }])


    .controller('UserHistoryCtrl', ["$scope", "kwApi", "userService", "configService", "Pager", "bibService", "$filter", function ($scope, kwApi, userService, configService, Pager, bibService, $filter) {

        $scope.user = userService;
        $scope.config = configService;

        var pagelength = 20;

        $scope.pager = {};

        $scope.issues = {
            page : [],
            history : []
        };
        $scope.pageChanged = function(){
            $scope.issues.page =
                $scope.issues.history.slice( $scope.pager.offset(), $scope.pager.rangeEnd());
            $scope.issues.page.forEach(function(issue){
                if(!issue.bib){
                    bibService.get(issue.itemSummary.biblionumber).then(function(bib){
                        issue.bib = bib;
                    });
                }
            });
        };
        $scope.order = {
            field: 'issuedate',
            reverse: true
        };
        $scope.$watch('order', function(order,old){
            if(!$scope.issues.history.length) return;
            $scope.issues.history = $filter('orderByDisplay')($scope.issues.history,
                    $scope.order.field, $scope.order.reverse);
            $scope.pageChanged();
        }, true);

        $scope.issues.history = kwApi.Issue.getForPatron(
            { id: userService.id, view: "history" },
            function (issues){

                $scope.pager = new Pager({
                    count: issues.length,
                    pagelength: pagelength
                });
                issues.forEach(function(issue){
                    // extend the $resource object (we never update it)
                    issue.title = issue.itemSummary.bib_title;
                });
                $scope.pageChanged();
        });

    }])

    .controller('UserIssuesCtrl', ["$scope", "$uibModal", "$http", "kwApi", "SelectionMgr", "userService", "configService",
                                    "resolvedUser", "bibService", "kohaDlg", "$q", "alertService", function (
                                    $scope, $uibModal, $http, kwApi, SelectionMgr, userService, configService,
                                    resolvedUser, bibService, kohaDlg, $q, alertService) {

        $scope.userdetails = resolvedUser;
        $scope.issuedBib = {};
        $scope.renewalOk = {};
        $scope.renewableCount = function(){
            if(!$scope.issues) return;
            return $scope.issues.reduce(function(cnt,issue){
                return cnt + ($scope.renewalOk[issue.id] ? 1 : 0)}, 0)
        };
        $scope.selection = new SelectionMgr();
        $scope.user = userService;
        $scope.config = configService;

        var currentIssue = {};

        var reload = function(){
            $scope.issues = kwApi.Issue.getForPatron({ id: userService.id }, function (issues){
                issues.forEach(function(checkout){
                    currentIssue[checkout.id] = checkout;
                    bibService.get(checkout.itemSummary.biblionumber).then(function(bib){
                        $scope.issuedBib[checkout.id] = bib;
                        if(bib.itemsAreRenewable()){
                            checkout.$testRenew({}, function(){
                                $scope.renewalOk[checkout.id] = true;
                            });
                        }
                    });
                });
            });
        };
        reload();

        $scope.order = {
            field: 'duedate',
            reverse: false,
            map: {
                'id': function(issueid){
                    // sort on title.
                    var title = ($scope.issuedBib[issueid]||{}).title_ext;
                    return (typeof(title) == 'string') ?
                            title.replace(/^(a|an|the) /i,'') : title;
                }
            }
        };

        $scope.selectAll = function(bool) {
            if (bool)  $scope.selection.select($scope.issues);
            else       $scope.selection.clear();
        };
        $scope.renewalError = {};

        $scope.renew = function(issue) {
            if(issue){
                $scope.selection.clear();
                $scope.selection.select(issue);
            }
            var count = $scope.selection.count();

            var confirm = true;
            if ( count > 1 ){
                confirm = kohaDlg.dialog({
                        heading: 'Renew Items',
                        message: 'You selected ' + count + ' items. Are you sure you want to renew ' +
                                 ((count < $scope.userdetails.circdata.issues.total) ? ('the selected') : 'all') + ' items?',
                        buttons: [{
                                    val: true,
                                    label: 'Renew',
                                    btnClass: 'btn-primary'
                                }, {
                                    val: false,
                                    label: 'Cancel'
                                }]
                    }).result;
            }
            $q.when(confirm).then(function (result) {
                if (result) {
                    var renewal_promises = [];
                    var allOk = true;
                    angular.forEach($scope.selection.selected, function (go,id){
                        if(go && $scope.renewalOk[id]){
                            currentIssue[id]._renewing = true;
                            renewal_promises.push(
                                currentIssue[id].$renew({},function(issue){
                                    delete $scope.renewalOk[id];
                                    return issue;
                                }, function(err){
                                    console.log(err);
                                    allOk = false;
                                    $scope.renewalError[id] = err;
                                    delete $scope.renewalOk[id];
                                })
                            );
                        }
                    });
                    if( ! renewal_promises.length ) return;
                    $q.all(renewal_promises).then(function(results) {
                        userService.updateCircData();
                        $scope.selection.clear();
                        var renewedCnt = results.reduce(function(cnt,issue){
                            if(!issue || !angular.isObject(issue)) return cnt;
                            return cnt + (issue.renewedToday() ? 1 : 0 );
                        }, 0);
                        var msg = '';
                        if(allOk && renewedCnt === 0) renewedCnt = 1; // RenewalPeriodBase := date_due
                        if(renewedCnt)
                            msg += renewedCnt + ((renewedCnt==1) ? ' item':' items') + " successfully renewed!\n";
                        if(!allOk)
                            msg += 'Could not renew some items at this time.';
                        alertService.add({
                            type: allOk ? 'success' : 'error' ,
                            msg: msg
                        });
                     });
                }
            });
        };

        $scope.renewAll = function() {
            $scope.selection.select($scope.issues.filter(function(issue){ return $scope.renewalOk[issue.id]; }));
            $scope.renew();
        };

        $scope.selfCheckoutOpen = function () {
            var dialog = $uibModal.open({
                backdrop: true,
                templateUrl: '/app/static/partials/user-selfcheckout-modal.html',
                controller: 'UserSelfCheckoutCtrl'
            });
            dialog.result.finally(function () {
                userService.updateCircData();
                reload();
            });
            return false;
        };

        $scope.digitalCheckouts = {}; // bibs.
        $scope.hasOverdrive = false;
        if(userService.overdrive){
            userService.overdrive.updatePatron().then(function(odpatron){
                if(odpatron.checkouts.totalCheckouts){
                    $scope.hasOverdrive = true;
                    odpatron.checkouts.checkouts.forEach(function(checkout){
                        $http.get("/api/overdrive/holdings/"+ checkout.reserveId +"/work")
                            .then(function(rsp){
                                var bibid = rsp.data.id;
                                bibService.get(bibid).then(function(bib){
                                    $scope.digitalCheckouts[bibid] = {
                                        bib: bib,
                                        reserveId: checkout.reserveId,
                                        expires: checkout.expires,
                                        checkoutDate: checkout.checkoutDate
                                    };
                                });
                            });
                    });
                }
            });
        }

        $scope.cloudLibraryCheckouts = {}; // bibs.
        $scope.hasCloudLibrary = false;
        if (userService.cloudlibrary) {
            userService.cloudlibrary.update().then( function (clpatron) {
                if (clpatron.PatronCirculation.Checkouts) {
                    $scope.hasCloudLibrary = true;

                    var patronCheckouts = clpatron.PatronCirculation.Checkouts;

                    if (angular.isArray(patronCheckouts.Item)) {
                        patronCheckouts.Item.forEach( function (checkout) {
                            $http.get("/api/cloudlibrary/holdings/"+ checkout.ItemId +"/work")
                                .then(function(rsp){
                                    var expiresToLocal = new Date(checkout.EventEndDateInUTC + 'Z');
                                    var checkoutDateToLocal = new Date(checkout.EventStartDateInUTC + 'Z');
                                    var bibid = rsp.data.id;
                                    bibService.get(bibid).then( function (bib) {
                                        $scope.cloudLibraryCheckouts[bibid] = {
                                            bib: bib,
                                            reserveId: patronCheckouts.Item.ItemId,
                                            expires: expiresToLocal,
                                            checkoutDate: checkoutDateToLocal
                                        };
                                    });
                                });
                        });
                    }
                    else {
                        $http.get("/api/cloudlibrary/holdings/"+ patronCheckouts.Item.ItemId +"/work")
                            .then(function(rsp){
                                var expiresToLocal = new Date(patronCheckouts.Item.EventEndDateInUTC + 'Z');
                                var checkoutDateToLocal = new Date(patronCheckouts.Item.EventStartDateInUTC + 'Z');
                                var bibid = rsp.data.id;
                                bibService.get(bibid).then( function (bib) {
                                    $scope.cloudLibraryCheckouts[bibid] = {
                                        bib: bib,
                                        reserveId: patronCheckouts.Item.ItemId,
                                        expires: expiresToLocal,
                                        checkoutDate: checkoutDateToLocal
                                    };
                                });
                            });
                    }
                }
            });
        }

    }])

    .controller('UserHoldsCtrl', ["$scope", "kwApi", "alertService", "$http", "SelectionMgr", "$timeout", "$uibModal", "userService", "configService", "bibService", "$q", function ($scope, kwApi, alertService, $http, SelectionMgr,
                    $timeout, $uibModal, userService, configService, bibService, $q) {

        var selector = new SelectionMgr();
        $scope.holds = {
            current: {},
            selected: selector.selected,
            count: {},
        };
        $scope.selectedCount = selector.count;

        $scope.selectAll = function(bool) {
            console.log(bool);
            if (bool){
                $scope.setAllowedActions();
                var noTransits = $scope.holdsArr.filter(function(el){
                    return el.found != 'T' && el.found != 'W';
                });
                selector.select(noTransits);
            } else {
                selector.clear();
            }
        };
        $scope.heldBib = {};
        $scope.isProcessing = function(hold){ return hold.found=='T' || hold.found=='W'; };
        $scope.holdsArr =
            kwApi.Hold.getForPatron({id: userService.id},function(holds){
                holds.forEach(function(hold){
                    $scope.holds.current[hold.id] = hold;
                    // kwApi.Work.get({id: hold.biblionumber}, function(bib){
                    //     $scope.heldBib[hold.id] = bib;
                    // });
                    bibService.get(hold.biblionumber).then(function(bib){
                        $scope.heldBib[hold.id] = bib;
                    });

                    if(hold.found=='W'){
                        $scope.holds.count.waiting++;
                    } else if(hold.found=='T'){
                        $scope.holds.count.transiting++;
                    } else if(hold.found=='S'){
                        $scope.holds.count.suspended++;
                    } else {
                        $scope.holds.count.pending++;
                    }
                });
        });
        $scope.statusDate = function(hold){
            return ({W:'0',T:'1',S:'3'}[hold.found]||'2')+hold.reservedate;
        };
        $scope.order = {
            field: 'statusDateSort()',
            reverse: false,
            map: {
                'id': function(input){ // title.
                    var title = ($scope.heldBib[input]||{}).title_ext;
                    return (typeof(title) == 'string') ?
                            title.replace(/^(a|an|the) /i,'') : title;
                }
            }
        };
        $scope.itemCountStr = function(hold){
            if(!$scope.heldBib[hold.id]) return '';
            return $scope.heldBib[hold.id].summary.item_count +
                ' cop' + ($scope.heldBib[hold.id].summary.item_count==1 ?
                    'y' : 'ies');
        };
        $scope.cancelHold = function (hold) {
            if (!hold.deleting) {
                hold.deleting = true;
                hold.$cancel({},function(h){
                    hold.canceled = true;
                    userService.updateCircData();
                    $timeout(function () {
                        delete $scope.holds.current[hold.id]; // TODO: ngAnimate.
                        var i = $scope.holdsArr.indexOf(hold);
                        if(i>=0) $scope.holdsArr.splice(i,1);
                        else console.warn('NoMatch on delete', hold);
                    }, 700);
                }, function(err) {
                    alertService.add({
                        type: 'error',
                        msg: "Error!  This hold cannot be canceled at this time.  Please contact the library for assistance."
                    });
                    hold.deleting = false;
                });
            }

            return false;
        };

        $scope.suspendHold = function (hold) {
            if (!hold.suspending) {
                $uibModal.open({
                    backdrop: true,
                    templateUrl: '/app/static/partials/suspendHold-modal.html',
                    controller: ["$scope", "$uibModalInstance", function ($scope, $uibModalInstance) {
                        $scope.config = configService;
                    }],
                    windowClass: "modal"
                }).result.then(function(val) {
                    if (typeof(val) == 'object') {
                        hold.suspending = true;
                        var param = {};
                        if (val.resume_date)
                            param.resume_date = val.resume_date.toISOString().substring(0,10);
                        hold.$suspend( param, function(h){
                            hold.suspending = false;
                            userService.updateCircData();
                        }, function(err) {
                            alertService.add({
                                type: 'error',
                                msg: "Error!  This hold cannot be suspended at this time.  Please contact the library for assistance."
                            });
                            hold.suspending = false;
                        });
                    }
                });
            }

            return false;
        };

        $scope.resumeHold = function (hold) {
            if (!hold.resuming) {
                hold.resuming = true;
                hold.$resume({}, function(h){
                    userService.updateCircData();
                }, function(err){
                    alertService.add({
                        type: 'error',
                        msg: "Error!  This hold cannot be resumed at this time.  Please contact the library for assistance."
                    });
                    hold.resuming = false;
                });
            }

            return false;
        };
        $scope.digitalHolds = {}; // bibs.
        $scope.hasOverdrive = false;
        if(userService.overdrive){
            userService.overdrive.updatePatron().then(function(odpatron){
                if(odpatron.holds.totalItems){
                    $scope.hasOverdrive = true;
                    odpatron.holds.holds.forEach(function(hold){
                        $http.get("/api/overdrive/holdings/"+ hold.reserveId +"/work")
                            .then(function(rsp){
                                var bibid = rsp.data.id;
                                bibService.get(bibid).then(function(bib){
                                    $scope.digitalHolds[bibid] = {
                                        bib: bib,
                                        reserveId: hold.reserveId,
                                        date: hold.holdPlacedDate
                                    };
                                });
                            });
                    });
                }
            });
        }

        $scope.cloudLibraryHolds = {}; // bibs.
        $scope.hasCloudLibrary = false;
        if (userService.cloudlibrary) {
            userService.cloudlibrary.update().then( function (clpatron) {
                if (clpatron.PatronCirculation.Holds) {
                    $scope.hasCloudLibrary = true;
                    var patronHolds = clpatron.PatronCirculation.Holds;
                    if (angular.isArray(patronHolds.Item)) {
                        patronHolds.Item.forEach( function (hold) {
                            $http.get("/api/cloudlibrary/holdings/"+ hold.ItemId +"/work")
                                .then(function(rsp){
                                    var bibid = rsp.data.id;
                                    var holdDateToLocal = new Date(hold.EventStartDateInUTC + 'Z');
                                    bibService.get(bibid).then( function (bib) {
                                        $scope.cloudLibraryHolds[bibid] = {
                                            bib: bib,
                                            reserveId: hold.ItemId,
                                            date: holdDateToLocal
                                        };
                                    });
                                });
                        });
                    }
                    else {
                        $http.get("/api/cloudlibrary/holdings/"+ patronHolds.Item.ItemId +"/work")
                            .then(function(rsp){
                                var bibid = rsp.data.id;
                                var holdDateToLocal = new Date(patronHolds.Item.EventStartDateInUTC + 'Z');
                                bibService.get(bibid).then( function (bib) {
                                    $scope.cloudLibraryHolds[bibid] = {
                                        bib: bib,
                                        reserveId: patronHolds.Item.ItemId,
                                        date: holdDateToLocal
                                    };
                                });
                            });
                    }
                }
            });
        }

        var allowedActions = [
            {   name: 'suspend',
                text: 'Suspend',
                icon: 'icon-pause',
                successmsg: 'Selected holds successfully suspended!',
                errmsg: 'Error!  Could not suspend some holds at this time.  Please contact the library for assistance.',
                can: function(){ 
                    var can_act = $scope.selectedCount();
                    if (!can_act) return can_act;
                    $scope.holdsArr.forEach(function(hold){
                       if ($scope.holds.selected[hold.id] && hold.found )
                            can_act = false;
                    });
                    return can_act;
                },
                do: function(){
                    this.items = [];
                    Object.keys($scope.holds.selected).forEach(function(id){
                       if ($scope.holds.selected[id] && 
                            !$scope.holds.current[id].suspending) { 
                            this.items.push(id);
                       } 
                    }.bind(this));                   
                    $scope.processSelections(this); 
                }
            },
            {   name: 'resume',
                text: 'Resume',
                icon: 'bi bi-play-fill',
                successmsg: 'Selected holds successfully resumed!',
                errmsg: 'Error!  Could not resume some holds at this time.  Please contact the library for assistance.',
                can: function(){
                    var can_act = $scope.selectedCount();
                    if (!can_act) return can_act;
                    $scope.holdsArr.forEach(function(hold){
                       if ($scope.holds.selected[hold.id] && hold.found!='S')
                            can_act = false;
                    });
                    return can_act;
                },
                do: function(){
                    this.items = [];
                    Object.keys($scope.holds.selected).forEach(function(id){ 
                       if ($scope.holds.selected[id] && 
                            !$scope.holds.current[id].resuming) { 
                            this.items.push(id);
                       } 
                    }.bind(this));                   
                    $scope.processSelections(this); 
                }
            },
            {   name: 'cancel',
                text: 'Cancel',
                icon: 'icon-trash',
                successmsg: 'Selected holds successfully canceled!',
                errmsg: 'Error!  Could not cancel some holds at this time.  Please contact the library for assistance.',
                can: function(){
                    var can_act = $scope.selectedCount();
                    if (!can_act )
                        return false;
                $scope.holdsArr.forEach(function(hold){
                    if ( !userService.can({circulate:'cancel_waiting_holds'}) &&
                         $scope.holds.selected[hold.id] && $scope.isProcessing(hold))
                        can_act = false;
                });

                    return can_act;
                },
                do: function(){
                    this.items = [];
                    Object.keys($scope.holds.selected).forEach(function(id){ 
                       if ($scope.holds.selected[id] && 
                            !$scope.holds.current[id].deleting) { 
                            this.items.push(id);
                       }
                    }.bind(this));
                    $scope.processSelections(this);
                }
            }
        ];
        $scope.allowedActions = [];        
        $scope.setAllowedActions = function(){ 
            $scope.allowedActions.length = 0;
            allowedActions.forEach(function(action){
                $scope.allowedActions.push(action);
            });
        };
        $scope.setAllowedActions();

        $scope.updateSelection = function() {
            $scope.setAllowedActions();
        };

        $scope.processSelections = function(action) {
            // console.log('will process ids:'+action.items);
            var count = $scope.selectedCount();            
            var msg = 'You selected ' + count + ((count == 1) ? ' hold' : ' holds') + '. Are you sure you want to ' + action.text.toLowerCase() + ' the selected holds?';            

            $uibModal.open({
                backdrop: true,
                templateUrl: '/app/static/partials/batchProcessHolds-modal.html',
                controller: ["$scope", "$uibModalInstance", function ($scope, $uibModalInstance) {
                    $scope.config = configService;
                    $scope.title = action.text + ' Selected Holds';
                    $scope.msg = msg;
                    $scope.btnLabel = action.text + ' Holds';
                    $scope.actionType = action.name;
                }],
                windowClass: "modal"
            }).result.then(function(val) {
                if (typeof(val) == 'object') {
                    var suspendParam = {};
                    console.log(val);
                    if (val.resume_date)
                        suspendParam.resume_date = val.resume_date.toISOString().substring(0,10);

                    var action_promises = [];
                    angular.forEach(action.items, function(id) {
                       if ($scope.holds.selected[id]) { 
                            switch(action.name) {
                            case 'suspend':
                                $scope.holds.current[id].suspending = true;
                                action_promises.push(
                                    $scope.holds.current[id].$suspend(suspendParam).$promise
                                    );
                                break;
                            case 'resume':
                                $scope.holds.current[id].resuming = true;
                                action_promises.push(
                                    $scope.holds.current[id].$resume().$promise
                                    );
                                break;
                            case 'cancel':
                                $scope.holds.current[id].deleting = true;
                                action_promises.push(
                                    $scope.holds.current[id].$cancel({}, function(hold){
                                        delete $scope.holds.selected[hold.id];
                                        $timeout(function () {
                                            delete $scope.holds.current[hold.id];
                                            var i = $scope.holdsArr.indexOf(hold);
                                            if(i>=0) $scope.holdsArr.splice(i,1);
                                            else console.warn('NoMatch on delete', hold);
                                        }, 700);
                                    }).$promise
                                );
                                break;
                            }
                       }
                    });
                    $q.all(action_promises).then(function() {
                        userService.updateCircData();
                        alertService.add({
                            type: 'success',
                            msg: action.successmsg
                        });
                        selector.clear();
                    }, function(failures){
                        selector.clear();
                        alertService.add({
                            type: 'error',
                            msg: action.errmsg
                        });
                    });
                }
            });
        };
    }])

    .controller('UserFinesCtrl', ["$scope", "kwApi", "userService", "$q", "configService", function ($scope, kwApi, userService, $q, configService) {
        $scope.order = {
            field: 'timestamp',
            reverse: true,
        };
        $scope.creditOrder = {
            field: 'date',
            reverse: true,
        };

        $scope.account = {
            fees: [],
            unallocated: []
        };
        $scope.totaldue = userService.details_data.circdata.fines.total;
        $scope.refreshAccount = function(){
            userService.updateCircData().then( function(circdata){
                $scope.totaldue = circdata.fines.total;
            });
            getAcct();
        }
        $scope.canPay = (configService.pmtGatewayCfg.paypal||{}).enable && userService.can( {fees: 'webpay'} );
console.warn($scope.canPay);

        $scope.acctLabel = { '_ACCRUING': 'Accruing Overdue' };
        function getAcct() {
          kwApi.AccountType.getList({}).$promise.then(function(ats) {
            ats.forEach(function(accttype){
                $scope.acctLabel[accttype.accounttype] = accttype.description;
            });

            $q.all([
                kwApi.Fee.getForPatron({patron_id: userService.id, type: 'accruing' }).$promise,
                kwApi.Fee.getForPatron({patron_id: userService.id, type: 'outstanding' }).$promise,
                kwApi.Payment.getForPatron({ patron_id: userService.id }).$promise,
            ]).then(function(values) {
                $scope.account.fees = values[0];
                Array.prototype.push.apply($scope.account.fees, values[1]);
                $scope.account.fees.forEach(function(fee) {
                    fee._type_description = $scope.acctLabel[fee.accounttype] + ' / ' + (fee.description || fee.title);
                });
                $scope.account.unallocated = values[2].filter(function(pmt){ return pmt.unallocated; });
            });
          });
        }
        getAcct();

    }])
    .controller('UserCallslipsCtrl', ["$scope", "kohaCallslipSvc", "bibService", "$filter", "configService", function ($scope, kohaCallslipSvc, bibService, $filter, configService) {
        $scope.callslipSvc = kohaCallslipSvc;

        kohaCallslipSvc.sync().then(function () {
            kohaCallslipSvc.requests.forEach(function(callslip, i){
                callslip.branch_name = $filter('displayName')(callslip.branch_id,'branch');
                if(callslip.work_id){
                    bibService.get(callslip.work_id).then(function(bib){
                        $scope.callslipSvc.requests[i].bib = bib; // excuse the mutation.
                    });
                }
                $scope.callslipSvc.requests[i].rfi = (callslip.request_type!='doc_del'&&callslip.request_type!='callslip');

            });
        });

        $scope.order = {
            field: '[request_type,request_status,request_time]',
            reverse: false,
            map: {
                type: function(input) { return configService.display(input,'callslip_types') },
            },
        };
    }])

    .controller('UserUpdateCtrl', ["$scope", "$location", "$anchorScroll", "configService", "userService", "alertService", "$window", "$timeout", function ($scope, $location, $anchorScroll, configService, userService, alertService, $window, $timeout) {
        $scope.userData = {};

        $scope.dirty = true; // So the user will see on load what is required.
        $scope.cfg = configService;
        var regex = /\W+/;
        var userRequiredDetails = $scope.cfg.UserRequiredDetails;
        var mustRequire = (userRequiredDetails) ? userRequiredDetails.split(regex) : [];
        var len = mustRequire.length;
        $scope.checkRequired = function(el) {
            for (var i=0; i<len; i++) {
                if (mustRequire[i] == el) {
                    return true;
                }
            }
            return false;
        };

        angular.copy($scope.user.details_data, $scope.userData);
        if ($scope.user.details_data.dateofbirth) {
            var birthDay = new Date ($scope.user.details_data.dateofbirth); // Date() applies an offset setting the date back a day
            var userTimezoneOffset = birthDay.getTimezoneOffset() * 60000;  // get the offset and add it back to get the correct date
            $scope.userData.dateofbirth = new Date(birthDay.getTime() + userTimezoneOffset);
        }
        else {
            $scope.userData.dateofbirth = null;
        }
        $scope.pristine = $scope.user.details_data;

        $scope.pickDate = false;

        $scope.submit = function () {
            var p = userService.updateDetails($scope.userData);
            p.then(function () {
                alertService.add({
                    type: 'success',
                    msg: "Your request has been submitted. Reloading..."
                });
                $timeout(function() {
                    $window.location.reload();
                }, 700);
            }, function () {
                $location.hash("app-body");
                $anchorScroll();
                alertService.add({
                    type: 'error',
                    msg: "Something went wrong with your request.  Please contact the library to make changes."
                });
            });
        };
        $scope.reset = function () {
            angular.copy($scope.pristine, $scope.userData);
        };

        $scope.moderated = !$scope.cfg.opacConfig.patronEdits ? null :
            Object.keys($scope.cfg.opacConfig.patronEdits).some(function(k){
                return $scope.cfg.opacConfig.patronEdits[k].value === 'moderated';
        });

        $scope.cannotSubmit = !$scope.user.canInBranch({borrowers: {modify: '*'}}, $scope.user.details_data.branchcode);
    }])

    .controller('UserListsCtrl', ["$scope", "kohaDlg", "kohaListsSvc", "configService", "userService", "BibBatchService", function ($scope, kohaDlg, kohaListsSvc, configService, userService, BibBatchService) {
        $scope.loadingpage = true;
        $scope.order = {
            field: 'shelfname',
            reverse: false,
        };

        function syncLists () {
            return kohaListsSvc.sync().then(function(){
                    $scope.myLists = kohaListsSvc.get_mine();
                    $scope.publicLists = kohaListsSvc.get_public();
                    $scope.loadingpage = false;
                })
        }
        syncLists();
        $scope.config = configService;

        $scope.listDlgOpen = kohaDlg.lists;
        $scope.listDlgOpen();
        $scope.createList = function(){
            kohaDlg.addToList().finally(function(){
                syncLists();
            });
        }

        $scope.sendList = function (shelfnumber) {
            var list = (shelfnumber) ? kohaListsSvc.lists[shelfnumber] : $scope.list;
            if (!list) return;

            var bibs = list.works.map(function (l) {
                return l.id;
            });
            var userdata = {};
            userService.whenAnyUserDetails().then(function(details){
                userdata = details;
                if(userdata.email){
                    kohaDlg.dialog({
                            heading: "Email " + configService.wording.list,
                            message: "Please enter the recipient email address.",
                            inputs: [{
                                    name: 'recipient',
                                    label: 'Recipient email',
                                    val: userdata.email,
                                    type: 'email'
                                },{
                                    name: 'subject',
                                    label: 'Subject',
                                    val: configService.wording.list + ": " + list.shelfname + " [from " + configService.pageTitle + "]"
                                }],
                            buttons: [{
                                val: true,
                                label: 'Send',
                                btnClass: "btn-primary"
                            }, {
                                val: false,
                                label: 'Cancel'
                            }]
                        }).result.then(function (modalresult) {
                            if (modalresult) {
                                var options = {
                                    op: 'email',
                                    recipient: modalresult.recipient,
                                    subject: modalresult.subject
                                };
                                BibBatchService.submit(bibs, options);
                            }
                        });
                } else {
                    kohaDlg.dialog({
                        type: "notify",
                        alertClass: "warning",
                        heading: "No email address on file",
                        message: "You must have an email address on file with the library to send email."
                    });
                }
            });
        };

        $scope.deleteList = function (id) {
            var title = 'Are you sure?';
            var msg = 'This action cannot be undone.  Permanently delete?';
            var btns = [{
                val: false,
                label: 'Cancel'
            }, {
                val: true,
                label: 'Delete',
                cssClass: 'btn-primary'
            }];

            kohaDlg.dialog({
                heading: title,
                message: msg,
                buttons: btns
            }).result.then(function (result) {
                if (result) {
                    kohaListsSvc.deleteList(id);
                }
            });
        };
    }])

    .controller('UserHoldHistoryCtrl', ["$scope", "bibService", "kwApi", "userService", "configService", "Pager", "$filter", function ($scope, bibService, kwApi, userService,
                                        configService, Pager, $filter) {

        // cfg.ShowHoldsRange: ':stafflimit,:opaclimit' (in days).
        var holdHistoryDays = (configService.ShowHoldsRange||'').trim().split(/\s*,\s*/)[1];
        $scope.showPatronHistory = holdHistoryDays!==0;

        $scope.holds = {
            expired: {
                all: [],
                pager: null
            },
            filled: {
                all: [],
                pager: null
            },
            canceled: {
                all: [],
                pager: null
            },
            shown: []
        };
        var pagesize = 20;

        $scope.showHolds = function(status){
            if(!status) status = $scope.showing;
            if($scope.holds[status].pager){
                $scope.holds.shown = $scope.holds[status].all.slice(
                        $scope.holds[status].pager.offset(), $scope.holds[status].pager.rangeEnd()
                    );
            } else {
                $scope.holds.shown = $scope.holds[status].all;
            }
            $scope.holds.shown.forEach(function(hold){
                if(!hold.bib)
                    hold.bib = bibService.get(hold.biblionumber, { promise: false });
            });
            $scope.showing = status;
        };
        $scope.showHolds('filled');
        $scope.order = {
            field: 'reservedate',
            reverse: true,
            map: {
                bib_title: function(input) {
                    if (typeof(input) !== 'string') return input;
                    return input.replace(/^(a|an|the) /i,'');
                },
            },
        };
        $scope.$watch('order', function(order,old){
            var status = $scope.showing;
            if(!$scope.holds[status].all.length) return;
            $scope.holds[status].all = $filter('orderByDisplay')($scope.holds[status].all,
                    $scope.order.field, $scope.order.reverse);
            $scope.showHolds();
        }, true);

        var cutoff = null;
        if (holdHistoryDays > 0) {
            cutoff = new Date(new Date() - (holdHistoryDays * 86400000));
        }

        var withinCutoff = function(hold){
            if (cutoff) {
                var holdDate = new Date(hold.timestamp);
                return holdDate > cutoff;
            }
            else return true;
        };

        kwApi.Hold.getForPatron({id: userService.id, include_history: true}, function(allHolds){
            var statusMap = {E:'expired',F:'filled',C:'canceled'};
            allHolds.forEach(function(hold){

                var status = statusMap[hold.found];
                if(!status || !withinCutoff(hold) || !hold.biblionumber) return;
                $scope.holds[ status ].all.push(hold);

                // since we don't call any resource methods, we can extend the obj.
                hold.date = function(){
                    return (hold.found=='C'||hold.found=='E') ? hold.cancellationdate :
                            (hold.waitingdate || hold.reservedate) ;
                };
            });
            angular.forEach(statusMap, function(status, code){
                if($scope.holds[status].all.length > pagesize + 9){
                    $scope.holds[status].pager = new Pager({
                        count: $scope.holds[status].all.length,
                        pagelength: pagesize
                    });
                }
            });
            $scope.showHolds();
            $scope.loading = false;
        });

    }])

    .controller('UserTagsCtrl', ["$scope", "kohaTagsSvc", "bibService", "$timeout", function ($scope, kohaTagsSvc, bibService, $timeout) {

        $scope.loading = true;
        $scope.notags = false;

        kohaTagsSvc.sync_mytags().then(function (tags) {
            $scope.tags = [];
            angular.forEach( tags, function (tag, id) {
                if(!tag.title){
                    bibService.get(tag.biblionumber).then(function(bib){
                        tag.title = bib.title;
                        tag.title_ext = bib.title_ext;
                    });
                }
                $scope.tags.push(tag);
            });
            $scope.loading = false;
            if ($scope.tags.length === 0) $scope.notags = true;
        });

        $scope.order = {
            field: 'term',
            reverse: false
        };

        $scope.deleteTag = function (id) {
            kohaTagsSvc.deleteTag(id);
            var index = -1;
            for (var i = $scope.tags.length - 1; i >= 0; i--) {
                if ($scope.tags[i].tag_id === id) {
                    index = i;
                    break;
                }
            }
            // TODO: animate.
            if (index > 0) {
                $scope.tags[index].deleting = true;
                $timeout(function () {
                    $scope.tags.splice(index, 1);
                }, 400);

            }
        };

    }])

    .controller('UserSuggestionsCtrl', ["$scope", "kohaSuggestSvc", "resolvedSuggestions", function ($scope, kohaSuggestSvc, resolvedSuggestions) {
        $scope.refresh = function() {
            kohaSuggestSvc.mine().then(function (sug) {
                $scope.suggestions = sug;
            });
        };

        $scope.suggestions = resolvedSuggestions;

        $scope.suggestionSvc = kohaSuggestSvc;
        $scope.deleteSuggestion = function (id) {
            kohaSuggestSvc.deleteSuggestion(id).then(function () {
                kohaSuggestSvc.mine().then(function (sug) {
                    $scope.suggestions = sug;
                });
            });
        };
        $scope.order = {
            field: 'title',
            reverse: false
        };

        $scope.$on('suggestionSubmitted', function () {
            kohaSuggestSvc.mine().then(function (sug) {
                $scope.suggestions = sug;
            });
        });

    }])

    .controller('UserILLRequestsCtrl', ['$scope', '$uibModal', 'configService', 'kohaILLRequestSvc', 'resolvedIllRequests', function ($scope, $uibModal, configService, kohaILLRequestSvc, resolvedIllRequests) {
        $scope.model = kohaILLRequestSvc;

        $scope.order = {
            field: 'title',
            reverse: false
        };

        $scope.requests = resolvedIllRequests;

        $scope.refresh = function() {
            $scope.model.mine().then(function (s) {
                $scope.requests = s;
            });
        };
        $scope['delete'] = function (id) {
            $scope.model['delete'](id).then(function () {
                $scope.refresh();
            });
        };

        $scope.create = function () {
            var listScope = $scope;
            $uibModal.open({
                backdrop: true,
                templateUrl: '/app/static/partials/ill-request-modal.html',
                controller: ["$scope", "$uibModalInstance", function ($scope, $uibModalInstance) {
                    $scope.request = {
                        notifications: {
                            approved: true,
                            ordered: true,
                            received: true,
                            available: true,
                            rejected: true
                        }
                    };
                    $scope.config = configService;
                    $scope.submit = function (data) {
                        var codes = [];
                        angular.forEach(data.notifications, function(val, key) {
                            if (val) {
                                codes.push(key);
                            }
                        });
                        data.notifications = codes.join(',');
                        listScope.model.create(data).then(function() {
                            $uibModalInstance.close();
                            listScope.refresh();
                        });
                    };
                }],
                windowClass: "modal purchase-suggestion"
            });
        };

        $scope.update = function(req) {
            var listScope = $scope;
            $uibModal.open({
                backdrop: true,
                templateUrl: '/app/static/partials/ill-request-modal.html',
                controller: ["$scope", "$uibModalInstance", function ($scope, $uibModalInstance) {
                    $scope.request = angular.copy(req);
                    var codes = req.notifications.split(',');
                    $scope.request.notifications = {};
                    codes.forEach(function(c) { $scope.request.notifications[c] = true; });

                    $scope.config = configService;
                    $scope.submit = function (data) {
                        var codes = [];
                        angular.forEach(data.notifications, function(val, key) {
                            if (val) {
                                codes.push(key);
                            }
                        });
                        data.notifications = codes.join(',');
                        listScope.model.update(data).then(function() {
                            $uibModalInstance.close();
                            listScope.refresh();
                        });
                    };
                }],
                windowClass: "modal purchase-suggestion"
            });
        };

    }])

    .controller('UserMessagesCtrl', ['$scope', 'messageService', 'userService', 'resolvedMessages', function($scope, messageService, userService, resolvedMessages) {
        $scope.order = { field: 'created_time', reverse: true };
        $scope.messages = resolvedMessages;
        $scope.rssUri = '/api/patron/' + userService.id + '/messages/rss';

        $scope.refresh = function() {
            messageService.getList().then(function(lst) {
                $scope.messages = lst;
            });
        };

        $scope.classOf = function(n) {
            return ((n.level && n.level > 1)  ? 'message-alert' : 'message');
        };

        $scope.clear = function(n) {
            messageService.clear(n).then(function() {
                for (var i=0; i<$scope.messages.length; i++) {
                    if ($scope.messages[i].id == n.id) {
                        $scope.messages.splice(i, 1);
                        break;
                    }
                }
            });
        };

        $scope.clearAll = function() {
            // Note backwards so splice works properly
            for (var i=$scope.messages.length-1; i>=0; i--) {
                var ii = i;
                messageService.clear($scope.messages[i]).then(function() {
                    $scope.messages.splice(ii,1);
                });
            }
        };
    }])

    .controller('UserMessagePrefsCtrl', ['$scope', 'messageService', 'userService', 'alertService', 'resolvedMsgPrefs', 'configService', function($scope, messageService, userService, alertService, resolvedMsgPrefs, configService) {
        $scope.ttech_voice = configService.TalkingTechVoice;
        $scope.ttech_text = configService.TalkingTechText;
        $scope.prefcats = resolvedMsgPrefs;
        $scope.isRole = (userService.whenAnyUserDetails().categorycode == 'ROLE');
        $scope.pollInterval = messageService.getPollInterval();
        
        $scope.isSelected = function(n) {
            return (n > 0 && n < 9);
        };


        $scope.refresh = function() {
            messageService.getPrefs().then(function(data) {
                $scope.prefcats = data;
                // TODO - This should be tied to user preferences
                $scope.pollInterval = messageService.getPollInterval();
            });
        };


        $scope.updatePrefs = function() {
            messageService.setPollInterval($scope.pollInterval);
            messageService.updatePrefs().then(function() {
                alertService.add({msg: "Preferences successfully updated!", type: "success"});
            });
        };
    }])

    .controller('ReadHistVrfyCtrl', ["$scope", "cardnumber", "submit", function($scope, cardnumber, submit) {
        $scope.cardnumber = cardnumber;
        $scope.vrfycardnum = '';
        $scope.submit = submit;
    }])

    .controller('UserPrefsCtrl', ['$scope', 'userService', 'alertService', 'configService', '$uibModal', function($scope, userService, alertService, configService, $uibModal) {
        $scope.user = userService;

        $scope.refresh = function() {
            if ($scope.unwatch) {
                ($scope.unwatch)();
            }
            $scope.prefs = angular.copy(userService.prefs);
            $scope.role_prefs = angular.copy(userService.role_prefs);
            $scope.acqlists_syspref = configService.AcqLists;
            $scope.dirty = false;
            $scope.unwatch = $scope.$watch('prefs', function(newVal, oldVal) {
                if (newVal !== oldVal) {
                    $scope.dirty = true;
                }
            }, true);
        };
        $scope.refresh();

        $scope.has_prefilters = configService.mastheadSearchConfig.prefilter.filters.length;

        $scope.submit = function() {
            $scope.saving = true;
            var filtered = {};
            angular.forEach($scope.prefs, function(val, key) {
                if (typeof(val) === 'string') {
                    if (!val.match(/^\s*$/)) {
                        filtered[key] = val;
                    }
                }
                else {
                    filtered[key] = val;
                }
            });
            userService.setAllPrefs(filtered).then(function() {
                $scope.refresh();
                $scope.saving = false;
                alertService.add({msg: "Preferences successfully updated!", type: "success"});
                $scope.showPref = false;
            }, function() {
                $scope.refresh();
                $scope.saving = false;
            });
        };

        $scope.verify = function() {
            $uibModal.open({
                backdrop: false,
                templateUrl: '/app/static/partials/user/readhistvrfy.html',
                controller: 'ReadHistVrfyCtrl',
                windowClass: "modal readhistvrfy",
                size: 'lg',
                resolve: {
                    cardnumber: function() { return userService.details_data.cardnumber },
                    submit: function() { return $scope.submit },
                },
            });
            return false;
        };
    }])

        .controller('UserSelfCheckoutCtrl', ["$scope", "$uibModalInstance", "kohaIssuesSvc", function ($scope, $uibModalInstance, kohaIssuesSvc) {
            $scope.barcode = {
                error: false
            };
            $scope.alerts = [

            ];

            $scope.closeAlert = function (index) {
                $scope.alerts.splice(index, 1);
            };
            $scope.checkoutItem = function () {
                var outcome = kohaIssuesSvc.checkOutBarcode($scope.barcode.userinput);

                if (outcome.status) {
                    //All is well in the universe
                    //Close modal, refresh checkout page
                    $uibModalInstance.close();
                } else {
                    $scope.alerts = [{
                        msg: outcome.message,
                        type: "danger"
                    }];


                }
            }

        }])


    .controller('UserJobsCtrl', ["$scope", "jobService", "resolvedJobs", "loading", "alertService", "$q", function($scope, jobService, resolvedJobs, loading, alertService, $q) {
        $scope.order = { field: 'scheduled_time', reverse: true };
        $scope.showAll = false;
        $scope.allJobs = resolvedJobs;

        $scope.refresh = function() {
            loading.add();
            jobService.getList().then(function(lst) {
                $scope.allJobs = lst;
                $scope.jobs = $scope.showAll ? $scope.allJobs : $scope.allJobs.filter(function(n) { return !n.module_managed });
                loading.resolve();
            }, function(err) {
                alertService.addApiError(err,'Can\'t load jobs');
                loading.resolve()
            });
        };
        
        $scope.$watch('showAll', function(newVal, oldVal) {
            if (newVal === oldVal) return;
            $scope.jobs = $scope.showAll ? $scope.allJobs : $scope.allJobs.filter(function(n) { return !n.module_managed });
        });

        $scope.classOf = function(n) {
            return 'job-class-' + n.status;
        };

        $scope.canSuspend = function(n) {
            return ((n.status == 'ready') || (n.status == 'retry'));
        };

        $scope.canResume = function(n) {
            return (n.status == 'suspended');
        };

        $scope.suspend = function(n) {
            jobService.suspend(n).then(function() {
                n.status = 'suspended';
            }, function(err) {
                alertService.addApiError(err,'Can\'t suspend job');
            });
        };

        $scope.resume = function(n) {
            jobService.resume(n).then(function() {
                n.status = 'ready';
            }, function(err) {
                alertService.addApiError(err,'Can\'t resume job');
            });
        };

        $scope.canRemove = function(n) {
            return (n.status != 'started');
        };

        $scope.isTerminal = function(n) {
            return (n.status == 'done' || n.status == 'error' || n.status == 'timeout');
        };

        $scope.remove = function(n) {
            if (!$scope.canRemove(n)) return;
            jobService.remove(n).then(function() {
                for (var i=0; i<$scope.jobs.length; i++) {
                    if ($scope.jobs[i].id == n.id) {
                        $scope.jobs.splice(i, 1);
                        break;
                    }
                }
            }, function(err) {
                alertService.addApiError(err,'Can\'t remove job');
            });
        };

        $scope.removeAllCompleted = function() {
            var promises = [];
            loading.add();
            $scope.jobs.forEach(function(job) {
                if ($scope.isTerminal(job) && $scope.canRemove(job)) {
                    promises.push(jobService.remove(job));
                }
            });
            $q.all(promises).then(function(rv) {
                loading.resolve();
                $scope.refresh();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Can\'t remove job');
            });
        };
    }])


    .controller('UserPatronGroupCtrl', ["$scope", "kwApi", "alertService", "userService", "$http", function($scope, kwApi, alertService, userService, $http) {
        $scope.loading = true;
        var user = userService;

        kwApi.PatronGroup.getGroup({id: user.id}).$promise.then(function(rv) {
            console.dir(rv);
            $scope.members = rv;
            $scope.total_holds = 0;
            $scope.total_issues = 0;
            $scope.total_overdue = 0;
            $scope.total_fines = 0;
            rv.forEach(function(m){
                $scope.total_holds += Number(m.holds_summary.total);
                $scope.total_issues += Number(m.issues_summary.total);
                $scope.total_overdue += Number(m.issues_summary.overdue);
                $scope.total_fines += Number(m.fines_summary.balance);
            });
            $scope.loading = false;
        }, function(er) {
            alertService.addApiError(er, 'Error fetching group members for id "' + userService.id + '"');
            $scope.loading = false;
        });

        var once = false;
        $scope.isDependent = function() {
            if (user.details_data.guarantorid){
                if (!once) {
                    $http.get('/api/patron/'+ user.details_data.guarantorid +'/name').then(function (response) {
                        var firstname = response.data.firstname;
                        var surname = response.data.surname;
                        $scope.guarantorName =  firstname + " " + surname;
                    });
                }
                once = true;
                return true;
            }
            else {return false};
        }
    }])

    .controller('MessageDetailCtrl', ['$scope', 'message', 'view', '$timeout', function($scope, message, view, $timeout) {
        $scope.message = message;
        if (view == 'print') {
            $timeout(function() { 
                window.print();
            }, 1000);
        }
    }])

    .controller('JobDetailCtrl', ["$scope", "job", "view", "$timeout", "loading", "kwApi", "alertService", function($scope, job, view, $timeout, loading, kwApi, alertService) {
        $scope.job = job;

        try {
            $scope.job.output = JSON.parse(job.return_value) || {};
        }
        catch (e) {
            $scope.job.output = {};
        }

        $scope.job.total_rows = $scope.job.output.total_rows;
        
        if (job.job_class == 'Report') {
            var jobArgs;
            try {
                jobArgs = JSON.parse(job.args);
            }
            catch (e) {
                ;
            }
            $scope.reload = function() {
                loading.wrap(
                    kwApi.Report.getRun({id: jobArgs.id, rid: job.id}, {
                        sort: $scope.order.field,
                        dir: ($scope.order.reverse ? 'DESC' : 'ASC'),
                        start: $scope.start,
                        count: $scope.count
                    }).$promise,
                    "Unable to load report run"
                ).then(function(data) {
                    try {
                        $scope.job.output = JSON.parse(job.return_value) || {};
                    }
                    catch (e) {
                        $scope.job.output = {};
                    }
                });            
            };
            
            $scope.has_biblionumber = ('biblio_column' in $scope.job.output) ? 1 : 0;
            $scope.has_itemnumber = ('item_column' in $scope.job.output) ? 1 : 0;
        
            $scope.start = 0;
            $scope.count = ($scope.job.total_rows < 20) ? $scope.job.total_rows : 20;
            $scope.pager = new KOHA.Pager({numResults: $scope.job.total_rows, offset: 0, numPerPage: $scope.count});
            $scope.toPage = function(page) {
                $scope.start = (page-1) * $scope.count;
                $scope.reload();
            };
            
            $scope.order = {
                field: '',
                reverse: false,
            };

            $scope.$watch('order', function(newVal) {
                if (newVal && newVal.field) {
                    $scope.reload();
                }
            }, true); 

            $scope.exportCsvLink = function(delim) {
                if (delim == 'tab') delim = "\t";
                return kwApi.Report.$exportDownloadLink(jobArgs.id, job.id, {format: 'csv', delimiter: delim});
            };
            $scope.exportExcelLink = function() {
                return kwApi.Report.$exportDownloadLink(jobArgs.id, job.id, {format: 'xlsx'});
            };

            $scope.exportToBatch = function() {
                loading.add();
                kwApi.Report.exportRunAsBatch({id: jobArgs.id, rid: job.id}, {}).$promise.then(function(batch ) {
                    loading.resolve();
                    alertService.add({msg: "Report exported to <a href=\"/app/staff/tools/marc-manage/" + batch.id + "\">import batch " + batch.id + "</a>", type: "info"});
                }, function(err) {
                    loading.resolve();
                    alertService.addApiError(err,'Can\'t export to import batch');
                });
            };

            
            if (view != 'print') {
                // just get the first $scope.count rows of the report
                $scope.reload(); 
            }            
        }
        
        if (view == 'print') {
            $scope.isPrintView = true;
            $timeout(function() { 
                window.print();
            }, 1000);
        }
    }])



    .controller('VersionCtrl', ["$state", "$uibModal", "configService", function( $state, $uibModal, configService){

        $uibModal.open({
            backdrop: false,
            templateUrl: '/app/static/partials/version-modal.html',
            controller: ["$scope", "$http", function($scope, $http){
                $http.get('/api/version').then(function(rsp){
                    var v = rsp.data;
                    var r = {};
                    var bvlabel = configService.opacConfig.wording.bvproductlabel
                        ? configService.opacConfig.wording.bvproductlabel : "Bibliovation";
                    r[bvlabel] = v.lakweb;
                    var vA = v.ArchivalWare;
                    if (vA) r.Knowvation = vA.substring(vA.indexOf("build:"), vA.length).replace('build: ','');
                    $scope.components = r;
                });
            }],
            windowClass: "modal version"
        });
        // FIXME - this is a kludge, we should really handle modal views better
        if ($state.previousParams)
            $state.go($state.previous, $state.previousParams);
        else
            $state.go($state.previous);
    }])



    .controller('AppConfigCtrl', ["$scope", "$rootScope", "configService", "alertService", "$state", "$location", "kohaDlg", "$q", "$timeout",
                        function ($scope, $rootScope, configService, alertService, $state, $location, kohaDlg, $q, $timeout) {


        // Need to adjust KOHA.config to split out config options.
        $scope.configService = configService;
        var catsources = [];
        for(var key in configService.catsources||{}){
            catsources.push({name : configService.catsources[key], val : key});
        }
        $scope.catsources = catsources.sort(function(a,b){
            var dispA = (a.name||'').toLowerCase();
            var dispB = (b.name||'').toLowerCase();
            if(dispA < dispB) return -1;
            if(dispA > dispB) return 1;
            return 0;
        });
        // this is done because the app my have modified  configService
        $scope.opacConfig = jQuery.extend(true, {}, KOHA.default_config, KOHA.sysprefs.opacConfig);

        // jQuery extend deep copy does not seem to overwrite arrays, it just merges them
        if(KOHA.sysprefs.opacConfig.mastheadSearchConfig)
            $scope.opacConfig.mastheadSearchConfig.queryFieldOptions = KOHA.sysprefs.opacConfig.mastheadSearchConfig.queryFieldOptions;
        if(KOHA.sysprefs.opacConfig.advancedSearch)
            $scope.opacConfig.advancedSearch.queryFields = KOHA.sysprefs.opacConfig.advancedSearch.queryFields;

        if ($scope.opacConfig.ILL.classificationTypes) {
            $scope.opacConfig.ILL.classificationTypesJSON = angular.toJson($scope.opacConfig.ILL.classificationTypes);
        }
        else {
            $scope.opacConfig.ILL.classificationTypesJSON = '';
        }

        $scope.patronEditOptions = ['hidden', 'read-only', 'moderated', 'editable'];

        $scope.advSearchTypes = ['single', 'checkbox', 'pulldown'];

        $scope.iconKeys = Object.keys($scope.opacConfig.icons);

        $scope.forms = {}; // modal adds an extra scope.   [[  not in a modal anymore ]]

        // tmp var for adding query field definitions to adv search interface.
        $scope.qf_defs = {
            basic: {  // advanced search page
                field: '',
                display: ''
            },
            masthead: {
                field: '',
                display: ''
            }
        };

        $scope.getDefaults = function(el){
            if(el == "masthead"){
                $scope.opacConfig.mastheadSearchConfig.queryFieldOptions = KOHA.default_config.mastheadSearchConfig.queryFieldOptions;
            }else if(el == "advancedsearch"){
                $scope.opacConfig.advancedSearch.queryFields.basic = KOHA.default_config.advancedSearch.queryFields.basic;
            }
        }

        $scope.clearQF = function (which) {
            if(!which) which = 'basic';
            $scope.qf_defs[which].field = '';
            $scope.qf_defs[which].display = '';
        };

        $scope.displayQF = function (qf) {
            var dispval = qf.display || qf.label;
            var field_name = (qf.field) ? "<span class='queryfield'>" + qf.field + "</span>" : "(none)";
            return "Field: " + field_name + "; Displayed as: " + dispval;
        };

        $scope.validQF = function (qf, model) {
            // returns true if the qf isn't already in the list.
            // FIXME: This needs to happen for multiple models.
            // i.e. use 'model'.
            var ok = true;

            $scope.opacConfig.advancedSearch.queryFields.basic.forEach(function (extant_qf) {
                if (qf.field == extant_qf.field && qf.display == extant_qf.display) ok = false;
            });
            return ok;
        };

        if (! $scope.opacConfig.mastheadSearchConfig.sortOptions ) {
            $scope.opacConfig.mastheadSearchConfig.sortOptions
                = KOHA.static_config.sortoptions;
        }

        if (! $scope.opacConfig.patronEdits ) $scope.opacConfig.patronEdits = KOHA.static_config.patronEdits;

        $scope.sortopts = {
            value: '',
            label: '',
            group: ''
        };

        $scope.getSortDefaults = function() {
            $scope.opacConfig.mastheadSearchConfig.sortOptions = KOHA.static_config.sortoptions;
        };

        $scope.displaySortOpt = function(opt) {
            return 'Field: <span class="queryfield">' + opt.value + '</span>'
                + '; Displayed as: "' + opt.label + '"; Group: ' + opt.group;
        };

        $scope.validSortOpt = function(opt) {
            if (opt.value == '') {
                return false;
            }
            return true;
        };

        $scope.clearSortOpt = function() {
            $scope.sortopts.value = '';
            $scope.sortopts.label = '';
            $scope.sortopts.group = '';
        };

        $scope.mf_indexdata = {
            metafacets: {
                label: '',
                sources: []
            }
        };

        $scope.mf_eds = {
            metafacets: {
                label: '',
                sources: []
            }
        };

        $scope.displayMF = function (mf) {
            var dispval = mf.sources;
            var label = "<span class='queryfield'>" + mf.label + "</span>";
            return "MetaFacet: " + label + "; Sources: " + dispval;
        };

        $scope.validMF = function (mf, vendor) {
            var ok = true;

            if ($scope.opacConfig.altSearch[vendor].metafacets) {
                $scope.opacConfig.altSearch[vendor].metafacets.forEach(function (extant_mf) {
                    if (mf.label == extant_mf.label) ok = false;
                });
            }

            if (!mf.label || !mf.sources.length > 0) ok = false;

            return ok;
        };

        $scope.clearMF = function (vendor) {
            vendor = 'mf_' + vendor;
            $scope[vendor].metafacets.label = '';
            $scope[vendor].metafacets.sources = [];
        };

        $scope.ebscoContentProviders = [];
        if (configService.EbscoContentProviders) {
            $scope.ebscoContentProviders = configService.EbscoContentProviders.split(',');
        }

        $scope.indexDataSources = [];
        if (configService.IndexDataSourcesConfig) {
            configService.IndexDataSourcesConfig.forEach( function (o) {
                $scope.indexDataSources.push(o.value);
            });
        }

        $scope.rcss_defs = {
            url: '',
            hostname: ''
        };
        $scope.clearRCSS = function () {
            $scope.rcss_defs.url = '';
            $scope.rcss_defs.hostname = '';
        };
        $scope.displayRCSS = function (css) {
            return "Hostname: " + css.hostname + " / Stylesheet URL: " + css.url;
        };
        $scope.validRCSS = function (css) {
            // TODO: determine constraints for regex.
            if (css.url) {
                return true;
            } else {
                return false;
            }

        };

        $scope.authvalList = configService.authvalList;

        $scope.addFilter = function(type){
            var fdef = {
                filterName: '',
                type: type,
                default: '',
                filterDef: {}
            };
            if(type=='manual'){
                fdef.filterDef.options = [];
            } else {
                fdef.filterDef.facet = '';
                fdef.filterDef.unlimited = '';
                fdef.filterDef.maxEntries = '';
                fdef.filterDef.authval= '';
            }
            $scope.opacConfig.mastheadSearchConfig.prefilter.filters.push(fdef);
        };
        $scope.rmFilter = function(i){
            $scope.opacConfig.mastheadSearchConfig.prefilter.filters.splice(i,1);
        };

        $scope.dummyDLSO = {
            created_date: "2018-05-25T13:12:23",
            extension: "jpeg",
            load_date: "2018-06-09T14:44:38-0700",
            modified_date: "2018-05-25T13:12:23",
            product_format: "JFIF",
            product_size: 2469888,
            product_type: "JPEG",
            src_file_info: "Image_19.jpeg,2469888",
            title: "Image_19",
            uuid: "13d30b51-13d3-43e5-8868-f6b3649b68e4"
        };
        $scope.defaultDlsoTmpl = KOHA.default_config.dls.fileTmpl;

        $scope.pagerPreview = {
            page: 1,
            show: true,
            reload: function(){
                this.show = false;
                $timeout(function(){
                    this.show = true;
                }.bind(this) );
            }
        }

        $scope.updateConfig = function () {
            // handle js and css
            $scope.jsError = '';
            $scope.htmlWarning = false;
            var newConfig = angular.copy($scope.opacConfig, {});

            var valid = true; // other validation can be handled in form itself, but
            // we don't want to do this validation on every keystroke.

            ['bibDetail', 'bibResult', 'page', 'load'].forEach(function (js) {

                if (newConfig.userJS[js] && newConfig.userJS[js] != KOHA.config.userJS[js]) {

                    var marc = (js === 'bibResult' || js === 'bibDetail');
                    try {
                        var fcn = (js === 'bibResult') ?
                            new Function('element', 'bib', newConfig.userJS[js]) :
                            (js === 'bibDetail') ?
                            new Function('element', 'bib', 'holdings', newConfig.userJS[js]) :
                            new Function(newConfig.userJS[js]);

                        // we'll run it if it's 'page' or 'load'.
                        // if it's marc js, we'll need to trigger reload of view.

                        if (marc) {
                            // FIXME: docs say to use $state.current.scope.name, but this doesn't exist.
                            // hack check the url for now to see if we trigger reload.


                            //console.warn($route.$$route.controller);
                            //  if($route.$$route.controller.name == 'BibDetailCtrl' || $route.current.scope.name == 'SearchResultsCtrl'){
                            if ((js == 'bibDetail' && $location.path().match(/^\/work/)) || ((js == 'bibResult' && $location.path().match(/^\/search\/.+/)))) {
                                console.log(fcn);
                                console.log(js);

                                configService.userJS[js] = fcn;

                                $state.reload();
                            }
                        } else {
                            fcn();
                            configService.userJS[js] = fcn;
                        }
                        $scope.forms.configForm[js].$setValidity('syntax', true);

                    } catch (e) {
                        $scope.jsError += e + "\n";
                        $scope.forms.configForm[js].$setValidity('syntax', false);
                        valid = false;
                    }

                }
            });

            // FIXME - this smells
            if ((typeof(newConfig.ILL.classificationTypesJSON) === 'string') && (newConfig.ILL.classificationTypesJSON !== '')) {
                newConfig.ILL.classificationTypes = angular.fromJson(newConfig.ILL.classificationTypesJSON);
            }
            else {
                newConfig.ILL.classificationTypes = null;
            }

            if (newConfig.userCSS && newConfig.userCSS != configService.userCSS) {
                KOHA.updateUserCSS(newConfig.userCSS);
            }

            // naive sanitation of html.

            angular.forEach(['logo_html', 'maincontent_html', 'header_html', 'footer_html', 'error403_html', 'error404_html'], function (htmlpref) {
                if (newConfig.layout[htmlpref] && newConfig.layout[htmlpref] != configService.layout[htmlpref].toString()) {
                    var doc = document.createElement('div');
                    doc.innerHTML = newConfig.layout[htmlpref];
                    if (doc.innerHTML != newConfig.layout[htmlpref]) {
                        $scope.htmlWarning = true;
                        newConfig.layout[htmlpref] = $scope.opacConfig.layout[htmlpref] = doc.innerHTML;
                        $scope.forms.configForm[htmlpref].$setValidity('html-syntax', false);
                    } else {
                        $scope.forms.configForm[htmlpref].$setValidity('html-syntax', true);
                    }
                }
            });



            // also reload adv search page if we're there.  re-order directive is working, but we need to get new excludes into that controller.
            if ($location.path().match(/^\/search\/?$/)) {
                $state.reload();
            }
            $rootScope.pageTitle = newConfig.pageTitle;

            if (valid) {
                configService.updateConfig(newConfig);
            }


        };

        var cfgChanged = false;
        $scope.saveConfig = function () {
            cfgChanged = true;
            if ((typeof($scope.opacConfig.ILL.classificationTypesJSON) === 'string') && ($scope.opacConfig.ILL.classificationTypesJSON !== '')) {
                $scope.opacConfig.ILL.classificationTypes = angular.fromJson($scope.opacConfig.ILL.classificationTypesJSON);
            }
            else {
                $scope.opacConfig.ILL.classificationTypes = null;
            }

            configService.saveConfig($scope.opacConfig).then(function () {
                alertService.add({
                    msg: "Configuration settings successfully updated!",
                    type: "success"
                });

                $scope.forms.configForm.$setPristine();
            }).catch(function (data) {
                $scope.error = data;
                alertService.add({
                    msg: "Configuration settings failed to update!  Please ensure you have the required permissions for this action.",
                    type: "error"
                });
            });
        };

        $scope.cancelConfig = function () {
            // TODO: test for pristine.
            var title = 'Really?';
            var msg = 'Any unsaved changes you have made will be lost.  Are you sure you want to continue?';
            var btns = [{
                val: false,
                label: 'Stay in configuration mode',
                btnClass: 'btn-outline-secondary'
            }, {
                val: true,
                label: 'EXIT',
                btnClass: 'btn-primary'
            }];

            var dlg = ($scope.forms.configForm.$dirty) ? 
            kohaDlg.dialog({
                message: msg,
                heading: title,
                buttons: btns
            }).result
            : $q.when(true);

            dlg.then(function (result) {
                    if (result) {
                        $state.go('staff');
                    }
                });
        };

        var MarcRecordDocumentationUri = "//github.com/liblime/marcrecord-js";
        $scope.userjs_help = {
            detail: '<div><dl>'+
            '<dt>element</dt><dd>jQuery object holding the DOM element containing all rendered bibliographic data.</dd>'+
            '<dt>bib</dt><dd>bib object, including bib.marc, a <a target="_blank" href="'+MarcRecordDocumentationUri+'">MarcRecord</a> instance for accessing MARC data.</dd>'+
            '<dt>holdings</dt><dd>holdings object, containing item (and optionally MFHD) data.  See documentation for details.</dd>'+
            '</dl></div>'
            ,
            results: '<div><dl>'+
            '<dt>element</dt><dd>jQuery object holding the DOM element containing all rendered bibliographic data.</dd>'+
            '<dt>bib</dt><dd>bib object, including bib.marc, a <a target="_blank" href="'+MarcRecordDocumentationUri+'">marcrecord-js</a> instance for accessing MARC data.</dd>'+
            '</dl></div>'
        }

    }])
        .controller('AddProductCtrl', ["$scope", "uploadProductService", function ($scope, uploadProductService) {
            uploadProductService.process($scope);

        }])
        .controller('AddProductModalCtrl', ["$scope", "uploadProductService", "$uibModalInstance", "bib", function($scope, uploadProductService,$uibModalInstance,bib){
            uploadProductService.process($scope,$uibModalInstance,bib);
        }])
        .controller('DeleteProductModalCtrl', ["$scope", "configService", "$uibModalInstance", "alertService", "deletions", function($scope, configService,$uibModalInstance,alertService,deletions){
            $scope.deletionRecords = deletions;
            $scope.input = {};
            $scope.runDelete = function(){
                if($scope.input.deleteConfirm == null || $scope.input.deleteConfirm.toLowerCase() != "delete"){
                    alert("Please type in 'Delete' to confirm the DLSO deletion(s)");
                    return;
                }
                
                $("#productDeleteModal").block({ message: '<h1><i class="icon-spinner icon-spin icon-2x"></i>Processing</h1>'  });
                var passed = 0;
                var failed = 0;
                //Ajax to do delete on each deletion record
                for(var dIndex = 0; dIndex < deletions.length;dIndex++){
                    $.ajax({
                        type: "DELETE",
                        url:  "/api/dlso/"+deletions[dIndex].uuid,
                        async: false,
                        cache: false,
                        contentType: "application/json",
                        headers: configService.getXSRFHeader(),
                        success: function (data) {
                            passed++;
                        },
                        error: function () {
                            failed++;
                        }
                    });
                
                }
                
                if(failed > 0){
                    alertService.add({
                    type: "error",
                    msg: failed + " DLSO deletions failed, and " + passed + " DLSOs deletions succeeded."
                    });
                }else{
                    alertService.add({
                    type: "success",
                    msg: passed + " DLSOs were deleted"
                    });
                }
                $uibModalInstance.close();
                $("#productDeleteModal").unblock();

            };
        }])
        .controller('PasswdDlgCtrl', ["$scope", "userService", "configService", "$uibModalInstance", "alertService", function ($scope, userService, configService, $uibModalInstance, alertService) {

            $scope.user = userService;
            $scope.config = configService;

            $scope.changePass = function (password) {
                $scope.loginFailed = false;
                if(password.newpw !== password.again || password.newpw.length < $scope.config.minPasswordLength){
                    return;
                }
                var promise = $scope.user.changePass(password.current, password.newpw);
                promise.then(function () {
                    $scope.$emit('passwordChanged');
                    alertService.add({
                        type: "success",
                        msg: "Password successfully changed."
                    });
                    $uibModalInstance.close();
                }, function () {
                    console.warn("then() failed in PasswdDlgCtrl");
                    $scope.loginFailed = true;
                });
            };
        }])

.controller('Error403Ctrl', ["$scope", "configService", "$sce", function($scope, configService, $sce) {
    $scope.wording403 = $sce.trustAsHtml(configService.opacConfig.layout.error403_html);
    if (!$scope.wording403) {
        $scope.wording403 = '403 Not Authorized';
    }
}])
.controller('Error404Ctrl', ["$scope", "configService", "$sce", function($scope, configService, $sce) {
    $scope.wording404 = $sce.trustAsHtml(configService.opacConfig.layout.error404_html);
    if (!$scope.wording404) {
        $scope.wording404 = '404 Not Found';
    }
}])
.controller('UserSocialCtrl', ["$scope", "socialMediaAccounts", "kwSocialMediaSvc", function($scope, socialMediaAccounts, kwSocialMediaSvc) {
    $scope.methods = kwSocialMediaSvc.getLinkedMethods(socialMediaAccounts);
    $scope.linkAccount = kwSocialMediaSvc.linkAccount;
}])

.controller('UserSocialBindCtrl', ["$scope", "$stateParams", "$state", "kwSocialMediaSvc", function($scope, $stateParams, $state, kwSocialMediaSvc) {
    $scope.type = $stateParams.type;

    if ($stateParams.error_reason) {
        console.log("Social media OAuth2 request canceled");
        $state.go('me.social');
    }
    else if ($stateParams.code) {
        kwSocialMediaSvc.linkFinalize($stateParams.type, $stateParams.code).then(function(rv) {
            $state.go('me.social');
        }, function(err) {
            $state.go('me.social');
        });
    }
}])
.controller('UserSocialLoginCtrl', ["$scope", "$stateParams", "$state", "kwSocialMediaSvc", "configService", function($scope, $stateParams, $state, kwSocialMediaSvc, configService) {
    $scope.type = $stateParams.type;
    console.log("COMPLETING LOGIN CODE=" + $stateParams.code);

    if ($stateParams.error_reason) {
        console.log("Social media OAuth2 request canceled");
        $state.go('home');
    }
    else if ($stateParams.code) {
        kwSocialMediaSvc.authFinalize($stateParams.type, $stateParams.code).then(function(rv) {
            configService.runUserJs();
            $state.go('home');
        }, function(err) {
            if (err === '')
                $state.go('home');
            $scope.error = err;
        });
    }
}])

.controller('EResourceCtrl', ["$scope", "$state", function($scope, $state) {
    $scope.meta = {
        mode: 'search',
        type: 'articles',
        idType: 'issn',
        idValue: '',
        searchType: 'starts',
    };

    if ($state.current.name == 'eresource.journals')
        $scope.meta.mode = 'journals';
    else if ($state.current.name == 'eresource.collections')
        $scope.meta.mode = 'collections';
    else if ($state.current.name == 'eresource.collection-entries')
        $scope.meta.mode = 'collections';

    $scope.inSearchState = function() {
        return ($state.current.name == 'eresource.search');
    }

    $scope.go = function(newState) {
        newState = 'eresource.' + newState;
        //if (newState != $state.current.name)
            $state.go(newState,{},{reload: true, inherit: false});
    };

    $scope.flattenLinks = function(entry) {
        (entry.links || []).forEach(function(link) {
            entry['href_' + link.rel] = link.href;
        });
    };

    $scope.mapKbEntry = function(entry) {
        $scope.flattenLinks(entry);

        angular.forEach(entry, function(val, key) {
            if (key.substr(0,3) == 'kb:')
                entry[key.substr(3)] = val;
        });

        entry.url = entry.href_via;                     // Exact equivalent
        entry.collection_url = entry.href_canonical;    // Exact equivalent
        // TODO entry.linker_url, maybe get via openUrl?
        return entry;

    };
        

    $scope.mergeResults = function(results) {
        var merged = [];
        var byIndex = {};
        results.forEach(function(r) {
            r.displayIssn = r.issn || r.eissn;
            r.mergedOptions = [{
                url: r.url,
                content: r.content,
                coverage: r.coverage,
                linkerurl: r.linkerurl,
                article_url: r.article_url,
                coverage_enum: r.coverage_enum,
                collection_name: r.collection_name,
                collection_url: r.collection_url,
                isDirect: r.isDirect,
            }];

            // TODO accept full range - fulltext, selectedft, abstracts, indexed, print, ebook
            r.fulltext_count = (/fulltext/.test(r.coverage) ? 1 : 0);
            r.abstract_count = (/abstract/.test(r.coverage) ? 1 : 0);
            r.ebook_count = (/ebook/.test(r.coverage) ? 1 : 0);

            var key = (r.title || '') + '/' + (r.displayIssn || '');
            if (byIndex[key]) {
                byIndex[key].mergedOptions.push(r.mergedOptions[0]);
                byIndex[key].fulltext_count += r.fulltext_count;
                byIndex[key].abstract_count += r.abstract_count;
                byIndex[key].ebook_count += r.ebook_count;
            }
            else {
                byIndex[key] = r;
                merged.push(r);
            }
        });

        results.forEach(function(r) {
            if (r.fulltext_count>0)
                r.content_count = 'Full text: ' + r.fulltext_count;
            else if (r.ebook_count > 0)
                r.content_count = 'eBook: ' + r.ebook_count;
            else if (r.abstract_count > 0)
                r.content_count = 'Abstract: ' + r.abstract_count;
            else
                r.content_count = '';

            r.type = 'eJournal'; //FIXME
        });

        return merged;
    };

    $scope.copyCitation = function() {
        var el = $('#eresource-cite');
        el.select();
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
    };

    $scope.getCitation = function(rec) {
        var lines = [];
        if (rec.title) lines.push(rec.title);

        if (rec.publisher) lines.push(rec.publisher);

        if (rec.issn) lines.push(rec.issn);
        else if (rec.eissn) lines.push(rec.eissn);

        return lines.join("\n");
    };

}])

.controller('EResourceSearchCtrl', ["$scope", "$stateParams", "$state", function($scope, $stateParams, $state) {
    $scope.rft = {};
    $scope.valid = true;

    $scope.clear = function() {
        $scope.rft.atitle = '';
        $scope.rft.jtitle = '';
        $scope.rft.btitle = '';
        $scope.rft.series = '';
        $scope.rft.au = '';
        $scope.rft.aucorp = '';
        $scope.rft.date = '';
        $scope.rft.volume = '';
        $scope.rft.issue = '';
        $scope.rft.spage = '';
        $scope.rft.epage = '';
        $scope.rft.isbn = '';
        $scope.rft.issn = '';

        $scope.meta.idType = 'issn';
        $scope.meta.searchType = 'starts';
        $scope.meta.idValue = '';
    };
    $scope.clear();

    if ($stateParams.query) {
        try {
            angular.extend($scope.rft, JSON.parse($stateParams.query));
        } catch (e) {
            console.log("Bad JSON " + $stateParams.query);
        }

    }
    if ($stateParams.meta) {
        try {
            angular.extend($scope.meta, JSON.parse($stateParams.meta));
        }
        catch (e) {
            console.log("Bad JSON " + $stateParams.meta);
        }
    }


    $scope.search = function() {
        var rft = {};
        angular.forEach($scope.rft, function(val, key) {
            if (val !== '' && val !== null && val !== undefined)
                rft[key] = val;
        });
        if ($scope.meta.idValue)
            rft[$scope.meta.idType] = $scope.meta.idValue;

        var meta = angular.extend({},$scope.meta, {
            prevState: 'eresource.search',
            prevStateName: 'Search',
        });

        $state.go('eresource.search-results', {query: JSON.stringify(rft), meta: JSON.stringify(meta)});
    };

/*
    $scope.$watch('rft', function(newVal) {
        $scope.valid = false;
        if (!newVal) return;
        if (newVal.title || newVal.btitle || newVal.jtitle || newVal.pub
            || newVal.issn || newVal.eissn || newVal.isbn || newVal.oclcnum)
            $scope.valid = true;
    }, true);*/
}])

.controller('EResourceSearchResultsCtrl', ["$scope", "$state", "$stateParams", "configService", "kwApi", "loading", function($scope, $state, $stateParams, configService, kwApi, loading) {
    $scope.rft = JSON.parse($stateParams.query || '{}');
    $scope.meta = JSON.parse($stateParams.meta || '{}');
    $scope.expanded = false;
    $scope.order = {field: 'title', reverse: false};

    $scope.requestForm = configService.OCLCSearchRequestForm;
    var gen_article_link = function(url) {
    //console.dir(url);
    var hq = url.split('?');
        if (hq.length == 1)
            return url;
    var qp = hq[1].split('&');
    var filtered = [];
    var has = {};
    qp.forEach(function(el) {
        var fv = el.split('=');
        has[fv[0]] = true;
        if (fv[0] == 'rft.content')
        fv[1] = 'fulltext%2Cprint';
        filtered.push(fv.join('='));
    });
    if (!has['rfr_id'])
        filtered.push('rfr_id=info%2Fsid%3Aoclc.org%2FWCL');
    if (!has['rft.order_by'])
        filtered.push('rft.order_by=preference');
    
    hq[1] = filtered.join('&');
    var s = hq.join('?');
    //console.dir(s);
    return s;
    };

    $scope.reload = function() {
        var searchType;
        if ($scope.meta.type == 'ebooks') {
            searchType = $scope.meta.searchType;
            $scope.rft.content = 'ebook';
        }
        else if ($scope.meta.type == 'journals') {
            searchType = $scope.meta.searchType;
            $scope.rft.content = 'fulltext';
        }
        else if ($scope.meta.type == 'articles') {
            searchType = 'starts';
            $scope.rft.content = 'fulltext';
        }
        else {
            searchType = 'starts';
            $scope.rft.content = 'fulltext';
        }

        loading.wrap(kwApi.Worldcat.getOpenUrlResolve({
            query: JSON.stringify($scope.rft),
            search_type: searchType,
        }).$promise).then(function(results) {
        (results || []).forEach(function(r) {
        if ($scope.meta.type == 'articles' && r.linkerurl) {
            r.article_url = gen_article_link(r.linkerurl);
        }
        });
            $scope.results = $scope.mergeResults(results);
            if ($scope.results.length == 1 && $scope.results[0].isDirect) {
                $scope.expandResult($scope.results[0]);
            }

        });
    };

    $scope.reload();

    $scope.expandResult = function(r) {
        if (r === $scope.selected) {
            $scope.expanded = false;
            $scope.selected = null;
            $scope.selected._selected = false;
        }
        else {
            if ($scope.selected)
                $scope.selected._selected = false;
            else 
                $scope.$emit('eResourceExpanded');

            $scope.expanded = true;
            $scope.selected = r;
            $scope.selected._selected = true;
            if (!$scope.selected.citation)
                $scope.selected.citation = $scope.getCitation($scope.selected);
        }
    };
    
    $scope.closeResult = function() {
        if ($scope.selected)
            $scope.selected._selected = false;

        $scope.expanded = false;
        $scope.selected = null;
    };

    $scope.prevStateName = $scope.meta.prevStateName || 'Search';
    $scope.prevState = $scope.meta.prevState || 'eresource.search';

    $scope.returnToPrevious = function() {
        var rft = {};
        angular.forEach($scope.rft, function(val, key) {
            if (val !== '' && val !== null && val !== undefined)
                rft[key] = val;
        });
        $state.go($scope.prevState, {query: JSON.stringify(rft), meta: JSON.stringify($scope.meta)});
    };

}])

.controller('EResourceJournalsCtrl', ["$scope", "$stateParams", "$state", "kwApi", "loading", function($scope, $stateParams, $state, kwApi, loading) {
    $scope.query = "";
    $scope.meta = {};

    if ($stateParams.query) {
        $scope.query = $stateParams.query;
    }

    if ($stateParams.meta) {
        try {
            angular.extend($scope.meta, JSON.parse($stateParams.meta));
        }
        catch (e) {
            console.log("Bad JSON " + $stateParams.meta);
        }
    }

    $scope.browse = function(prefix) {
        $state.go('eresource.journals', {query: prefix.label});
    };

    $scope.expanded = false;
    $scope.order = {field: 'title', reverse: false};
    $scope.sublinks = [];

    $scope.pager = {
        totalResults: 0,
        itemsPerPage: 25,
        page: 1,
    };
    
    $scope.reload = function() { 
        var q = {
            itemsPerPage: $scope.pager.itemsPerPage,
            startIndex: (($scope.pager.page-1) * $scope.pager.itemsPerPage + 1),
            content: 'fulltext',
            'search-type': 'atoz',
        };

        if ($stateParams.query) 
            q.title = '"' + $stateParams.query + '*"';

        loading.wrap(kwApi.Worldcat.getEntriesSearch({
            query: JSON.stringify(q),
        }).$promise).then(function(data) {
            $scope.data = data;
            $scope.pager.totalResults = $scope.data['os:totalResults'];
            $scope.results = $scope.data.entries.map($scope.mapKbEntry);
            $scope.results = $scope.mergeResults($scope.results);

            if ($scope.sublinks.length == 0) {
                $scope.data.links.forEach(function(link) {
                    if (link.rel != 'atoz') return;
                    if (link.length == 0) return;

                    $scope.sublinks.push({
                        value: link.title,
                        label: (
                            link.title.charAt(0).toUpperCase() + 
                            link.title.substring(1, link.title.length-1)),
                        title: "" + link.length + " titles",
                    });
                });
            }
        });
    };

    $scope.reload();

    $scope.expandResult = function(r) {
        if (r === $scope.selected) {
            $scope.expanded = false;
            $scope.selected = null;
            $scope.selected._selected = false;
        }
        else {
            if ($scope.selected)
                $scope.selected._selected = false;
            else 
                $scope.$broadcast('eResourceExpanded');

            $scope.expanded = true;
            $scope.selected = r;
            $scope.selected._selected = true;
            if (!$scope.selected.citation)
                $scope.selected.citation = $scope.getCitation($scope.selected);
        }
    };
    
    $scope.closeResult = function() {
        if ($scope.selected)
            $scope.selected._selected = false;

        $scope.expanded = false;
        $scope.selected = null;
    };

    $scope.prevStateName = $scope.meta.prevStateName || 'Search';
    $scope.prevState = $scope.meta.prevState || 'eresource.search';

    $scope.returnToPrevious = function() {
        var rft = {};
        angular.forEach($scope.rft, function(val, key) {
            if (val !== '' && val !== null && val !== undefined)
                rft[key] = val;
        });
        $state.go($scope.prevState, {query: JSON.stringify(rft), meta: JSON.stringify($scope.meta)});
    };

}])

.controller('EResourceCollectionsCtrl', ["$scope", "$stateParams", "$state", "kwApi", "loading", function($scope, $stateParams, $state, kwApi, loading) {
    $scope.query = "";
    $scope.meta = {};

    $scope.alphaChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');

    if ($stateParams.meta) {
        try {
            angular.extend($scope.meta, JSON.parse($stateParams.meta));
        }
        catch (e) {
            console.log("Bad JSON " + $stateParams.meta);
        }
    }

    $scope.browse = function(prefix) {
        $state.go('eresource.collections', {query: prefix});
    };

    $scope.expanded = false;
    $scope.order = {field: 'title', reverse: false};

    $scope.pager = {
        totalResults: 0,
        itemsPerPage: 25,
        page: 1,
    };
    
    $scope.reload = function() { 
        var q = {
            itemsPerPage: $scope.pager.itemsPerPage,
            startIndex: (($scope.pager.page-1) * $scope.pager.itemsPerPage + 1),
        };

        if ($stateParams.query) 
            q.title = '"' + $stateParams.query + '*"';

        loading.wrap(kwApi.Worldcat.getCollectionsSearch({
            query: JSON.stringify(q),
        }).$promise).then(function(data) {
            $scope.data = data;
            $scope.pager.totalResults = $scope.data['os:totalResults'];
            $scope.results = $scope.data.entries.map($scope.mapKbEntry);
        });
    };

    $scope.reload();

    $scope.expandResult = function(r) {
        if (r === $scope.selected) {
            $scope.expanded = false;
            $scope.selected = null;
            $scope.selected._selected = false;
        }
        else {
            if ($scope.selected)
                $scope.selected._selected = false;
            else 
                $scope.$emit('eResourceExpanded');

            $scope.expanded = true;
            $scope.selected = r;
            $scope.selected._selected = true;
        }
    };
    
    $scope.closeResult = function() {
        if ($scope.selected)
            $scope.selected._selected = false;

        $scope.expanded = false;
        $scope.selected = null;
    };

    $scope.prevStateName = $scope.meta.prevStateName || 'Search';
    $scope.prevState = $scope.meta.prevState || 'eresource.search';

    $scope.returnToPrevious = function() {
        var rft = {};
        angular.forEach($scope.rft, function(val, key) {
            if (val !== '' && val !== null && val !== undefined)
                rft[key] = val;
        });
        $state.go($scope.prevState, {query: JSON.stringify(rft), meta: JSON.stringify($scope.meta)});
    };

}])

.controller('EResourceCollectionDetailCtrl', ["$scope", "$state", "kwApi", "loading", function($scope, $state, kwApi, loading) {


    $scope.collectionData = angular.copy($scope.cannedCollectionDetailResults); // TODO 

    $scope.reload = function() { 
        var q = {
            itemsPerPage: 5,
            startIndex: 1,
            collection_uid: $scope.selected.collection_uid,
            provider_uid: $scope.selected.provider_uid,
            content: 'fulltext',
        };

        loading.wrap(kwApi.Worldcat.getEntriesSearch({
            query: JSON.stringify(q),
        }).$promise).then(function(data) {
            $scope.collectionData = data;
            $scope.collectionEntries = $scope.collectionData.entries.map($scope.mapKbEntry);
            $scope.titlesInCollection = $scope.collectionData['os:totalResults'];
        });
    };

    $scope.$watch('selected', function(newVal) {
        if (newVal) 
            $scope.reload();
    }, true);

    $scope.viewCollectionTitles = function() {
        $state.go('eresource.collection-entries', {
            collection: $scope.selected.collection_uid,
            provider: $scope.selected.provider_uid,
            count: $scope.selected.selected_entries, //$scope.titlesInCollection,
        });
    };
}])

.controller('EResourceCollectionEntriesCtrl', ["$scope", "$stateParams", "kwApi", "loading", function($scope, $stateParams, kwApi, loading) {

    $scope.expanded = false;
    $scope.order = {field: 'title', reverse: false};

    $scope.pager = {
        totalResults: 0,
        itemsPerPage: 25,
        page: 1,
    };

    $scope.reload = function() { 
        var q = {
            collection_uid: $stateParams.collection,
            provider_uid: $stateParams.provider,
            itemsPerPage: $scope.pager.itemsPerPage,
            startIndex: (($scope.pager.page-1) * $scope.pager.itemsPerPage + 1),
        };

        loading.wrap(kwApi.Worldcat.getEntriesSearch({
            query: JSON.stringify(q),
        }).$promise).then(function(data) {
            $scope.data = data;
            $scope.pager.totalResults = $scope.data['os:totalResults'];
            $scope.results = $scope.data.entries.map($scope.mapKbEntry);
            $scope.results = $scope.mergeResults($scope.results);
        });
    };

    $scope.reload();

    $scope.expandResult = function(r) {
        if (r === $scope.selected) {
            $scope.expanded = false;
            $scope.selected = null;
            $scope.selected._selected = false;
        }
        else {
            if ($scope.selected)
                $scope.selected._selected = false;
            else 
                $scope.$emit('eResourceExpanded');

            $scope.expanded = true;
            $scope.selected = r;
            $scope.selected._selected = true;
            if (!$scope.selected.citation)
                $scope.selected.citation = $scope.getCitation($scope.selected);
        }
    };
    
    $scope.closeResult = function() {
        if ($scope.selected)
            $scope.selected._selected = false;

        $scope.expanded = false;
        $scope.selected = null;
    };

}])

;


})();
