(function(){
'use strict';

var module = angular.module('kohapac.templates',[]);

angular.module('kohapac.templates').run(['syncTemplateCache', function(syncTemplateCache) {
  'use strict';

  syncTemplateCache.put('app/partials/pub-masthead-search.html',
    "<!-- PRELOAD -->\n" +
    "<form name=\"searchform\" class=\"searchform form-inline\" class=\"form\" ng-submit=\"mastheadSearch($event)\" novalidate>\n" +
    "\n" +
    "    <div class=\"row\" ng-class=\"opt.visible + '-search'\">\n" +
    "        <div class=\"field-select col\" ng-class=\"{'col-sm-3 col-sm-offset-3': (opt.visible=='simple'), 'col-sm-3': (opt.visible!='simple')}\">\n" +
    "            <div ng-show=\"showFieldSelect[opt.visible]\">\n" +
    "                <selectize config=\"fieldSelect.selectizeConfig\" ng-model=\"query.field\" options=\"fieldSelect.options\" data-aria-label=\"Choose search index\" >\n" +
    "                </selectize>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col col-sm-3 hidden-xs\" ng-show=\"opt.visible=='prefilter'\">\n" +
    "            <div class=\"prefilters\">\n" +
    "                <div class=\"prefilter\" ng-repeat=\"filter in orderedSelects\">\n" +
    "                    <span class=\"dynamic-select\">\n" +
    "                        <selectize config=\"filter.selectizeConfig\" options=\"filter.options\" ng-model=\"filter.value\" class=\"prefilter-select\" data-aria-label=\"Limit search by {{filter.label}}\">\n" +
    "                        </selectize>\n" +
    "                    </span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"query-terms col col-sm-6\" >\n" +
    "            <div class=\"input-group\"  uib-dropdown keyboard-nav is-open=\"opt.showFqDropdown\">\n" +
    "\n" +
    "                <input class=\"form-control\" ng-click=\"opt.menuOnFocus && onSimpleSearchFocus()\" ng-model=\"query.q\" type=\"text\" placeholder=\"Enter search terms - use quotes for phrases\" kw-clearable-input aria-label=\"search terms\" id=\"publicInputQueryText\">\n" +
    "                <button type=\"submit\" class=\"btn btn-outline-secondary searchsubmit\" aria-label=\"submit search\">\n" +
    "                    <i class=\"bi bi-search\"></i>\n" +
    "                </button>\n" +
    "                <ul uib-dropdown-menu class=\"simple-search\" role=\"menu\" aria-labelledby=\"simple-btn-keyboard-nav\">\n" +
    "                    <li role=\"menuitem\" ng-repeat=\"opt in simpleFilterOptions\">\n" +
    "                        <a href ng-click=\"simpleSearch(opt.fq)\">\n" +
    "                            <span class=\"bg\">Search for</span>\n" +
    "                            <span class=\"query-term\">{{query.q}}</span>\n" +
    "                            <span class=\"bg\">in</span>\n" +
    "                            <span class=\"query-limit\">{{opt.label}}</span>\n" +
    "                        </a>\n" +
    "                    </li>\n" +
    "                </ul>\n" +
    "            </div>\n" +
    "            <a href class=\"masthead-search-switch hidden-xs btn btn-outline-secondary\" ng-show=\"showSwitcher\" ng-click=\"toggleView()\" role=\"button\">\n" +
    "                <span ng-show=\"opt.visible=='simple'\">&nbsp;More Options</span>\n" +
    "                <span ng-show=\"opt.visible=='prefilter'\">Fewer Options</span>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "</form>\n"
  );

  syncTemplateCache.put('app/partials/masthead/infomart-demo.html',
    "<header id=\"header\" ng-hide=\"hideHeaderFooter\">\n" +
    "  <div class=\"container-fluid\">\n" +
    "    <div class=\"row\" role=\"navigation\" ng-class=\"{'tracing-navbar': root.tracing, 'proxy-navbar': user.origin_userid}\">\n" +
    "      <kw-staff-nav class=\"col-sm-6\">\n" +
    "      </kw-staff-nav>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "<div ng-cloak ng-if=\"!user.has_staff_masthead || masthead.public_view\">\n" +
    "  <div id=\"header\" class=\"infomart-masthead-navbar infomart-masthead-header\" aria-label=\"Site header\">\n" +
    "    <div class=\"infomart-masthead-container-fluid\">  \n" +
    "      <div class=\"infomart-masthead-navbar-header clearfix\">\n" +
    "        <div style=\"height: 83px\" id=\"block-waldo-branding\" class=\"infomart-masthead-block-system-branding-block\">\n" +
    "          <a href=\"/\" title=\"Home\" rel=\"home\" class=\"infomart-masthead-site-logo\">\n" +
    "            <img src=\"https://proconsort.com/profiles/proconsort/themes/waldo/bwaldo-logo.png\" alt=\"Home\">\n" +
    "          </a>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div id=\"bs-waldo-header-collapse\">\n" +
    "        <nav role=\"navigation\" aria-labelledby=\"block-waldo-user-authorization-menu\" id=\"block-waldo-user-authorization\" class=\"infomart-masthead-menu-user-authorization\">\n" +
    "          <ul class=\"nav infomart-masthead-navbar-authorization\">\n" +
    "            <li ng-show=\"user.loggedin\">\n" +
    "              <a class=\"infomart-link\" target=\"_self\" href=\"https://proconsort.com/index.php/user/logout\">Log out</a>\n" +
    "            </li>\n" +
    "            <li ng-show=\"!user.loggedin\">\n" +
    "              <a class=\"infomart-link\" target=\"_self\" href=\"https://proconsort.com/user/login\">Log in</a>\n" +
    "            </li>\n" +
    "            <li ng-show=\"!user.loggedin\">\n" +
    "              <a class=\"infomart-link\" target=\"_self\" href=\"https://proconsort.com/join\">Join Us</a>\n" +
    "            </li>\n" +
    "          </ul>\n" +
    "          <span ng-show=\"0\" class=\"global-login btn\" kw-login-button></span>\n" +
    "        </nav>\n" +
    "        <nav role=\"navigation\" aria-labelledby=\"block-waldo-main-menu-menu\" id=\"block-waldo-main-menu\" class=\"infomart-masthead-navigation infomart-masthead-menu-main\">\n" +
    "          <ul class=\"infomart-masthead-nav infomart-masthead-navbar-nav\">\n" +
    "            <li>\n" +
    "              <a class=\"infomart-link\" target=\"_self\" href=\"https://proconsort.com/\">Home</a>\n" +
    "            </li>\n" +
    "            <li>\n" +
    "              <a class=\"infomart-link\" href=\"https://proconsort.com/about\" target=\"_blank\">About Us</a>\n" +
    "            </li>\n" +
    "            <li>\n" +
    "              <a class=\"infomart-link\" href=\"/\">Products &amp; Services</a>\n" +
    "            </li>\n" +
    "            <li>\n" +
    "              <a ng-show=\"user.loggedin\" class=\"infomart-link\" target=\"_self\" href=\"https://proconsort.com/marketplace\">Marketplace</a>\n" +
    "            </li>\n" +
    "            <li class=\"infomart-carts-overview\" ng-controller=\"acqListsCartCtrl\">\n" +
    "              <span ng-show=\"user.loggedin && !user.is_infomart_vendor\" class=\"infomart-cart-orders-overview\" ng-click=\"goToPortalCart()\">\n" +
    "\t        <span class=\"infomart-shopping-cart-icon\"></span>\n" +
    "\t\t<sup class=\"infomart-cart-orders-count\">{{getPortalCartCount()}}</sup>\n" +
    "\t      </span>\n" +
    "          </ul>\n" +
    "        </nav>\n" +
    "        <nav ng-show=\"user.loggedin\" role=\"navigation\" aria-labelledby=\"block-waldo-account-menu-menu\" id=\"block-waldo-account-menu\" class=\"infomart-masthead-navigation infomart-masthead-menu-account\">\n" +
    "          <ul class=\"nav infomart-navbar-nav infomart-navbar-right\">\n" +
    "            <li ng-show=\"user.origin_userid\">\n" +
    "              <a href=\"https://proconsort.com/unmasquerade\" target=\"_self\" title=\"Switch back to {{user.origin_userid}}\" class=\"infomart-link\">Exit Proxy</a>\n" +
    "            </li>\n" +
    "            <li>\n" +
    "              <a target=\"_self\" href=\"https://proconsort.com/messages\" class=\"infomart-link\">Messages</a>\n" +
    "            </li>\n" +
    "            <li>\n" +
    "              <a href=\"https://proconsort.com/account\" target=\"_self\" class=\"infomart-link\">{{user.login_branch|displayName:'branch'}}</a>\n" +
    "            </li>\n" +
    "            <li>\n" +
    "              <a href=\"https://proconsort.com/user\" target=\"_self\" class=\"infomart-link\">{{user.details_data.firstname}} {{user.details_data.surname}}</a>\n" +
    "            </li>\n" +
    "          </ul>\n" +
    "        </nav>\n" +
    "      </div> <!-- bs-waldo-header-collapse -->\n" +
    "    </div> <!-- infomart-masthead-container-fluid -->\n" +
    "  </div> <!-- header -->\n" +
    "\n" +
    "  <!-- public masthead -->\n" +
    "  <div id=\"public-masthead\">\n" +
    "    <div class=\"masthead-body container-fluid\">\n" +
    "      <div class=\"row\">\n" +
    "        <div class=\"col-md-10 col-sm-9 public-search\" ng-controller=\"publicMastheadCtrl\">\n" +
    "          <div class=\"mastsearch-cnt\"> <!-- fixme: move controller to directive. -->\n" +
    "            <kw-pub-masthead-search></kw-pub-masthead-search>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\t<div class=\"col-md-2 col-sm-3 infomart-masthead-buttons\" ng-controller=\"acqListsCartCtrl\">\n" +
    "\t  <a href=\"/app/search\" class=\"btn btn-outline-secondary btn-sm infomart-masthead-button\">Advanced Search</a>\n" +
    "\t  <button ng-show=\"!user.is_infomart_vendor\" class=\"btn btn-outline-secondary btn-sm infomart-masthead-button\" ng-click=\"goToPortalCart()\">My Cart {{getPortalCartCount()}}</button>\n" +
    "\t  <!--a href=\"https://proconsort.com/carts-overview\" target=\"_self\" class=\"btn btn-outline-secondary btn-sm infomart-masthead-button\">My Cart</a -->\n" +
    "\t  <a href=\"/app/me/acqsubs\" class=\"btn btn-outline-secondary btn-sm infomart-masthead-button\">Active Orders</a>\n" +
    "\t</div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div> <!-- public-masthead -->\n" +
    "</div> <!-- !user.has_staff_masthead || masthead.public_view -->\n" +
    "\n" +
    "<div ng-cloak id=\"staff-masthead\" ng-show=\"user.has_staff_masthead && !masthead.public_view && !masthead.docked\" class=\"container-fluid\">\n" +
    "  <div class=\"row\">\n" +
    "    <div class=\"koha-logo col-md-2\">\n" +
    "      <a href=\"/app/staff\" >\n" +
    "        <img src=\"//cdn.bibliovation.com/c/Docs/Bibliovation-staffB-logo.png\" width=\"140\">\n" +
    "      </a>\n" +
    "    </div>\n" +
    "    <div class=\"search-cnt col-md-5\">\n" +
    "      <div id=\"resident-search\" bv-staff-mast-search>\n" +
    "        <div uib-tabset type=\"pills\" justified=\"true\">\n" +
    "          <uib-tab ng-repeat=\"tab in tabs\" heading=\"{{tab.title}}\" select=\"tabSelected($index)\" deselect=\"tabDeselected(tab.id)\"> <div ng-include=\"tab.tmpl\" ng-class=\"'mastTab' + tab.id\"></div> </uib-tab>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"maplaunchg col-md-1 col-md-offset-4\" ng-if=\"config.geospatialSearch\">\n" +
    "      <button class='btn btn-primary kohaMapBtn' ng-click=\"toggleMap()\"><i class='icon icon-large icon-globe'></i>Map</button>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "</header>\n"
  );

  syncTemplateCache.put('app/partials/masthead/default.html',
    "    <header id=\"header\" ng-hide=\"hideHeaderFooter\">\n" +
    "      <div id=\"topbar\">\n" +
    "          <div class=\"topbar-container container-fluid\">\n" +
    "            <div class=\"row\" role=\"navigation\" ng-class=\"{'tracing-navbar': root.tracing, 'proxy-navbar': user.origin_userid}\">\n" +
    "\n" +
    "                <div class=\"nav-container col-6\">\n" +
    "                    <kw-staff-nav></kw-staff-nav>\n" +
    "                </div>\n" +
    "\n" +
    "            <div class=\"col-xs-12 col-sm-6 text-end\">\n" +
    "                  <div class=\"login\">\n" +
    "                    <span ng-if=\"user.is_staff\" class=\"switch-branch\">\n" +
    "                      {{user.login_branch|displayName:'branch'}}\n" +
    "                    </span>\n" +
    "                    <span class=\"global-login float-end\">\n" +
    "                        <app-login-button></app-login-button>\n" +
    "                    </span>\n" +
    "\n" +
    "                    <span class=\"proxy-flag\" ng-if=\"user.origin_userid\"> (proxy session from {{user.origin_userid}}) </span>\n" +
    "                    <span class=\"tracing-flag\" ng-if=\"root.tracing\"> (TRACING ENABLED <button class=\"btn btn-sm btn-outline-secondary\" ng-click=\"root.setApiTracing(false)\">Stop</button>) </span>\n" +
    "\n" +
    "                    <span class=\"cart-link\" ng-if=\"config.opacbookbag && cart.cartCount()\">\n" +
    "                      <button type=\"button\" class=\"btn btn-outline-secondary btn-sm\" ng-click=\"cartDlgOpen()\">\n" +
    "                        <i class=\"bi bi-bag-check\"></i> <span class=\"badge bg-secondary\">{{cart.cartCount()}}</span></button>\n" +
    "                    </span>\n" +
    "\n" +
    "                    <span ng-if=\"config.AcqLists && user.loggedin\">\n" +
    "                      <a id=\"acqlist-button\" class='btn btn-outline-secondary' ui-sref=\"me.acqlists_switch\"><i ng-class=\"config.AcqList.icons.acqlists\"></i> {{(config.AcqList.wording.button || 'Acq Lists') | ucfirst}}</a>\n" +
    "                    </span>\n" +
    "\n" +
    "                    <span class=\"logout-links\">\n" +
    "                      <span id=\"{{'logout-xlink-'+opt.id}}\" ng-repeat=\"opt in config.logoutLinks\">\n" +
    "                        <span ng-show=\"user.loggedin || opt.always\">| <a href=\"{{opt.url}}\" ng-click=\"user.logout()\" target=\"{{opt.target}}\">{{opt.display}}</a>\n" +
    "                        </span>\n" +
    "                      </span>\n" +
    "                    </span>\n" +
    "                  </div><!--//login-->\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"usercontent-container\">\n" +
    "        <div class=\"usercontent\">\n" +
    "          <div id=\"header-html\" ng-if=\"!user.is_staff\" ng-bind-html=\"config.layout.header_html\"></div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div id=\"masthead\" class=\"masthead-container\">\n" +
    "\n" +
    "        <!-- public masthead -->\n" +
    "        <div id=\"public-masthead\" ng-show=\"!user.is_staff || masthead.public_view\">\n" +
    "          <div class=\"masthead-body container-fluid\">\n" +
    "            <div class=\"row\">\n" +
    "              <div class=\"col-md-4 logo-cnt\">\n" +
    "                <div id=\"masthead-logo\" class=\"usercontent\" ng-bind-html=\"config.layout.logo_html\"></div>\n" +
    "              </div>\n" +
    "              <div class=\"col-md-8 public-search\" ng-controller=\"publicMastheadCtrl\">\n" +
    "                <div class=\"mastsearch-cnt\"> <!-- fixme: move controller to directive. -->\n" +
    "                  <kw-pub-masthead-search bv-async-onready-await=\"pub-masthead-ready\" bvao-name=\"masthead\"></kw-pub-masthead-search>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"text-center\">\n" +
    "            <div class=\"nav-container navbar-default\">\n" +
    "              <app-public-nav></app-public-nav>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "        </div> <!-- /#public-masthead -->\n" +
    "\n" +
    "\n" +
    "        <!-- staff masthead -->\n" +
    "        <div id=\"staff-masthead\" ng-show=\"user.is_staff && !masthead.public_view && !masthead.docked && user.can({catalogue:{access:'masthead'}})\" class=\"container-fluid\">\n" +
    "          <div class=\"row\">\n" +
    "            <div class=\"koha-logo col-md-2\">\n" +
    "              <a href=\"/app/staff\" >\n" +
    "                <img src=\"//cdn.bibliovation.com/c/Docs/Bibliovation-staffB-logo.png\" width=\"140\">\n" +
    "              </a>\n" +
    "            </div>\n" +
    "            <div class=\"search-cnt col-md-7\">\n" +
    "              <div id=\"resident-search\" bv-staff-mast-search>\n" +
    "                <div uib-tabset type=\"pills\" justified=\"true\" active=\"activeTabId\">\n" +
    "                  <div uib-tab index=\"tab.id\" ng-repeat=\"tab in tabs\" heading=\"{{tab.title}}\" select=\"tabSelected(tab.id, $index)\" deselect=\"tabDeselected(tab.id)\">\n" +
    "                    <div ng-include=\"tab.tmpl\" onload=\"tabLoaded(tab.id)\" ng-class=\"'mastTab' + tab.id\"></div>\n" +
    "                  </div>\n" +
    "                </div> <!-- uib-tabset -->\n" +
    "\n" +
    "              </div> <!-- #resident-search -->\n" +
    "            </div>\n" +
    "            <div class=\"maplaunchg col-md-1 col-md-offset-2\" ng-if=\"config.geospatialSearch\">\n" +
    "              <button class='btn btn-primary kohaMapBtn' ng-click=\"toggleMap()\"><i class='icon icon-large icon-globe'></i>Map</button>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "      </div> <!-- masthead -->\n" +
    "\n" +
    "    </header>\n" +
    "<!-- End Header and Nav -->\n" +
    "    <bv-async-onready bvao-name=\"masthead\" bvao-onready=\"userjs\" depth=\"1\"></bv-async-onready>\n"
  );

  syncTemplateCache.put('app/partials/public-nav.html',
    "<!-- PRELOAD -->\n" +
    "<nav>\n" +
    "\n" +
    "    <div class=\"navbar-header float-end\">\n" +
    "        <button type=\"button\" class=\"navbar-toggle\" ng-click=\"isNavCollapsed = !isNavCollapsed\">\n" +
    "            <span class=\"sr-only\">Toggle navigation</span>\n" +
    "            <span class=\"icon-bar\"></span>\n" +
    "            <span class=\"icon-bar\"></span>\n" +
    "            <span class=\"icon-bar\"></span>\n" +
    "        </button>\n" +
    "    </div>\n" +
    "    <div class=\"collapse navbar-collapse\" uib-collapse=\"isNavCollapsed\">\n" +
    "        <ul class=\"navbar-nav mx-auto\">\n" +
    "            <li><a ng-class=\"{active: viewId==''}\" class=\"homeLink\" href=\"/\">Home</a></li>\n" +
    "            <li ng-show=\"config.ext_svc.worldcat\"><a ng-class=\"{active: viewId==''}\" class=\"electronicResourcesLink\" ui-sref=\"eresource.search\">Electronic Resources</a></li>\n" +
    "            <li><a ng-class=\"{active: viewId=='search'}\" class=\"advSearchLink\" ui-sref=\"search\">Advanced Search</a></li>\n" +
    "            <li ng-show=\"config.NewTitlesAge\"><a href ng-class=\"{active: viewId=='new-titles'}\" class=\"new-titles-link\" ng-click=\"doNewTitles()\">New Titles</a></li>\n" +
    "            <li ng-show=\"config.OpacTopissue\"><a ng-class=\"{active: viewId=='most-popular'}\" class=\"most-pop-link\" href=\"/app/search/*:*?sort=popularity%20desc\">Most Popular</a></li>\n" +
    "            <li ng-if=\"config.CourseReserves\"><a ng-class=\"{active: viewId=='course-reserves'}\" class=\"course-reserves-link\" href=\"/app/course-reserves\">Course Reserves</a></li>\n" +
    "            <li ng-show=\"config.OpacAuthorities\"><a ng-class=\"{active: viewId=='authorities'}\" class=\"auth-search-link\" href=\"/app/authorities\">Browse</a></li>\n" +
    "            <li ng-show=\"config.TagsEnabled\"><a class=\"tag-cloud-link\" href ng-click=\"tagCloudDlgOpen()\">Tag Cloud</a></li>\n" +
    "            <li ng-show=\"config.dls.enabled && user.can({editcatalogue:{dlso:'upload'}},'*')\"><a ng-class=\"{active: viewId=='batchManager'}\" class=\"add-product-link\" href=\"/app/batchManager\">Add Content</a></li>\n" +
    "            <li class=\"saved-searches\" ng-show=\"user.loggedin && user.savedSearches.all.length\"><a class=\"saved-search-link\" href ng-click=\"savedSearchDlgOpen()\">Saved Searches</a></li>\n" +
    "            <li class=\"virtualshelves\" ng-show=\"config.virtualshelves\">\n" +
    "                <a href ng-click=\"listDlgOpen()\">\n" +
    "                    <span class=\"lists-label\">{{config.wording.lists | ucfirst}} </span>\n" +
    "                </a>\n" +
    "            </li>\n" +
    "            <li class=\"rfi\" uib-dropdown ng-show=\"user.loggedin && rfi_types\">\n" +
    "                <a class=\"rfi-link\" href uib-dropdown-toggle>RFI <span class=\"caret faded\"></span></a>\n" +
    "                <ul uib-dropdown-menu>\n" +
    "                    <li ng-repeat=\"code in rfi_types\">\n" +
    "                        <a href ng-click=\"rfiDlgOpen(code)\">{{code | displayName:'callslip_types'}}</a>\n" +
    "                    </li>\n" +
    "                </ul>\n" +
    "            </li>\n" +
    "            <li class=\"maplaunch\" ng-if=\"config.geospatialSearch\">\n" +
    "                <a href class='kohaMapBtn' ng-click=\"toggleMap()\"><i class='bi icon-large bi-globe-americas'></i>Map</a>\n" +
    "            </li>\n" +
    "        </ul>\n" +
    "    </div>\n" +
    "</nav>\n"
  );

  syncTemplateCache.put('app/partials/masthead/infomart.html',
    "<header id=\"header\" ng-hide=\"hideHeaderFooter\">\n" +
    "  <div class=\"container-fluid\">\n" +
    "    <div class=\"row\" role=\"navigation\" ng-class=\"{'tracing-navbar': root.tracing, 'proxy-navbar': user.origin_userid}\">\n" +
    "      <kw-staff-nav class=\"col-sm-6\">\n" +
    "      </kw-staff-nav>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "<div ng-cloak ng-if=\"!user.has_staff_masthead || masthead.public_view\">\n" +
    "  <div id=\"header\" class=\"infomart-masthead-navbar infomart-masthead-header\" aria-label=\"Site header\">\n" +
    "    <div class=\"infomart-masthead-container-fluid\">  \n" +
    "      <div class=\"infomart-masthead-navbar-header clearfix\">\n" +
    "        <div style=\"height: 83px\" id=\"block-waldo-branding\" class=\"infomart-masthead-block-system-branding-block\">\n" +
    "          <a href=\"/\" title=\"Home\" rel=\"home\" class=\"infomart-masthead-site-logo\">\n" +
    "            <img src=\"https://test.proconsort.com/profiles/proconsort/themes/waldo/bwaldo-logo.png\" alt=\"Home\">\n" +
    "          </a>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div id=\"bs-waldo-header-collapse\">\n" +
    "        <nav role=\"navigation\" aria-labelledby=\"block-waldo-user-authorization-menu\" id=\"block-waldo-user-authorization\" class=\"infomart-masthead-menu-user-authorization\">\n" +
    "          <ul class=\"nav infomart-masthead-navbar-authorization\">\n" +
    "            <li ng-show=\"user.loggedin\">\n" +
    "              <a class=\"infomart-link\" target=\"_self\" href=\"https://test.proconsort.com/index.php/user/logout\">Log out</a>\n" +
    "            </li>\n" +
    "            <li ng-show=\"!user.loggedin\">\n" +
    "              <a class=\"infomart-link\" target=\"_self\" href=\"https://test.proconsort.com/user/login\">Log in</a>\n" +
    "            </li>\n" +
    "            <li ng-show=\"!user.loggedin\">\n" +
    "              <a class=\"infomart-link\" target=\"_self\" href=\"https://test.proconsort.com/join\">Join Us</a>\n" +
    "            </li>\n" +
    "          </ul>\n" +
    "          <span ng-show=\"0\" class=\"global-login float-end\">\n" +
    "            <app-login-button></app-login-button>\n" +
    "          </span>\n" +
    "        </nav>\n" +
    "        <nav role=\"navigation\" aria-labelledby=\"block-waldo-main-menu-menu\" id=\"block-waldo-main-menu\" class=\"infomart-masthead-navigation infomart-masthead-menu-main\">\n" +
    "          <ul class=\"infomart-masthead-nav infomart-masthead-navbar-nav\">\n" +
    "            <li>\n" +
    "              <a class=\"infomart-link\" target=\"_self\" href=\"https://test.proconsort.com/\">Home</a>\n" +
    "            </li>\n" +
    "            <li>\n" +
    "              <a class=\"infomart-link\" href=\"https://test.proconsort.com/about\" target=\"_blank\">About Us</a>\n" +
    "            </li>\n" +
    "            <li>\n" +
    "              <a class=\"infomart-link\" href=\"/\">Products &amp; Services</a>\n" +
    "            </li>\n" +
    "            <li>\n" +
    "              <a ng-show=\"user.loggedin\" class=\"infomart-link\" target=\"_self\" href=\"https://test.proconsort.com/marketplace\">Marketplace</a>\n" +
    "            </li>\n" +
    "            <li class=\"infomart-carts-overview\" ng-controller=\"acqListsCartCtrl\">\n" +
    "              <span ng-show=\"user.loggedin && !user.is_infomart_vendor\" class=\"infomart-cart-orders-overview\" ng-click=\"goToPortalCart()\">\n" +
    "\t        <span class=\"infomart-shopping-cart-icon\"></span>\n" +
    "\t\t<sup class=\"infomart-cart-orders-count\">{{getPortalCartCount()}}</sup>\n" +
    "\t      </span>\n" +
    "          </ul>\n" +
    "        </nav>\n" +
    "        <nav ng-show=\"user.loggedin\" role=\"navigation\" aria-labelledby=\"block-waldo-account-menu-menu\" id=\"block-waldo-account-menu\" class=\"infomart-masthead-navigation infomart-masthead-menu-account\">\n" +
    "          <ul class=\"nav infomart-navbar-nav infomart-navbar-right\">\n" +
    "            <li ng-show=\"user.origin_userid\">\n" +
    "              <a href=\"https://test.proconsort.com/unmasquerade\" target=\"_self\" title=\"Switch back to {{user.origin_userid}}\" class=\"infomart-link\">Exit Proxy</a>\n" +
    "            </li>\n" +
    "            <li>\n" +
    "              <a target=\"_self\" href=\"https://test.proconsort.com/messages\" class=\"infomart-link\">Messages</a>\n" +
    "            </li>\n" +
    "            <li>\n" +
    "              <a href=\"https://test.proconsort.com/account\" target=\"_self\" class=\"infomart-link\">{{user.login_branch|displayName:'branch'}}</a>\n" +
    "            </li>\n" +
    "            <li>\n" +
    "              <a href=\"https://test.proconsort.com/user\" target=\"_self\" class=\"infomart-link\">{{user.details_data.firstname}} {{user.details_data.surname}}</a>\n" +
    "            </li>\n" +
    "          </ul>\n" +
    "        </nav>\n" +
    "      </div> <!-- bs-waldo-header-collapse -->\n" +
    "    </div> <!-- infomart-masthead-container-fluid -->\n" +
    "  </div> <!-- header -->\n" +
    "\n" +
    "  <!-- public masthead -->\n" +
    "  <div id=\"public-masthead\">\n" +
    "    <div class=\"masthead-body container-fluid\">\n" +
    "      <div class=\"row\">\n" +
    "        <div class=\"col-md-10 col-sm-9 public-search\" ng-controller=\"publicMastheadCtrl\">\n" +
    "          <div class=\"mastsearch-cnt\"> <!-- fixme: move controller to directive. -->\n" +
    "            <kw-pub-masthead-search></kw-pub-masthead-search>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\t<div class=\"col-md-2 col-sm-3 infomart-masthead-buttons\" ng-controller=\"acqListsCartCtrl\">\n" +
    "\t  <a href=\"/app/search\" class=\"btn btn-outline-secondary btn-sm infomart-masthead-button\">Advanced Search</a>\n" +
    "\t  <button ng-show=\"!user.is_infomart_vendor\" class=\"btn btn-outline-secondary btn-sm infomart-masthead-button\" ng-click=\"goToPortalCart()\">My Cart {{getPortalCartCount()}}</button>\n" +
    "\t  <!--a href=\"https://test.proconsort.com/carts-overview\" target=\"_self\" class=\"btn btn-outline-secondary btn-sm infomart-masthead-button\">My Cart</a -->\n" +
    "\t  <a href=\"/app/me/acqsubs\" class=\"btn btn-outline-secondary btn-sm infomart-masthead-button\">Active Orders</a>\n" +
    "\t</div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div> <!-- public-masthead -->\n" +
    "</div> <!-- !user.has_staff_masthead || masthead.public_view -->\n" +
    "\n" +
    "<div ng-cloak id=\"staff-masthead\" ng-show=\"user.has_staff_masthead && !masthead.public_view && !masthead.docked\" class=\"container-fluid\">\n" +
    "  <div class=\"row\">\n" +
    "    <div class=\"koha-logo col-md-2\">\n" +
    "      <a href=\"/app/staff\" >\n" +
    "        <img src=\"//cdn.bibliovation.com/c/Docs/Bibliovation-staffB-logo.png\" width=\"140\">\n" +
    "      </a>\n" +
    "    </div>\n" +
    "    <div class=\"search-cnt col-md-5\">\n" +
    "      <div id=\"resident-search\" bv-staff-mast-search>\n" +
    "        <div uib-tabset type=\"pills\" justified=\"true\">\n" +
    "          <uib-tab ng-repeat=\"tab in tabs\" heading=\"{{tab.title}}\" select=\"tabSelected($index)\" deselect=\"tabDeselected(tab.id)\"> <div ng-include=\"tab.tmpl\" ng-class=\"'mastTab' + tab.id\"></div> </uib-tab>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"maplaunchg col-md-1 col-md-offset-4\" ng-if=\"config.geospatialSearch\">\n" +
    "      <button class='btn btn-primary kohaMapBtn' ng-click=\"toggleMap()\"><i class='icon icon-large icon-globe'></i>Map</button>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "</header>\n"
  );

  syncTemplateCache.put('app/partials/staff/tabs/barcodeSearch.html',
    "    <form ng-submit=\"simpleSearch({q: 'barcode:'+q})\" class=\"form-inline\">\n" +
    "      <input type=\"text\" ng-model=\"q\" title=\"Enter barcode\" placeholder=\"Enter barcode\" class=\"form-control input-large\" id=\"staffbarcodesearchbox\" />\n" +
    "      <button type=\"submit\" class=\"btn btn-outline-secondary\"><i class=\"bi bi-play-fill\"></i></button>\n" +
    "    </form>\n"
  );

  syncTemplateCache.put('app/partials/staff/tabs/authSearchTab.html',
    "\n" +
    "<form class=\"row row-cols-lg g-1\">\n" +
    "\n" +
    "    <div class=\"col-3\">\n" +
    "        <select name=\"authtypecodeselect\" class=\"form-select\" ng-options=\"c.label as c.code for c in auth_types\" ng-model=\"tabsearch.auth.authtypecode\">\n" +
    "            <option value=\"\">Default</option>\n" +
    "        </select>\n" +
    "    </div>\n" +
    "    <div class=\"col-3\">\n" +
    "        <select ng-model=\"tabsearch.auth.idx\" class=\"form-select\">\n" +
    "            <option value=\"auth-heading\">main heading</option>\n" +
    "            <option value=\"auth-full\">keyword</option>\n" +
    "            <option value=\"auth-heading_lc\">starts with</option>\n" +
    "        </select>\n" +
    "    </div>\n" +
    "    <div class=\"col-4\">\n" +
    "        <input id=\"value_anywhere\" type=\"text\" ng-model=\"tabsearch.auth.q\" class=\"form-control focus-active\" placeholder=\"Query term(s)\" click-on-enter=\"authsearchbutton\" />\n" +
    "    </div>\n" +
    "    <div class=\"col\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-9\">\n" +
    "                <select ng-model=\"tabsearch.auth.orderby\" class=\"form-select\">\n" +
    "                    <option value=\"auth-heading-sort asc\">A-Z</option>\n" +
    "                    <option value=\"auth-heading-sort desc\">Z-A</option>\n" +
    "                    <option value=\"score desc\">score</option>\n" +
    "                </select>\n" +
    "            </div>\n" +
    "            <a class=\"btn col-2\" id=\"authsearchbutton\" ui-sref='staff.authorities.search({q:tabsearch.auth.q,  op:\"do_search\", authtypecode:tabsearch.auth.authtypecode, idx:tabsearch.auth.idx, orderby:tabsearch.auth.orderby})' ui-sref-opts=\"{reload: true, inherit: false}\"><i class=\"bi bi-play-fill\"></i></a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</form>\n"
  );

  syncTemplateCache.put('app/partials/staff/tabs/catSearchTab.html',
    "<div ng-controller=\"StaffCatalogSearchCtrl\">\n" +
    "    <form ng-submit=\"submitSearch()\" class=\"form-inline\">\n" +
    "      <div class=\"btn-group\">\n" +
    "        <input type=\"text\" ng-model=\"inputQuery.q\" title=\"Enter the terms you wish to search for.\" placeholder=\"Enter search keywords\" class=\"form-control focus-active staffsimplesearchbox inputQueryText\">\n" +
    "        <span ng-show='inputQuery.q.length > 0' ng-click='clearMeOut()' class=\"searchclear bi bi-x-circle\"></span>\n" +
    "      </div>\n" +
    "\n" +
    "      <button ng-click='submitSearch()' class=\"btn btn-sm btn-primary\"><i class=\"bi bi-play-fill\"></i></button>\n" +
    "      <button ng-click='clearMeOut()' onclick='return false;' class=\"btn btn-sm btn-outline-secondary\">Clear All</button>\n" +
    "      </form>\n" +
    "</div>\n"
  );

  syncTemplateCache.put('app/partials/staff/tabs/checkinTab.html',
    "<form class=\"form-inline\">\n" +
    "    <input ng-model=\"tabsearch.checkin.barcode\" type=\"text\" class=\"form-control input-large focus-active ret-barcode\" placeholder=\"Scan a barcode to check in\" clear-on-submit >\n" +
    "    <button type=\"submit\" class=\"btn btn-outline-secondary patron-checkin-submit\" ui-sref-opts=\"{inherit: false, reload: true}\" ui-sref=\"staff.circ.checkin({barcode:tabsearch.checkin.barcode})\">\n" +
    "        <i class=\"bi bi-play-fill\"></i>\n" +
    "    </button>\n" +
    "</form>\n"
  );

  syncTemplateCache.put('app/partials/staff/tabs/callslipFillTab.html',
    "<form class=\"form-inline\">\n" +
    "    <input click-on-enter=\"callslipsearchbutton\" ng-model=\"tabsearch.cslip.barcode\" type=\"text\" class=\"callslip-barcode form-control input-large focus-active\" placeholder=\"Scan a barcode to initiate fill\" />\n" +
    "    <a class=\"btn\" id=\"callslipsearchbutton\" ui-sref-opts=\"{inherit: false, reload: true}\" ui-sref=\"staff.circ.callslips-search({barcode:tabsearch.cslip.barcode})\"><i class=\"bi bi-play-fill\"></i></a>\n" +
    "</form>\n"
  );

  syncTemplateCache.put('app/partials/staff/tabs/checkoutTab.html',
    "<div class=\"checkout-tab\">\n" +
    "    <kw-patron-select target-state=\"staff.patron.checkout\"></kw-patron-select>\n" +
    "</div>\n" +
    "\n"
  );

  syncTemplateCache.put('app/partials/staff/tabs/courseReserves.html',
    "<form class=\"form-inline\">\n" +
    "    <input click-on-enter=\"coursereservessearchbutton\" ng-model=\"tabsearch.creserves.limit\" type=\"text\" class=\"creserves_name form-control input-large focus-active\" placeholder=\"Search by barcode, name, department, course number, term,notes or instructor name\" />\n" +
    "    <a class=\"btn\" id=\"coursereservessearchbutton\" ui-sref-opts=\"{inherit: false, reload: true}\" ui-sref=\"staff.circ.course-reserves({limit:tabsearch.creserves.limit})\"><i class=\"bi bi-play-fill\"></i></a>\n" +
    "</form>\n"
  );

  syncTemplateCache.put('app/partials/staff/tabs/patronSearchTab.html',
    "<div class=\"patron-search-tab\">\n" +
    "    <kw-patron-select target-state=\"staff.patron.details\" with-sort></kw-patron-select>\n" +
    "</div>\n" +
    "\n" +
    "\n"
  );

  syncTemplateCache.put('app/partials/staff/tabs/subSearchTab.html',
    "<form class=\"form-inline\">\n" +
    "    <label for=\"ISSN_filter\">ISSN:</label>\n" +
    "    <input ng-model=\"tabsearch.serial.ISSN_filter\" type=\"text\" size=\"10\" maxlength=\"11\" name=\"ISSN_filter\" id=\"ISSN_filter\" value=\"\" click-on-enter=\"serialsearchbutton\" class=\"form-control focus-active\" />\n" +
    "    <label for=\"title_filter\">Title:</label>\n" +
    "    <input ng-model=\"tabsearch.serial.title_filter\" type=\"text\" size=\"12\" maxlength=\"40\" name=\"title_filter\" id=\"title_filter\" value=\"\" click-on-enter=\"serialsearchbutton\" class=\"form-control\" />\n" +
    "    <a class=\"btn\" id=\"serialsearchbutton\" ui-sref-opts=\"{inherit: false, reload: true}\" ui-sref=\"staff.serials.index({title:tabsearch.serial.title_filter,issn:tabsearch.serial.ISSN_filter})\"><i class=\"bi bi-play-fill\"></i></a>\n" +
    "</form>\n"
  );

  syncTemplateCache.put('app/partials/staff/tabs/sysprefSearchTab.html',
    "\n" +
    "<form class=\"form-inline\">\n" +
    "    <input ng-model=\"tabsearch.syspref.searchfield\" type=\"text\" name=\"searchfield\" class=\"form-control input-large focus-active\" placeholder=\"Enter syspref search string\" click-on-enter=\"sysprefsearchbutton\" />\n" +
    "    <a class=\"btn\" id=\"sysprefsearchbutton\" ui-sref-opts=\"{inherit: false, reload: true}\" ui-sref=\"staff.admin.sysprefs-search({searchfield:tabsearch.syspref.searchfield})\" ng-click=\"resetTabSearch()\"><i class=\"bi bi-play-fill\"></i></a>\n" +
    "</form>\n"
  );

  syncTemplateCache.put('app/partials/staff-nav.html',
    "<!-- PRELOAD -->\n" +
    "<div ng-show=\"user.is_staff &&  user.can({catalogue: {access: 'menu'}})\" class=\"navbar navbar-expand-lg\">\n" +
    "    <div class=\"navbar-header\">\n" +
    "        <button type=\"button\" class=\"navbar-toggler\" ng-click=\"staffNavCollapsed = !staffNavCollapsed\">\n" +
    "            <span class=\"sr-only\">Toggle navigation</span>\n" +
    "            &#9776;\n" +
    "        </button>\n" +
    "    </div>\n" +
    "    <div class=\"collapse navbar-collapse \" uib-collapse=\"staffNavCollapsed\">\n" +
    "        <ul class=\"navbar-nav\">\n" +
    "            <li class=\"nav-item\">\n" +
    "                <a ui-sref=\"staff\" kw-triggerable=\"staffHome\" class=\"nav-link\">Staff Home</a>\n" +
    "            </li>\n" +
    "            <li class=\"nav-item\">\n" +
    "                <a ui-sref-opts=\"{inherit: false, reload: true}\" ui-sref=\"staff.circ\"\n" +
    "                    kw-triggerable=\"circHome\" class=\"nav-link\">Circulation</a>\n" +
    "            </li>\n" +
    "            <li class=\"nav-item\">\n" +
    "                <a ui-sref-opts=\"{inherit: false, reload: true}\" ui-sref=\"staff.patrons.browse\"\n" +
    "                    kw-triggerable=\"patronHome\" class=\"nav-link\">Patrons</a>\n" +
    "            </li>\n" +
    "            <li class=\"nav-item\">\n" +
    "                <a href=\"/app/search\" kw-triggerable=\"advSearch\" class=\"nav-link\">Search</a>\n" +
    "            </li>\n" +
    "            <li class=\"nav-item\" uib-dropdown>\n" +
    "                <a uib-dropdown-toggle href class=\"nav-link\">More <span class=\"caret\"></span> {{isOpen}}</a>\n" +
    "                <ul uib-dropdown-menu>\n" +
    "                    <li class=\"dropdown-item\"><a ui-sref-opts=\"{inherit: false, reload: true}\" ui-sref=\"staff.lists\">Lists</a></li>\n" +
    "                    <li class=\"dropdown-item\" ng-if=\"user.can({editcatalogue: '*'})\"><a ui-sref-opts=\"{inherit: false, reload: true}\"\n" +
    "                            ui-sref=\"staff.marced.home\" kw-triggerable=\"marced\">Cataloging Editor</a></li>\n" +
    "                    <li class=\"dropdown-item\" ng-if=\"user.can({catalogue: '*'})\"><a ui-sref-opts=\"{inherit: false, reload: true}\"\n" +
    "                            ui-sref=\"staff.authorities\">Authorities</a></li>\n" +
    "                    <li class=\"dropdown-item\" ng-if=\"config.dls.enabled && user.can({editcatalogue:{dlso:'upload'}},'*')\"><a\n" +
    "                            ui-sref-opts=\"{inherit: false, reload: true}\" ui-sref=\"batch-manager\">Add Digital\n" +
    "                            Content</a></li>\n" +
    "                    <li class=\"dropdown-item\" ng-if=\"user.savedSearches.all.length\"><a class=\"saved-search-link\" href\n" +
    "                            ng-click=\"savedSearchDlgOpen()\">Saved Searches</a></li>\n" +
    "                    <li class=\"dropdown-item\" ng-if=\"user.can({serials: '*'})\"><a ui-sref-opts=\"{inherit: false, reload: true}\"\n" +
    "                            ui-sref=\"staff.serials.index\">Serials</a></li>\n" +
    "                    <li class=\"dropdown-item\" ng-if=\"config.GetItAcquisitions && user.can({acquisition: '*'})\"><a\n" +
    "                            ui-sref-opts=\"{inherit: false, reload: true}\" ui-sref=\"staff.acquisitions\">Acquisitions</a>\n" +
    "                    </li>\n" +
    "                    <li class=\"dropdown-item\" ng-if=\"user.can({reports: '*'})\"><a ui-sref-opts=\"{inherit: false, reload: true}\"\n" +
    "                            ui-sref=\"staff.reports\">Reports</a></li>\n" +
    "                    <li class=\"dropdown-item\" ng-if=\"user.can({tools: '*'})\"><a ui-sref-opts=\"{inherit: false, reload: true}\"\n" +
    "                            ui-sref=\"staff.tools\">Tools</a></li>\n" +
    "                    <li class=\"dropdown-item\" ng-if=\"user.can({parameters: '*'})\"><a ui-sref-opts=\"{inherit: false, reload: true}\"\n" +
    "                            ui-sref=\"staff.admin\">Administration</a></li>\n" +
    "                    <li class=\"dropdown-item\"><a ui-sref=\"version\">About</a></li>\n" +
    "                    <li class=\"dropdown-item\"><a href ng-click=\"openHotkeyModal()\" kw-triggerable=\"hotkeys\">Show hotkeys</a></li>\n" +
    "                    <li class=\"dropdown-item user-manual\" ng-show=\"config.useExternalCDNs\"><a\n" +
    "                            href=\"https://cdn.bibliovation.com/c/Docs/BV-Manual.pdf\" kw-triggerable=\"userManual\">User\n" +
    "                            Manual</a></li>\n" +
    "                    <li class=\"dropdown-item\"><a href ng-click=\"togglePublicView()\">Show\n" +
    "                        <span ng-if=\"masthead.public_view\">\n" +
    "                            <span ng-if=\"user.can({catalogue:{access:'masthead'}})\">Staff</span>\n" +
    "                            <span ng-if=\"!user.can({catalogue:{access:'masthead'}})\"> No</span>\n" +
    "                        </span>\n" +
    "                        <span ng-if=\"!masthead.public_view\"> Public </span> Masthead</a>\n" +
    "                    </li>\n" +
    "                    <li class=\"dropdown-item\" ng-if=\"!masthead.public_view && user.can({catalogue: {access: 'masthead'}})\">\n" +
    "                        <a href ng-click=\"toggleMasthead()\" >\n" +
    "                            <span ng-if=\"masthead.docked\"><i class=\"bi bi-arrows-expand\"></i> Show</span>\n" +
    "                            <span ng-if=\"!masthead.docked\"><i class=\"bi bi-arrows-collapse\"></i> Hide</span>\n" +
    "                            Masthead</a>\n" +
    "                    </li>\n" +
    "                </ul>\n" +
    "            </li>\n" +
    "\n" +
    "            <li class=\"hidden\"><a ng-click=\"repeatLastSearch()\" kw-triggerable=\"lastSearchResults\">Repeat last\n" +
    "                    search</a></li>\n" +
    "        </ul>\n" +
    "    </div>\n" +
    "</div>"
  );

}]);
})();
